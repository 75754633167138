import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { persistor, store } from './slices/store';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from "notistack";
import App from './App';
import './index.css';
import './animations.css';
import { Link, Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ExtraPaths, Paths } from './helpers/navigationHelper';
import { TabledRepositories } from './components/repositories/TabledRepositories';
import { SchedulesList } from './components/scheduler/SchedulesList';
import { ScheduleEditor } from './components/scheduler/ScheduleEditor';
import { RepositoryDetails } from './components/repository/RepositoryDetails';
import { BulkPreSelected } from './components/bulk/BulkPreSelected';
import { BulkSelected } from './components/bulk/BulkSelected';
import { EditStorage, NewStorage } from './components/storage/NewStorage';
import { StoragesList } from './components/storage/StoragesList';
import { AccountPage } from './components/settings/AccountPage';
import { BillingPage } from './components/settings/BillingPage';
import { ModalOutlet } from './components/application/ModalOutlet';
import { RestorePage } from './components/restore/Restore';
import { ContactUsPage } from './components/settings/ContactUsPage';
import { NotificationSettings } from './components/settings/NotificationSettings';
import { OtpWindow } from './components/predashboard/OtpWindow';
import { NotFoundPage } from './components/application/NotFoundPage';
import { AccountDeleted } from './components/application/AccountDeleted';
import { AuditLog } from './components/settings/AuditLog';

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

const RedirectComponent = () => {
  //window.location.replace("https://staging.cloudback.it");
  return null;
}

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "/test",
        element: <RedirectComponent />
      },
      {
        path: Paths.dashboard.path,
        element: <ModalOutlet />,
        children: [
          {
            index: true,
            element: <TabledRepositories />,
          },
          {
            path: Paths.dashboard.repositoryDetails.path,
            element: <RepositoryDetails />,
            handle: {
              crumb: (data) => ({ name: Paths.dashboard.repositoryDetails.name }),
            },
          }
        ]
      },
      {
        path: Paths.schedules.path,
        element: <ModalOutlet />,
        handle: {
          crumb: (data) => ({ name: Paths.schedules.name }),
        },
        children: [
          {
            index: true,
            element: <SchedulesList />,
          },
          {
            path: Paths.schedules.newSchedule.path,
            element: <ScheduleEditor />,
            handle: {
              crumb: (data) => ({ name: Paths.schedules.newSchedule.name }),
            },
          },
          {
            path: Paths.schedules.editSchedule.path,
            element: <ScheduleEditor />,
            handle: {
              crumb: (data) => ({ name: Paths.schedules.editSchedule.name }),
            },
          }
        ]
      },
      {
        path: Paths.storages.path,
        element: <ModalOutlet />,
        handle: {
          crumb: (data) => ({ name: Paths.storages.name }),
        },
        children: [
          {
            index: true,
            element: <StoragesList />,
          },
          {
            path: Paths.storages.newStorage.path,
            element: <NewStorage />,
            handle: {
              crumb: (data) => ({ name: Paths.storages.newStorage.name }),
            },
          },
          {
            path: Paths.storages.editStorage.path,
            element: <EditStorage />,
            handle: {
              crumb: (data) => ({ name: Paths.storages.editStorage.name }),
            },
          }
        ]
      },
      {
        path: Paths.bulk.path,
        element: <ModalOutlet />,
        handle: {
          crumb: (data) => ({ name: Paths.bulk.name }),
        },
        children: [
          {
            index: true,
            element: <BulkSelected />,
          },
          {
            path: Paths.bulk.bulkPreSelected.path,
            element: <BulkPreSelected />,
            handle: {
              crumb: (data) => ({ name: Paths.bulk.bulkPreSelected.name }),
            },
          }
        ]
      },
      {
        path: Paths.accountSettings.path,
        element: <ModalOutlet />,
        handle: {
          crumb: (data) => ({ name: Paths.accountSettings.name }),
        },
        children: [
          {
            index: true,
            element: <AccountPage />,
          }
        ]
      },
      {
        path: Paths.audit.path,
        element: <ModalOutlet />,
        handle: {
          crumb: (data) => ({ name: Paths.audit.name }),
        },
        children: [
          {
            index: true,
            element: <AuditLog />,
          }
        ]
      },
      {
        path: Paths.billing.path,
        element: <ModalOutlet />,
        handle: {
          crumb: (data) => ({ name: Paths.billing.name }),
        },
        children: [
          {
            index: true,
            element: <BillingPage />,
          }
        ]
      },
      {
        path: Paths.notifications.path,
        element: <ModalOutlet />,
        handle: {
          crumb: (data) => ({ name: Paths.notifications.name }),
        },
        children: [
          {
            index: true,
            element: <NotificationSettings />,
          }
        ]
      },
      {
        path: ExtraPaths.restore.path,
        element: <ModalOutlet />,
        handle: {
          crumb: (data) => ({ name: ExtraPaths.restore.name }),
        },
        children: [
          {
            index: true,
            element: <RestorePage />
          },
          {
            path: ExtraPaths.restore.restoreAccount.path,
            element: <RestorePage />
          },
          {
            path: ExtraPaths.restore.restoreBackup.path,
            element: <RestorePage />
          },
          {
            path: ExtraPaths.restore.restoreRepository.path,
            element: <RestorePage />
          }
        ]
      },
      {
        path: ExtraPaths.contactUs.path,
        element: <ModalOutlet />,
        handle: {
          crumb: (data) => ({ name: ExtraPaths.contactUs.name }),
        },
        children: [
          {
            index: true,
            element: <ContactUsPage />
          }
        ]
      },
      {
        path: "/signin-otp",
        element:<OtpWindow/>
      }, 
      {
        path:"*",
        element:<NotFoundPage />
      }
    ]
  }
])

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <SnackbarProvider maxSnack={3}>
        <RouterProvider router={router} />
      </SnackbarProvider>
    </PersistGate>
  </Provider>
);
