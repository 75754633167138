import { useEffect, useState } from "react"
import { BackupStorageProvider } from "../../model/domain";
import { AzureStorageInfo } from "./Providers/Azure";
import { useNavigate, useParams } from "react-router-dom";
import { StorageImg, StorageProviderName } from "../../helpers/domainHelpers";
import { SelectBox } from "../../kit/SelectBox";
import { OneDrivePersonalStorageInfo } from "./Providers/OneDrivePersonal";
import { OneDriveBusinessStorageInfo } from "./Providers/OneDriveBusiness";
import { AmazonS3AccessPointStorageInfo } from "./Providers/AmazonS3AccessPoint";
import { classNames } from "../application/ApplicationHeader";
import { AmazonS3AccessKeyStorageInfo } from "./Providers/AmazonS3AccessKey";
import { AmazonS3GlacierStorageInfo } from "./Providers/AmazonS3Glacier";
import { SwiftContainerAccessKeyStorageInfo } from "./Providers/SwiftContainerAccessKey";
import { WasabiS3AccessKeyStorageInfo } from "./Providers/WasabiS3AccessKey";
import { GoogleCloudStorageInfo } from "./Providers/GoogleCloud";
import { AlibabaCloudStorageInfo } from "./Providers/AlibabaCloud";
import axios from "axios";
import { settings } from "../../settings";
import { Paths } from "../../helpers/navigationHelper";
import { FullScreenLoader } from "../../kit/Spinners";
import { CompositeStorageInfo } from "./Providers/Composite";
import { capitalizeFirstLetter } from "../../helpers/internalFunctions";
import { processAxiosError } from "../util/ErrorBoundary";
import { ErrorPage } from "../../kit/ErrorPage";
import { forwardRef } from "react";
import { Listbox, Menu } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";

const storageProviders = Object.values(BackupStorageProvider)
    .filter(x => x !== BackupStorageProvider.undefined)
    .map(x => ({ id: x, name: StorageProviderName(x), icon: className => StorageImg({ isCustomStorage: true, provider: x, className }) }))
    .sort((a, b) => a.name.localeCompare(b.name));


const dedupOptions = [
    { id: true, name: "Only archive if changes have occurred - no duplicates" },
    { id: false, name: "Always archive - duplicates allowed" }
];

const protectOptions = [
    { id: true, name: "Password-protected ZIP archive" },
    { id: false, name: "ZIP archive without password protection" }
]

const providersContent = {
    [BackupStorageProvider.azureBlobContainer]: props => <AzureStorageInfo {...props} />,
    [BackupStorageProvider.microsoftOneDrivePersonal]: props => <OneDrivePersonalStorageInfo {...props} />,
    [BackupStorageProvider.microsoftOneDriveBusiness]: props => <OneDriveBusinessStorageInfo {...props} />,
    [BackupStorageProvider.amazonS3AccessPoint]: props => <AmazonS3AccessPointStorageInfo {...props} />,
    [BackupStorageProvider.amazonS3AccessKey]: props => <AmazonS3AccessKeyStorageInfo {...props} />,
    [BackupStorageProvider.amazonS3Glacier]: props => <AmazonS3GlacierStorageInfo {...props} />,
    [BackupStorageProvider.openStackSwiftS3]: props => <SwiftContainerAccessKeyStorageInfo {...props} />,
    [BackupStorageProvider.wasabiS3AccessKey]: props => <WasabiS3AccessKeyStorageInfo {...props} />,
    [BackupStorageProvider.googleCloudStorageBucket]: props => <GoogleCloudStorageInfo {...props} />,
    [BackupStorageProvider.alibabaObjectStorageService]: props => <AlibabaCloudStorageInfo {...props} />,
    [BackupStorageProvider.compositeStorage]: props => <CompositeStorageInfo {...props} />,
}

const findStorageProvider = (providerName) => {
    let index = storageProviders.findIndex(x => capitalizeFirstLetter(x.id) === providerName);
    if (index !== -1) {
        return storageProviders[index];
    }
    return undefined;
}

const useStorageBasicInfo = (storageId, setPageError) => {
    const [provider, setProvider] = useState(storageProviders[0]);
    const [protection, setProtection] = useState(protectOptions[0]);
    const [deduplication, setDeduplication] = useState(dedupOptions[0]);
    const [pattern, setPattern] = useState("");
    const [name, setName] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [preloadedConnectionString, setPreloadedConnectionString] = useState(null);

    const loadStorage = () => {
        setIsLoading(true);
        axios.get(settings.backendUrl + "/backup/storage/get?storageId=" + storageId)
            .then(res => {
                if (res.data.isSuccess) {
                    setProtection(res.data.result.isPasswordProtected ? protectOptions[0] : protectOptions[1]);
                    setDeduplication(res.data.result.isDeduplicationEnabled ? dedupOptions[0] : dedupOptions[1]);
                    setName(res.data.result.storageName);
                    setPattern(res.data.result.archiveNamePattern);
                    let provider = findStorageProvider(res.data.result.provider);
                    //TODO: set error if failed
                    setProvider(provider);//TODO: check capitalized letter
                    setPreloadedConnectionString(res.data.result.connectionString);
                }
                else {
                    setPageError(res.data.errorDescription)
                }
            })
            .catch(e => {
                processAxiosError(e);
                setPageError("The storage couldn't be loaded. Please try again later.");
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        if (storageId !== undefined) {
            loadStorage();
        }
        else { setIsLoading(false); }
    }, [])

    return { preloadedConnectionString, isLoading, storageId, provider, protection, deduplication, name, pattern, setPattern, setProvider, setProtection, setDeduplication, setName }
}

const StorageName = ({ name, setName }) => {
    return (
        <div className="sm:col-span-3">
            <label htmlFor="storageName" className="block text-sm font-medium leading-6 text-gray-900">
                Storage name
            </label>
            <div className="mt-2">
                <input
                    type="text"
                    name="storageName"
                    id="storageName"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    className={classNames("block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6", name === '' ? "ring-red-400 focus:ring-red-600" : "")}
                />
            </div>
        </div>
    )
}

const predefinedPatterns = [
    { id: 1, value: '{{ date.now | date.to_string "%Y-%m-%d" }}-{{ context.AccountName }}-{{ context.RepositoryName }}' },
    { id: 2, value: '{{ date.now | date.to_string "%Y%m%d" }}-{{ context.AccountName }}-{{ context.RepositoryName }}' },
    { id: 3, value: '{{ math.uuid }}' },
    { id: 4, value: '{{ math.uuid | string.replace "-" "" }}' },
]
const StoragePattern = ({ pattern, setPattern }) => {


    const onPreselect = (value) => {
        setPattern(value);
    }
    return (
        <div className="sm:col-span-6">
            <label htmlFor="storagePattern" className="inline-flex items-center text-sm font-medium leading-6 text-gray-900">
                <span className="mr-1">Archive name pattern (optional, <a className="text-green-600 hover:text-green-500" href="https://cloudback.it/docs/archive-name-pattern">learn more</a>)</span>
            </label>
            <div className="mt-2">
                <div className="mt-2 flex flex-grow items-stretch relative">
                    <input
                        type="text"
                        name="storagePattern"
                        id="storagePattern"
                        value={pattern}
                        placeholder='ex.: {{ date.now | date.to_string "%F" }}-{{ context.AccountName }}-{{ context.RepositoryName }}'
                        onChange={e => setPattern(e.target.value)}
                        className={classNames("block w-full rounded-l-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6")}
                    />
                    <Menu>
                        <Menu.Button className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                            <ChevronUpDownIcon className="h-5 w-5" aria-hidden="true" />
                        </Menu.Button>
                        <Menu.Items
                            className="absolute z-10 mt-1 max-h-60 max-w-full w-max overflow-y-auto overflow-x-hidden rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {predefinedPatterns.map(item => (
                                <Menu.Item key={item.id}>
                                    {({ active }) => (
                                        <div className={classNames(
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                            active ? 'bg-gray-600 text-white' : 'text-gray-900'
                                        )} onClick={() => onPreselect(item.value)}>
                                            <div className="inline-flex items-center align-middle w-full overflow-hidden">
                                                <span className={classNames('block truncate')}>{item.value}</span>
                                            </div>
                                        </div>
                                    )}
                                </Menu.Item>
                            ))}

                        </Menu.Items>
                    </Menu>
                </div>
            </div>
        </div>
    )
}

const StorageProvider = ({ editMode, provider, setProvider }) => {
    return (
        <div className="sm:col-span-3">
            <SelectBox
                label="Storage Provider"
                options={storageProviders}
                selected={provider}
                setSelected={setProvider}
                keySelector={(option) => option.id}
                labelSelector={(option) => option.name}
                leftContentSelector={option => (option.icon("h-5 w-5"))}
            />
        </div>
    )
}

const Deduplication = ({ deduplication, setDeduplication }) => {
    return (
        <div className="sm:col-span-3">
            <SelectBox
                label="Deduplication type"
                options={dedupOptions}
                selected={deduplication}
                setSelected={setDeduplication}
                keySelector={(option) => option.id}
                labelSelector={(option) => option.name}
            />
        </div>
    )
}

const Protection = ({ protection, setProtection }) => {
    return (
        <div className="sm:col-span-3">
            <SelectBox
                label="Archive type"
                options={protectOptions}
                selected={protection}
                setSelected={setProtection}
                keySelector={(option) => option.id}
                labelSelector={(option) => option.name}
            />
        </div>
    )
}

//Form with common storage info
const NewStorageBasics = ({ basics }) => {

    return (
        <div className="pb-6 space-y-6">
            <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                <StorageName name={basics.name} setName={basics.setName} />
                <StorageProvider editMode={basics.storageId !== undefined} provider={basics.provider} setProvider={basics.setProvider} />
                <Deduplication deduplication={basics.deduplication} setDeduplication={basics.setDeduplication} />
                <Protection protection={basics.protection} setProtection={basics.setProtection} />
                <StoragePattern pattern={basics.pattern} setPattern={basics.setPattern} />
            </div>
        </div>
    )

}


const StorageEditor = ({ storageId }) => {
    const [pageError, setPageError] = useState(null);
    const basics = useStorageBasicInfo(storageId, setPageError);
    const editMode = storageId !== undefined;

    const onComplete = (connectionString, callback) => {
        //Send request to create storage using storageInfo as well       
        axios.post(settings.backendUrl + "/backup/storage/" + (editMode ? "update" : "create"), {
            storageId: basics.storageId,
            storageName: basics.name,
            providerName: capitalizeFirstLetter(basics.provider.id),
            connectionString: connectionString,
            isPasswordProtected: basics.protection.id,
            isDeduplicationEnabled: basics.deduplication.id,
            archiveNamePattern: basics.pattern === '' ? null : basics.pattern
        })
            .then(res => {
                callback(res.data);
            })
            .catch(e => {
                processAxiosError(e);
                callback({ error: true });
            })
    }

    const onTest = (connectionString, callback) => {
        axios.post(settings.backendUrl + "/backup/storage/test", {
            storageId: basics.storageId,
            storageName: basics.name,
            providerName: capitalizeFirstLetter(basics.provider.id),
            connectionString: connectionString,
            archiveNamePattern: basics.pattern === '' ? null : basics.pattern
        })
            .then(res => {
                callback(res.data);
            })
            .catch(e => {
                processAxiosError(e);
                callback({ error: true });
            })
    }

    const navigate = useNavigate();
    const onCancel = () => {
        navigate(Paths.storages.path);
    }
    return pageError !== null ? <ErrorPage /> : (
        <div className="w-full bg-gray-100">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-3xl">
                    {basics.isLoading ? <FullScreenLoader className="h-96 w-full" fillColor="gray" strokeColor="black" /> :
                        <div className="pb-5">
                            <NewStorageBasics basics={basics} />
                            {providersContent[basics.provider.id]({ onComplete, onCancel, onTest, nameProvided: basics.name !== '', editMode, connectionString: basics.preloadedConnectionString, setPageError })}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export const EditStorage = () => {
    let { id } = useParams();
    return (
        <StorageEditor storageId={id} />
    )
}

export const NewStorage = () => {

    return (
        <StorageEditor storageId={undefined} />
    )
}