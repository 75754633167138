import { useSelector } from "react-redux";
import { VirtuosoGrid } from "react-virtuoso"
import { RepositoryCard } from "./RepositoryCard";
import { forwardRef, useState } from "react";
import { classNames } from "../application/ApplicationHeader";
import { Tooltip } from "react-tooltip";
import { EmptyRepoState } from "./EmptyRepoState";
import { BaseTransition } from "../../kit/Transitions";

const GridWrapper = forwardRef((props, ref) => {
    return (
        <div {...props} ref={ref}>
            <div className="h-full flex flex-wrap mx-auto max-w-sm md:max-w-4xl  relative">
                {props.children}
            </div>
        </div>
    )
})

const GridListWrapper = forwardRef((props, ref) => {
    const { className, ...other } = props;
    return (
        <div className={classNames(className, "flex flex-wrap")} {...other} ref={ref}>
            {props.children}
        </div>
    )
})
const GridItemWrapper = (props) => {
    const { className, ...other } = props;
    return (
        <div className={classNames(className, "px-1 py-3 md:px-5 md:py-5 flex w-[100%] justify-center content-stretch md:w-[50%]")} {...other} >
            {props.children}
        </div>
    )
}


const COMPONENTS = {
    Scroller: forwardRef((props, ref) => {
        return <GridWrapper {...props} ref={ref} />
    }),
    List: forwardRef((props, ref) => {
        return <GridListWrapper {...props} ref={ref} />
    }),
    Item: (props) => {
        return <GridItemWrapper {...props} />
    },
}

const DummyCard = () => {
    return (
        <div className="animate-pulse h-72 bg-white w-full flex justify-center items-center rounded-lg shadow shadow-gray-400">

        </div>
    )
}


export const GridRepositories = ({ isLoading }) => {
    const filtered = useSelector((state) => state.data.selectors.filtered);
    return (
        <>
            {!isLoading && filtered.length === 0 ?
               <EmptyRepoState />
                :
                <VirtuosoGrid
                    components={COMPONENTS}
                    useWindowScroll={false}
                    style={{ height: "100%" }}
                    data={isLoading ? [1, 2, 3, 4] : filtered}
                    overscan={{ main: 50, reverse: 50 }}
                    //initialItemCount={10}
                    computeItemKey={(index, data) => { return isLoading ? index : data.repoId.toString() + index }}
                    itemContent={(index, data) => isLoading ? <DummyCard /> : <RepositoryCard {...data} />}
                />
            }
            <Tooltip className="z-[55]" id="info-tooltip" />
            <Tooltip className="z-[55]" id="go-tooltip" />
            <Tooltip className="z-[55]" id="backup-type-tooltip" />
            <Tooltip className="z-[55]" id="download-tooltip" />
            <Tooltip className="z-[55]" id="restore-tooltip" />
            <Tooltip className="z-[55]" id="backup-tooltip" />
            <Tooltip className="z-[55]" id="repo-status-tooltip" />
            <Tooltip className="z-[55]" id="details-tooltip" />
            <Tooltip className="z-[55]" id="storage-tooltip" />
        </>
    )
}