import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid"
import { Fragment } from "react"
import { classNames } from "../components/application/ApplicationHeader"
import { LoadingAutocompleteInner } from "./Autocomplete"



export const SelectBox = ({ isLoading, disabled, label, options, selected, setSelected, keySelector, labelSelector, leftContentSelector, multiple = false }) => {
  
  const compareBy = (a,b)=>{
    return keySelector(a)===keySelector(b);
  }
  
  return (
    <Listbox disabled={disabled || isLoading} by={compareBy} value={selected} onChange={setSelected} multiple={multiple}>
      {({ open }) => (
        <>
          {label !== undefined && <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">{label}</Listbox.Label>}
          <div className={classNames("relative", label !== undefined ? "mt-1" : "")}>
            {isLoading ? <LoadingAutocompleteInner /> :
              <Listbox.Button disabled={disabled}
                className={classNames("relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-600 sm:text-sm sm:leading-6", disabled ? "!bg-gray-200 hover:!bg-gray-200" : "")}>
                <span className="w-full inline-flex overflow-x-hidden gap-1 ">{multiple ?
                  selected.map((x, i) =>
                    <span key={labelSelector(x)} className="flex items-center">
                      {leftContentSelector !== undefined && leftContentSelector(x)}
                      <span className="ml-1 block truncate">{labelSelector(x)}{i !== selected.length - 1 ? "," : ""}</span>
                    </span>)
                  :
                  <span className="flex items-center">
                    {leftContentSelector !== undefined && leftContentSelector(selected)}
                    <span className="ml-3 block truncate">{labelSelector(selected)}</span>
                  </span>
                }
                </span>
                {!disabled && !isLoading && <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>}
              </Listbox.Button>
            }
            {!isLoading &&
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-20 min-w-full max-w-[300px] mt-1 max-h-60 overflow-y-auto overflow-x-hidden rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      key={keySelector(option)}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-gray-600 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9 hover:rounded-md'
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center w-full overflow-hidden">
                            {leftContentSelector !== undefined && leftContentSelector(option)}
                            <span
                              className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                            >
                              {labelSelector(option)}
                            </span>
                          </div>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-gray-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            }
          </div>
        </>
      )}
    </Listbox>
  )
}