import { Transition } from "@headlessui/react"
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { Fragment } from "react"
import { Link } from "react-router-dom"


export const MarketplacePlanMissingDialogAlert = ({ onClose, content }) => {

    return (
        <Transition.Root show={true} as={Fragment}>
            <div className="">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-yellow-700">
                                    GitHub marketplace plan is missing for the following accounts: {content.accounts.map((x,i) => <b key={x}>{i===0?"":", "}{x}</b>)}.
                                    <Link className="ml-1" to="https://www.github.com/marketplace/cloudback" target="blank">
                                        <button
                                            type="button"
                                            onClick={onClose}
                                            className="font-medium text-yellow-700 underline hover:text-yellow-600"
                                        >
                                            Purchase now
                                        </button>
                                    </Link>
                                    .
                                </p>
                            </div>
                            <div className="ml-auto pl-3">
                                <div className="-mx-1.5 -my-1.5">
                                    <button
                                        type="button"
                                        onClick={onClose}
                                        className="inline-flex rounded-md bg-yellow-50 p-1.5 text-yellow-700 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
                                    >
                                        <span className="sr-only">Dismiss</span>
                                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>                   
                </Transition.Child>
            </div>
        </Transition.Root >
    )
}