import { InTextClickButton, InTextLink } from "../../../kit/Badges"
import { useEffect, useMemo, useState } from "react"
import { ProviderSettingsWrapper, ProviderStepWrapper, ProviderTestAndSave, StorageWrapperComponent, useBroadcastChannel, useStorageSection } from "./ProvidersCommon"
import { useDispatch, useSelector } from "react-redux"
import { isDevelopment, settings } from "../../../settings"
import { WarningAlert } from "../../../kit/Alerts"
import { CheckCircleIcon } from "@heroicons/react/20/solid"
import { Tooltip } from "react-tooltip"
import { setModal } from "../../../slices/userSlice"
import { Modals } from "../../application/modals/ModalsCommon"
const parseConnectionString = cs => {
    if (!cs) return {
        accountId: null,
    }
    let parts = cs.split(';');
    let partsDict = parts.map(x => { let keyValue = x.split('='); return { key: keyValue[0], value: keyValue[1] } }).reduce((acc, val) => ({ ...acc, [val.key]: val.value }), {});
    return (
        {
            accountId: partsDict['AccountId'],
        }
    )
}
export const OneDrivePersonalStorageInfo = ({ onComplete, onCancel, onTest, nameProvided, connectionString, editMode, setPageError }) => {
    const parsedConnectionString = useMemo(() => parseConnectionString(connectionString), [connectionString])
    const userId = useSelector(state => state.user.id);
    const [accountId, setAccountId] = useState(parsedConnectionString.accountId);

    const canContinue = accountId !== null;
    const buildConnectionString = () => `IdentityId=${userId};AccountId=${accountId}`;
    const storageEditor = useStorageSection(onCancel, onTest, onComplete, buildConnectionString, editMode, setPageError);
    const permissionsBroadcast = useBroadcastChannel();
    const onGrantPermission = () => {
        permissionsBroadcast.setBusy(true);
        const userState = encodeURIComponent(JSON.stringify({
            InitiatorIdentityId: userId
        }));
        const winUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${storageEditor.providers.list["MicrosoftOneDrivePersonal"].userInterfaceHelper.parameters.ClientId}&scope=https://graph.microsoft.com/user.read+files.readwrite.appfolder+offline_access&response_type=code&redirect_uri=${settings.identityUrl}/signin-microsoft&state=${userState}`;
        const newpopup = window.open(winUrl,
            'Grant OneDrive permission',
            (isDevelopment ? "scrollbars=yes,resizable=yes,status=yes,toolbar=yes,menubar=yes,location=yes," : "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,") + 'height=800,width=1016');
        permissionsBroadcast.checkExit(newpopup, "Failed to grant permissions");
    }
    const dispatch = useDispatch();
    useEffect(() => {
        if (permissionsBroadcast.extParams !== null && permissionsBroadcast.extParams.error === undefined) {            
            setAccountId(permissionsBroadcast.extParams.accountId);
        }
        else if (permissionsBroadcast.extParams !== null && permissionsBroadcast.extParams.error === undefined) {
            //Show alert with errorsetAlert({ type: "error", title: "Storage test failed", text: result.messages.join(";") });
            dispatch(setModal({id:Modals.ErrorModalWithSingleCloseButton, title: "Error", text: decodeURI(permissionsBroadcast.extParams.error_description) }));
        }
    }, [permissionsBroadcast.extParams])

    return (
        <>
            <StorageWrapperComponent className="bg-blue-50/20" providers={storageEditor.providers}>
                <ProviderStepWrapper stepTitle="Step 1"
                >
                    <p>
                        Create a <InTextLink url="https://support.microsoft.com/en-us/office/video-sign-in-or-create-an-account-for-onedrive-personal-6c63b4e3-c92f-4f52-80e2-237c798cec1e"
                            title="OneDrive Personal Account" />
                    </p>
                </ProviderStepWrapper>
                <ProviderStepWrapper stepTitle="Step 2"
                >
                    <>
                        <p>Grant us a permission to write to the
                            <InTextLink url="https://docs.microsoft.com/en-us/onedrive/developer/rest-api/concepts/special-folders-appfolder" title=" app's folder" />:{' '} </p>
                        <div className="ml-1">
                            <InTextClickButton busy={permissionsBroadcast.busy} onClick={onGrantPermission} title="Grant permission" extra={
                                accountId !== null && <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" data-tooltip-id="onedrive-permissions-tooltip" data-tooltip-content="Permission granted" />
                            } />
                        </div>

                        <Tooltip id="onedrive-permissions-tooltip" />
                    </>
                </ProviderStepWrapper>
            </StorageWrapperComponent>
            <ProviderTestAndSave storageEditor={storageEditor} nameIncluded={nameProvided}  canContinue={canContinue} />
        </>
    )
}