import { Fragment, forwardRef, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableVirtuoso } from "react-virtuoso";
import { RowInner } from "./TableComponents";
import { HeaderInner } from "./HeaderComponents";
import { deselectAll } from "../../slices/dataSlice";
import axios from "axios";
import { useNotifier } from "../../hooks/notifications/notificationActions";
import { settings } from "../../settings";
import { createSelector } from "@reduxjs/toolkit";
import { WarningWindow } from "../../kit/WarningWindow";
import { TableCell, TableHeadCell } from "../../kit/Table";
import { CircularSpinnerSecondary } from "../../kit/Spinners";
import { EmptyState } from "../../kit/EmptyState";
import { Paths } from "../../helpers/navigationHelper";
import { Link } from "react-router-dom";
import { classNames } from "../application/ApplicationHeader";
import { setModal } from "../../slices/userSlice";
import { Modals } from "../application/modals/ModalsCommon";
import { EmptyRepoState } from "./EmptyRepoState";
import { Tooltip } from "react-tooltip";
import { processAxiosError } from "../util/ErrorBoundary";
import { FormButton } from "../../kit/Buttons";
import { Transition } from "@headlessui/react";
import { BaseTransition } from "../../kit/Transitions";

const getSelectedAndFilteredCards = (cards, selected, filtered) => {
  var filteredIds = [];
  filtered.forEach(row => { filteredIds[row.repoId] = true; });
  return cards.filter(x => selected[x.repositoryId] === true && filteredIds[x.repositoryId] === true);
}

export const selectSelectedAndFilteredCards = createSelector([
  state => state.data.dashboard.cards,
  state => state.data.selectors.selectedCards,
  state => state.data.selectors.filtered
], (cards, selectedCards, filteredCards) => getSelectedAndFilteredCards(cards, selectedCards, filteredCards));

const TableWrapper = forwardRef((props, ref) => {
  const { style, className, ...rest } = props;
  return (
    <div className={classNames(className, "h-full min-w-full rounded-lg border-b")} {...rest} style={{ ...style, overflowY: "scroll" }} ref={ref}>
      {props.children}
    </div>
  )
})
function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
  );
}

const TableTrigger = (props) => {
  const selectedAndFilteredCards = useSelector((state) => selectSelectedAndFilteredCards(state));
  const numSelected = selectedAndFilteredCards.length;

  const repoIds = useMemo(()=>selectedAndFilteredCards.map(x => x.repositoryId), [selectedAndFilteredCards]);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [notifySuccess, notifyInfo] = useNotifier();
  const triggerSelected = () => {
    //build cards list for trigger
    setIsLoading(true);
    let correlationId =  uuidv4();
    axios.post(settings.backendUrl + "/v2/triggerMultiple", { repositoryIds: repoIds, correlationId })
      .then((res) => {
        if (res.data.isSuccess) {  
          dispatch(deselectAll());
          dispatch(setModal({ id: Modals.TriggerMultipleResultModal, correlationId}));        
        }
        else {
          dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to trigger", text: res.data.errorDescription }));
        }
      }).catch(e => {
        processAxiosError(e);
        dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to trigger", text: "Something went wrong" }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      {numSelected > 0 && (
        <div className="absolute -translate-x-2/4 left-2/4 bottom-0 flex items-center justify-center space-x-3 z-[9] px-3 pt-2 pb-3">
          <button
            disabled={isLoading}
            onClick={triggerSelected}
            type="button"
            className="inline-flex items-center rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500"
          >
            {isLoading && <CircularSpinnerSecondary light={true} size="sm" className="mr-1" />}
            {isLoading ? "Triggerring" : "Trigger"} backup{numSelected > 1 ? "s" : ""}
            <span className="ml-1 inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-semibold text-green-700">
              {numSelected}
            </span>

          </button>
          <Link to={Paths.bulk.bulkPreSelected.path}>
            <button
              type="button"
              className="inline-flex items-center rounded-md text-white px-2.5 py-1.5 text-sm font-semibold bg-gray-600 hover:bg-gray-500 shadow-sm hover:bg-gray-50"
            >
              Bulk Edit
              <span className="ml-1  inline-flex items-center rounded-full bg-gray-100 px-2 py-1 text-xs font-semibold text-gray-600">
                {numSelected}
              </span>
            </button>
          </Link>
        </div>
      )}
    </>
  )
}

export const COMPONENTS = {
  Scroller: forwardRef((props, ref) => {
    return <TableWrapper {...props} ref={ref} />
  }),
  Table: forwardRef((props, ref) =>
    <table {...props} className="min-w-full border-separate border-spacing-0 shadow ring-1 ring-black ring-opacity-5 rounded-lg" ref={ref}
      aria-labelledby="tableTitle" />),
  TableHead: forwardRef((props, ref) => {
    const { style, className, ...rest } = props;
    return <thead {...rest} className={classNames(className, "bg-gray-50 h-12")} style={{ ...style, zIndex: 2 }}
      ref={ref} />
  }),
  TableRow: (props) => {
    return (
      <BaseTransition>
        <tr {...props} className="h-[41px]"/>
      </BaseTransition>)
  },
  TableBody: forwardRef((props, ref) =>
    <tbody className="divide-y divide-gray-200 bg-white" {...props} ref={ref} />),
};

export const DummyRow = ({ colSpan }) => {
  return (
    <TableCell colSpan={colSpan} className="animate-pulse h-12 w-full bg-gray-200 border-b border-gray-300">

    </TableCell>
  )
}

export const VirtualizedDummyRow = ({ colSpan = 5, firstColCheckbox = true }) => {
  return (
    <>
      <TableCell colSpan={1} padding="checkbox" className="animate-pulse bg-gray-200 border-b border-gray-300">
        {firstColCheckbox && <input
          type="checkbox"
          readOnly
          disabled={true}
          className="h-6 w-6 rounded border-gray-300 hover:bg-gray-300 hover:text-gray-300 text-gray-600"
          checked={false}
        />}
      </TableCell>
      <TableCell colSpan={colSpan} className="animate-pulse h-12 w-full bg-gray-200 border-b border-gray-300">

      </TableCell>
    </>

  )
}

export const VirtualizedRepoTable = ({ isLoading }) => {

  const filtered = useSelector((state) => state.data.selectors.filtered);
  const selectedAndFilteredCards = useSelector((state) => selectSelectedAndFilteredCards(state));

  return (
    <div className={classNames("px-3 pt-2 w-full h-full", selectedAndFilteredCards.length > 0 ? "pb-14" : "pb-3")}>
      {!isLoading && filtered.length === 0 ?
        <EmptyRepoState />
        :
        <>
          <TableVirtuoso
            useWindowScroll={false}
            style={{ height: "100%" }}
            data={isLoading ? [1, 2, 3, 4] : filtered}
            overscan={{ main: 50, reverse: 50 }}
            increaseViewportBy={{ bottom: 2, top: 2 }} 
            defaultItemHeight={41}
            //initialItemCount={20} - enable this to let table draw 20 rows at the start instead of 1, but it will blink a bit
            components={COMPONENTS}
            computeItemKey={(index, data) => { return isLoading ? index : data.repoId.toString() + data.rowType.toString() }}
            fixedHeaderContent={() => (
              <HeaderInner isLoading={isLoading} />
            )}
            itemContent={(index, data) => isLoading ? <VirtualizedDummyRow /> : <RowInner {...data} />}
          />
          <TableTrigger />
          <Tooltip className="z-[55]" id="details-tooltip" />
        </>
      }
    </div>

  )
}