import { createSelector } from "@reduxjs/toolkit"
import { useDispatch, useSelector } from "react-redux"
import { deselectAll } from "../../slices/dataSlice"
import { BulkApplyBlock, BulkPage, BulkSettingsSection, useBulkSettings } from "./BulkCommon";
import { getCard } from "../../helpers/domainHelpers";
import { Navigate, useNavigate } from "react-router-dom";
import { EmptyState } from "./../../kit/EmptyState"
import { Paths } from "../../helpers/navigationHelper";
import { useState } from "react";
import { selectSelectedAndFilteredCards } from "../repositories/VirtualizedRepoTable";
import { useMemo } from "react";

const BulkForm = ({setPageError, pageError}) => {
    //Check selected
    const dispatch = useDispatch();
    const selectedAndFilteredCards = useSelector(selectSelectedAndFilteredCards);
    const selected = useMemo(()=>selectedAndFilteredCards.map(x=>x.definitionId), [selectedAndFilteredCards]);
    const bulkSettings = useBulkSettings();
    const navigate = useNavigate();
    
    const onApplyExtra = () => {
        //deselect   
        dispatch(deselectAll());            
        navigate(-1);
    }
    const onApply = () => {
        bulkSettings.onApply(selected, onApplyExtra);
    }
    const [isLoading, setIsLoading] = useState(true);
    return (
        <>
            <div className="space-y-6">
                <BulkSettingsSection               
                    pageError={pageError}     
                    setPageError={setPageError}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    className="!mt-10"
                    bulkSettings={bulkSettings}
                    selectedCount={selected.length}
                    description="repos." />
            </div>
            {pageError===null && <BulkApplyBlock nothingChanged={selected.length === 0 || bulkSettings.nothingChanged} onApply={onApply} isApplying={bulkSettings.isApplying} isLoading={isLoading} />}
        </>
    )
}

export const BulkPreSelected = () => {
    return (
        <BulkPage content={(spe, pe)=><BulkForm setPageError={spe} pageError={pe}/>} />
    )
}