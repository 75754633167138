import { InTextLink } from "../../../kit/Badges"
import { useState } from "react"
import { ProviderStepWithTextArea, ProviderStepWithTextInput, ProviderStepWrapper, ProviderTestAndSave, StorageWrapperComponent, useStorageSection } from "./ProvidersCommon"

export const GoogleCloudStorageInfo = ({ onComplete, onCancel, onTest, nameProvided, connectionString, editMode, setPageError }) => {

    const [bucketName, setBucketName] = useState(connectionString || '');

    const storageEditor = useStorageSection(onCancel, onTest, onComplete, () => bucketName, editMode, setPageError);
    const serviceAccount = () => storageEditor?.providers?.list["GoogleCloudStorageBucket"]?.userInterfaceHelper.parameters.ServiceAccount;
    const canContinue = bucketName !== '';


    return (
        <>
            <StorageWrapperComponent className="bg-red-50/20" providers={storageEditor.providers}>
                <ProviderStepWrapper stepTitle="Step 1"
                >
                    <p>
                        Create <InTextLink url="https://cloud.google.com/storage/docs/creating-buckets" title="Google Cloud Storage Bucket" />
                    </p>
                </ProviderStepWrapper>
                <ProviderStepWithTextInput stepTitle="Step 2"
                    value={serviceAccount()} readonly={true} copy={true}
                >
                    <p>
                        Grant <b>Storage Object Admin</b> role to the Service Account
                    </p>
                </ProviderStepWithTextInput>
                <ProviderStepWithTextArea
                    stepTitle="Step 3"
                    value={bucketName}
                    setValue={setBucketName}
                >
                    <p>
                        Type your bucket name:
                    </p>
                </ProviderStepWithTextArea>
            </StorageWrapperComponent>
            <ProviderTestAndSave storageEditor={storageEditor} nameIncluded={nameProvided}  canContinue={canContinue} />
        </>
    )
}