import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../util/Loader";
import { Post } from "../../helpers/requests";
import { addOrUpdateSchedule } from "../../slices/dataSlice";
import { useEffect, useState } from "react";
import { ReUnixCron, Tab } from "@sbzen/re-cron";
import { Tooltip } from "react-tooltip";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { createSelector } from "@reduxjs/toolkit";
import { settings } from "../../settings";
import { CircularSpinnerSecondary, FullScreenLoader } from "../../kit/Spinners";
import axios from "axios";
import { processAxiosError } from "../util/ErrorBoundary";
import { setModal } from "../../slices/userSlice";
import { Modals } from "../application/modals/ModalsCommon";
import { ErrorPage } from "../../kit/ErrorPage";


const localization = {
    tabs: {
        hours: 'Hour'
    },
    unix: {
        hour: {
            and: {
                label: "Specific hour (choose one)"
            }
        }
    }
};

const NameInputField = ({ value, setValue }) => {
    return (
        <div className="flex flex-col">
            <label className="ml-1 flex mr-4 text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Schedule Name
            </label>
            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-600">
                <input
                    type="text"
                    name="scheduleName"
                    id="schedule-name"
                    placeholder="Enter schedule name"
                    value={value}
                    onChange={setValue === undefined ? () => { } : (e) => setValue(e.target.value)}
                    className="ring-gray-600 block flex-1 border-0 bg-transparent text-gray-900 placeholder:text-gray-400 focus:ring-0"
                />
            </div>
        </div>
    )
}

const CronExpressionInputField = ({ value }) => {
    return (
        <div className="flex flex-col w-full sm:w-max">
            <label className="ml-1 flex mr-4 text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Cron
            </label>
            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-600">
                <input
                    type="text"
                    name="scheduleName"
                    readOnly
                    id="schedule-name"
                    value={value}
                    className="rounded-md ring-gray-600 block flex-1 border-0 bg-gray-100 text-gray-500 placeholder:text-gray-400 focus:ring-0 font-medium tracking-[0.5rem] text-center min-w-full"
                />
            </div>
        </div>
    )
}

export const ScheduleEditor = () => {

    let { id } = useParams();
    //If schedule not found - try load it from server
    const [isLoading, setIsLoading] = useState(true);
    const [schedule, setSchedule] = useState({ scheduleId: 0, name: "", cron: "0 0 ? * *" });
    const [pageError, setPageError] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loadSchedule = () => {
        setIsLoading(true);
        axios.get(settings.backendUrl + "/v2/schedule?scheduleId=" + id)
            .then(res => {
                if (res.data.isSuccess) {
                    setSchedule(res.data.result);
                }
                else {
                    setPageError(res.data.errorDescription);
                }
            })
            .catch(e => {
                processAxiosError(e);
                setPageError(e);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const tryAgain = () => {
        setPageError(null);
        loadSchedule();
    }

    useEffect(() => {
        if (id !== undefined) {
            loadSchedule();
        }
        else {
            setIsLoading(false);
        }
    }, [])
    const [saveBusy, setSaveBusy] = useState(false);
    const save = () => {
        setSaveBusy(true);
        if (schedule.scheduleId === 0) {            
            Post(settings.backendUrl + "/v2/schedules/add", schedule, (res) => {
                if (res.data.isSuccess) {
                    schedule.scheduleId = res.data.result;
                    dispatch(addOrUpdateSchedule(schedule));
                    navigate(-1);
                }
                else {
                    dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to add", text: res.data.errorDescription }));
                }
            }, (e) => {
                processAxiosError(e);
                dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to update", text: "The schedule couldn't be added. Please try again later." }));
            }, setSaveBusy);
        }
        else {
            Post(settings.backendUrl + "/v2/schedules/update", schedule, (res) => {
                if (res.data.isSuccess) {
                    dispatch(addOrUpdateSchedule(schedule));
                    navigate(-1);
                }
                else {
                    dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to update", text: res.data.errorDescription }));
                }
            }, (e) => {
                processAxiosError(e);
                dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to update", text: "The schedule couldn't be updated. Please try again later." }));
            }, setSaveBusy);
        }
    }

    const onCronChange = (x) => {
        //Check hours
        let chunks = x.split(" ");
        let hours = x.split(" ")[1];
        let many = hours.split(",");
        let cron = "0 ";
        if (many.length > 1) {
            hours = many[many.length - 1];
        }

        cron = cron.concat(hours, " ", chunks[2], " ", chunks[3], " ", chunks[4]);

        setSchedule({ ...schedule, cron: cron });
    }

    return pageError !== null ? <ErrorPage tryAgainAction={tryAgain} canTryAgain={true} /> : (
        <div className="w-full bg-gray-100">
            {isLoading ? <FullScreenLoader className="h-96" fillColor="gray" strokeColor="black" /> :
                <div className="px-3 sm:px-6 md:px-16 xl:px-32 2xl:px-48">
                    <div className="bg-white rounded-lg shadow px-3 mt-5">
                        <div className="pt-3">
                            <div className="flex flex-1 flex-row flex-wrap gap-2 mb-4">
                                <CronExpressionInputField value={schedule.cron} />
                                <div className="flex-1 min-w-[50%] min-w-max">
                                    <NameInputField value={schedule.name} setValue={(v) => setSchedule({ ...schedule, name: v })} />
                                </div>
                            </div>
                            <div className="recron">
                                <ReUnixCron localization={localization} cssClassPrefix="cron-" value={schedule.cron} tabs={[Tab.HOURS, Tab.DAY, Tab.MONTH]}
                                    onChange={onCronChange} />
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse pb-4">
                            <button type="button"
                                className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:w-auto "
                                onClick={() => navigate(-1)}>
                                Cancel
                            </button>
                            {schedule.name !== ""
                                ?
                                <button type="button"
                                    disabled={saveBusy}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:mt-0 sm:w-auto"
                                    onClick={save}>
                                    {saveBusy && <CircularSpinnerSecondary light={true} className="mr-1" />}
                                    {saveBusy ? "Saving" : "Save"}
                                </button>
                                :
                                <>
                                    <button disabled type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:mt-0 sm:w-auto"
                                        data-tooltip-id="schedule-warn" data-tooltip-content="Please insert schedule name"
                                        style={{ cursor: "not-allowed" }}>
                                        Save
                                    </button>
                                    <Tooltip id="schedule-warn" />
                                </>
                            }
                        </div>
                    </div>
                </div>}
        </div>
    )
}