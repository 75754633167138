import { Dialog } from "@headlessui/react"
import { ExclamationTriangleIcon, XCircleIcon } from "@heroicons/react/24/outline"
import { ButtonType, FormButton } from "../../../kit/Buttons"
import { Link } from "react-router-dom"
import { MarkGithubIcon } from "@primer/octicons-react"
import { settings } from "../../../settings"


export const ErrorModalWithSimpleCloseButton = ({ onClose, data }) => {

    return (
        <>
            <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                    <XCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-2">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {data.title}
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            {data.text}
                        </p>                        
                        {data.errorCode === "planLimitExceeded" &&
                            <Link to={settings.marketplaceUrl} target="_blank"
                                className="mt-3 w-max inline-flex justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
                                <MarkGithubIcon className="h-5 w-5 mr-1" />
                                Upgrade your plan
                            </Link>}
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-6 flex justify-center sm:justify-end">
                    <FormButton onClick={onClose} btnText="Close" buttonType={ButtonType.Cancel} />
                </div>
        </>
    )
}