import axios from "axios"
import { useEffect, useState } from "react"
import { isDevelopment, settings } from "../../settings"
import { FullScreenLoader, NewLoader } from "../../kit/Spinners";
import { useBroadcastChannel } from "../storage/Providers/ProvidersCommon";
import { ErrorAlert } from "../../kit/Alerts";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { getRestoreStatus } from "../../helpers/domainHelpers";
import { RestoreStatus } from "../../model/domain";
import { CheckBadgeIcon, CheckCircleIcon, ChevronDoubleRightIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { Link, useLocation } from "react-router-dom";
import { ArrowPathIcon, LifebuoyIcon } from "@heroicons/react/24/outline";
import { MarkGithubIcon } from "@primer/octicons-react";
import { ExtraPaths, Paths } from "../../helpers/navigationHelper";

const checkAppInstalled = (accountName, onSuccess, onAuthRequired, onInstallRequired, onError = (e) => console.log(e)) => {
    axios.get(settings.backendUrl + "/backup/restore/installation?owner=" + accountName)
        .then(res => {
            if (res.data.isSuccess) {
                onSuccess(res.data.result);
            }
            else if (res.data.errorCode === "unauthorized") {
                onAuthRequired(res.data.result);
            }
            else {
                onInstallRequired(res.data.result);
            }
        })
        .catch(e => { onError(e) })
}

const RestoreInstallVerifyStep = ({ accountName, setCurrentStep, setStepData }) => {
    const [error, setError] = useState(null);
    const onPass = (data) => {
        setStepData(sd => ({ ...sd, installLink: data.installUrl, installationId: data.installationId }));//installationId,installUrl
        setCurrentStep(restoreSteps.create);
    }

    const onAuthRequired = (link) => {
        setStepData(sd => ({ ...sd, authLink: link }));
        setCurrentStep(restoreSteps.authorize);
    }

    const onInstallRequired = (link) => {
        setStepData(sd => ({ ...sd, installLink: link }));
        setCurrentStep(restoreSteps.install);
    }

    useEffect(() => {
        checkAppInstalled(accountName, onPass, onAuthRequired, onInstallRequired, setError)
    }, [])

    return (
        <>
            {error === null ?
                <FullScreenLoader title="Verifying installation" />
                :
                <div>Error occured. Try again or contact support</div>
            }
        </>
    )
}

const RestoreAuthorize = ({ setCurrentStep, stepData }) => {

    const [error, setError] = useState(null);
    const authBroadcast = useBroadcastChannel((a) => { setError(a.text) });
    const onAuth = () => {
        authBroadcast.setBusy(true);
        const newpopup = window.open(stepData.authLink, "oauth-authorize",
            (isDevelopment ? "scrollbars=yes,resizable=yes,status=yes,toolbar=yes,menubar=yes,location=yes," : "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,") + 'height=800,width=1016')
        authBroadcast.checkExit(newpopup, "Failed to authorize");
    }

    useEffect(() => {
        if (authBroadcast.extParams !== null && authBroadcast.extParams.error === undefined) {
            setCurrentStep(restoreSteps.verifyInstall);
        }
        else if (authBroadcast.extParams !== null && authBroadcast.extParams.error === undefined) {
            setError("Error during authorization");
        }
    }, [authBroadcast.extParams])

    return (
        <div className="flex justify-center flex-col">
            {error !== null && <div>{error} Try again or contact support</div>}
            {authBroadcast.busy ?
                <FullScreenLoader title="Waiting for authorization" />
                :
                <>
                    <h3 className="p-2">Authorize Cloudback Restore App to continue restore process.</h3>
                    <button
                        className="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                        onClick={onAuth}>Authorize Cloudback Restore App</button>
                </>
            }
        </div>
    )
}

const RestoreAppInstall = ({ setCurrentStep, stepData, targetAccount }) => {

    const [error, setError] = useState(null);
    const installBroadcast = useBroadcastChannel((a) => { setError(a.text) });

    const onInstall = () => {
        installBroadcast.setBusy(true);
        setError(null);
        const newpopup = window.open(stepData.installLink, "oauth-authorize",
            (isDevelopment ? "scrollbars=yes,resizable=yes,status=yes,toolbar=yes,menubar=yes,location=yes," : "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,") + 'height=800,width=1016')
        installBroadcast.checkExit(newpopup, "Failed to install");
    }

    useEffect(() => {
        if (installBroadcast.extParams !== null && installBroadcast.extParams.error === undefined) {
            //Verify
            setCurrentStep(restoreSteps.verifyInstall);
        }
        else if (installBroadcast.extParams !== null && installBroadcast.extParams.error === undefined) {
            setError("Error during installation");
        }
    }, [installBroadcast.extParams])


    return (
        <div className="flex justify-center flex-col">
            {installBroadcast.busy ?
                <FullScreenLoader title="Waiting for installation" />
                :
                <>
                    <h3 className="p-2">Install Cloudback Restore App into <b>{targetAccount}</b> account to continue restore process. Make sure you grant <b>All Repositories</b> access</h3>
                    <button
                        className="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                        onClick={onInstall}>Install Cloudback Restore App</button>
                </>
            }
            {error !== null && <ErrorAlert className="mt-3" title="Error" text={error} onClose={() => setError(null)} />}
        </div>
    )
}

const RestoreCreateRepo = ({ restoreData, setCurrentStep, setStepData, stepData }) => {
    const [error, setError] = useState(null);

    const onCreateRepository = () => {
        axios.post(settings.backendUrl +
            "/backup/restore/createRepository?accountName=" + restoreData.targetOwner +
            "&repositoryName=" + restoreData.targetRepo +
            "&backupId=" + restoreData.backupId +
            "&isPrivate=" + restoreData.isPrivate)
            .then(res => {
                if (res.data.isSuccess) {
                    setStepData(sd => ({ ...sd, repositoryId: res.data.result.repositoryId, repositoryUrl: res.data.result.htmlUrl }))
                    setCurrentStep(restoreSteps.verifyPermissions)
                }
                else {
                    setError("Error creating repository!");
                }
            })
    }
    useEffect(() => {
        if (stepData.repositoryId !== null) {
            setCurrentStep(restoreSteps.verifyPermissions);
        }
        else {
            onCreateRepository();
        }
    }, [])

    const onStepBack = () => {
        setCurrentStep(restoreSteps.install);
    }

    return (
        <div className="flex justify-center flex-col">
            {error !== null ?
                <div>
                    <ErrorAlert title="Error" content={<p>{error}. Step back to installation step or try again</p>} onClose={() => setError(null)} />
                    <div className="flex flex-row mt-2 justify-between">
                        <button
                            className="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                            onClick={onStepBack}>Step back</button>
                        <button className="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                            onClick={onCreateRepository}>Try again</button>
                    </div>
                </div>
                :
                <FullScreenLoader title="Creating repository..." />
            }
        </div>
    )
}

const RestoreVerifyPermissionsStep = ({ stepData, setCurrentStep }) => {
    const [error, setError] = useState(null);
    const onVerifyPermissions = () => {
        axios.post(settings.backendUrl +
            "/backup/restore/installationPermission?installationId=" + stepData.installationId + "&repoId=" + stepData.repositoryId)
            .then(res => {
                if (res.data.isSuccess) {
                    setCurrentStep(restoreSteps.restoring)
                }
                else {
                    setError("Error occurred during restore!");
                }
            })
    }
    useEffect(() => {
        onVerifyPermissions();
    }, [])

    const onStepBack = () => {
        setCurrentStep(restoreSteps.authorize);
    }
    return (
        <div className="flex justify-center flex-col">
            {error !== null ?
                <div>
                    <ErrorAlert title="Error" content={<p className="text-sm">{error}. Step back to installation step or try again</p>} onClose={() => setError(null)} />
                    <div className="flex flex-row mt-2 justify-between">
                        <button
                            className="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                            onClick={onStepBack}>Step back</button>
                        <button className="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                            onClick={onVerifyPermissions}>Try again</button>
                    </div>
                </div>
                :
                <FullScreenLoader title="Verifying permissions..." />
            }
        </div>
    )
}

const restoreSelector = createSelector([state => state.data.dashboard?.loadedRestores, (state, defId) => defId, (state, defId, restoreId) => restoreId],
    (restores, defId, restoreId) => {
        let arr = restores[defId];
        if (arr === undefined) return null;
        let index = arr.findIndex(x => x.restoreId === restoreId);
        if (index !== -1) return arr[index];
        return null;
    })

const RestoreInProgressState = ({ restoreId, restoreData }) => {
    const restore = useSelector(state => restoreSelector(state, restoreData.defId, restoreId));//TODO: definition id -> restore id
    
    const running = restore !== null && restore !== undefined && restore.status !== RestoreStatus.failed && restore.status !== RestoreStatus.undefined && restore.status !== RestoreStatus.succeeded;
    const inProgress = running && restore.status !== RestoreStatus.queued;
    const succeeded = restore !== null && restore !== undefined && restore.status === RestoreStatus.succeeded;
    const failed = restore !== null && restore !== undefined && restore.status === RestoreStatus.failed;
    const unknown = restore === null || restore === undefined;

    const location = useLocation();

    return (
        <div className="flex flex-col h-full items-center">
            <div className="flex flex-row justify-center items-center">
                <div className="flex justify-center">
                    <div className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 font-medium text-gray-600 h-max">
                        <span className="mr-1">From:</span>
                        <a href={`https://github.com/${restoreData.sourceOwner}/${restoreData.sourceRepo}`} target="_blank"
                            className="font-semibold underline">{restoreData.sourceOwner} / {restoreData.sourceRepo}</a>
                    </div>
                </div>
                <div className="flex justify-center py-5 mx-5">
                    {running && <NewLoader />}
                    {succeeded && <CheckCircleIcon className="h-10 w-10 text-green-600" />}
                    {failed && <ExclamationCircleIcon className="h-10 w-10 text-red-600" />}
                    {unknown && <NewLoader />}
                </div>
                <div className="flex justify-center">
                    <div className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 font-medium text-gray-600 h-max">
                        <span className="mr-1">To:</span>
                        <a href={`https://github.com/${restoreData.targetOwner}/${restoreData.targetRepo}`} target="_blank"
                            className="font-semibold underline">{restoreData.targetOwner} / {restoreData.targetRepo}</a>
                    </div>
                </div>
            </div>
            {failed &&
                <>
                    <div className="my-5 flex flex-row justify-center items-baseline">
                        <p className="text-md font-semibold mr-2">Error occured: </p>
                        <p className="text-sm text-center">{restore.errorDescription}</p>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-2 justify-center w-full">
                        <Link
                            to={ExtraPaths.contactUs.path}
                            type="button"
                            className="w-full inline-flex sm:w-max justify-center sm:justify-start rounded-md bg-gray-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500"
                        >
                            <LifebuoyIcon className="h-5 w-5 mr-1" />
                            Contact support
                        </Link>
                        <a
                            href={location.pathname}
                            type="button"
                            className="w-full inline-flex sm:w-max justify-center sm:justify-start rounded-md bg-gray-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500"
                        >
                            <ArrowPathIcon className="h-5 w-5 mr-1" />
                            Start over
                        </a>
                    </div>
                </>
            }
            {(running || succeeded) &&
                <>
                    <div className="w-full">
                        <div>
                            <h4 className="sr-only">Status</h4>
                            <p className="text-sm font-medium text-gray-900">Restoring repository...</p>
                            <div className="mt-6" aria-hidden="true">
                                <div className="overflow-hidden rounded-full bg-gray-200">
                                    <div className="h-2 rounded-full bg-gray-600" style={{ width: !succeeded && !inProgress ? '33%' : !succeeded ? '66%' : '100%' }} />
                                </div>
                                <div className="mt-6 hidden grid-cols-3 text-sm font-medium text-gray-300 sm:grid">
                                    <div className="text-gray-600">Queued</div>
                                    <div className={inProgress || succeeded ? "text-center text-gray-600" : "text-center"}>In Progress</div>
                                    <div className={succeeded ? "text-right text-gray-600" : "text-right"}>Succeeded</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!succeeded &&
                        <>
                            <div className="mt-10 mb-5">
                                <p className="text-sm text-center">Restore process will run in background and may take significant time based on repository and metadata size. <br />You can continue to dashboard.</p>
                            </div>
                            <div>
                                <Link
                                    to={"/"}
                                    type="button"
                                    className="w-full sm:w-max justify-center sm:justify-start rounded-md bg-gray-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500"
                                >
                                    Return to dashboard
                                </Link>
                            </div>
                        </>
                    }
                </>
            }
            {succeeded &&
                <>
                    <div className="my-5 flex flex-row justify-center items-baseline">
                        <p className="text-md font-semibold mr-2">Restore finished successfully</p>
                    </div>
                    <Link
                        to={`https://github.com/${restoreData.targetOwner}/${restoreData.targetRepo}`}
                        target="_blank"
                        type="button"
                        className="w-full inline-flex sm:w-max justify-center sm:justify-start rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500"
                    >
                        <MarkGithubIcon className="h-5 w-5 mr-1 !block" />
                        Go to restored repository
                    </Link>
                </>
            }
        </div>
    )
}

const RestoreProcedure = ({ restoreData, stepData }) => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [restoreRunning, setRestoreRunning] = useState(false);
    const [restoreId, setRestoreId] = useState(null);

    const onRestore = () => {
        axios.post(settings.backendUrl + "/backup/restore", {
            backupId: restoreData.backupId,
            installationId: stepData.installationId,
            repositoryId: stepData.repositoryId,
            repositoryUrl: stepData.repositoryUrl
        })
            .then((res) => {
                if (res.data.isSuccess) {
                    setRestoreRunning(true);
                    setRestoreId(res.data.result);
                    setIsLoading(false);
                }
                else {
                    setError("Error requesting restore");
                }
            })
    }
    useEffect(() => {
        onRestore();
    }, [])

    return (
        <>
            {error !== null ? <div>Error!</div>
                : isLoading ?
                    <FullScreenLoader title="Starting restore..." />
                    : <RestoreInProgressState restoreId={restoreId} restoreData={restoreData} />}
        </>
    )
}

const restoreSteps = {
    authorize: "authorize",
    create: "create",
    verifyInstall: "verifyInstall",
    install: "install",
    verifyPermissions: "verifyPermissions",
    restoring: "restoring"
}

export const RestoreStepThree = ({ restoreData }) => {

    const [currentStep, setCurrentStep] = useState(restoreSteps.verifyInstall);
    const [stepData, setStepData] = useState({
        authLink: '',
        installLink: '',
        installationId: null,
        repositoryId: null,
        respositoryUrl: '',
        prevStepError: null
    });
    return (
        <div className="flex justify-center">
            {{
                [restoreSteps.verifyInstall]: <RestoreInstallVerifyStep accountName={restoreData.targetOwner} setStepData={setStepData} setCurrentStep={setCurrentStep} />,
                [restoreSteps.authorize]: <RestoreAuthorize setCurrentStep={setCurrentStep} stepData={stepData} />,
                [restoreSteps.install]: <RestoreAppInstall targetAccount={restoreData.targetOwner} setCurrentStep={setCurrentStep} stepData={stepData} />,
                [restoreSteps.create]: <RestoreCreateRepo stepData={stepData} setCurrentStep={setCurrentStep} restoreData={restoreData} setStepData={setStepData} />,
                [restoreSteps.verifyPermissions]: <RestoreVerifyPermissionsStep setCurrentStep={setCurrentStep} stepData={stepData} />,
                [restoreSteps.restoring]: <RestoreProcedure setCurrentStep={setCurrentStep} restoreData={restoreData} stepData={stepData} />
            }[currentStep]}
        </div>
    )
}