import { useEffect, useState } from "react";
import axios from "axios";
import { settings } from "../../settings";
import { useDispatch, useSelector } from "react-redux";
import { preload, updateCards } from "../../slices/dataSlice";
import { CircularSpinnerSecondary, FullScreenLoader } from "../../kit/Spinners";
import { Link, useNavigate } from "react-router-dom";
import { classNames } from "../application/ApplicationHeader";
import { createSelector } from "@reduxjs/toolkit";
import { StorageImg, cardCanBeSelectedOrBackedUp } from "../../helpers/domainHelpers";
import { useNotifier } from "../../hooks/notifications/notificationActions";
import { SelectBox } from "../../kit/SelectBox";
import {ButtonType, FormButton} from "../../kit/Buttons"
import { setModal } from "../../slices/userSlice";
import { Modals } from "../application/modals/ModalsCommon";
import { processAxiosError } from "../util/ErrorBoundary";
import { ErrorPage } from "../../kit/ErrorPage";

export const filteredCardsSelector = createSelector([state => state.data.dashboard.cards],
    (cards) => cards.filter(x => cardCanBeSelectedOrBackedUp(x)))

export const BulkPage = ({ content }) => {

    const [pageError, setPageError] = useState(null);

    return pageError !== null ? <ErrorPage canTryAgain={false} /> : (
        <div className="w-full bg-gray-100">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-5">
                <div className="mx-auto max-w-3xl rounded-lg bg-white shadow py-3 px-4 mt-5">
                    {content(setPageError, pageError)}
                </div>
            </div>
        </div>
    )
}

export const dontChangeSchedule = { scheduleId: 0, name: "Don't change" };
const extraSchedule = { 0: dontChangeSchedule };
const BulkScheduleSelector = ({ schedule, onSchedule, isLoading }) => {

    const schedules = useSelector(state => state.data.schedules);
    const options = Object.values({ ...extraSchedule, ...schedules });

    return (
        <div className="sm:col-span-3">
            <SelectBox label="Schedule" options={options}
                isLoading={isLoading}
                selected={schedule}
                setSelected={onSchedule}
                keySelector={(option) => option?.scheduleId}
                labelSelector={(option) => option?.name}
            />
        </div>
    )
}

export const dontChangeStorage = { storageId: 0, storageName: "Don't change" };
const extraStorage = { 0: dontChangeStorage };
const BulkStorageSelector = ({ storage, onStorage,isLoading }) => {

    const storages = useSelector(state => state.data.storages);
    const options = Object.values({ ...extraStorage, ...storages });

    return (
        <div className="sm:col-span-3">
            <SelectBox label="Storage" options={options}
                isLoading={isLoading}
                selected={storage}
                setSelected={onStorage}
                keySelector={(option) => option?.storageId}
                labelSelector={(option) => option?.storageName}
                leftContentSelector={(option)=>option?.storageId===dontChangeStorage.storageId?<></>:<StorageImg className="w-5 h-5 min-h-5 min-w-5" alt="storage-img" isCustomStorage={option?.isCustomStorage} storageName={option?.storageName} provider={option?.provider} />}
            />
        </div>
    )
}

export const dontChangeRetention = { retentionPolicyId: 0, name: "Don't change" };
const extraRetention = { 0: dontChangeRetention };
const BulkRetentionSelector = ({ retention, onRetention,isLoading }) => {
    const retentions = useSelector(state => state.data.retentions);
    const options = Object.values({ ...extraRetention, ...retentions });

    return (
        <div className="sm:col-span-3">
            <SelectBox label="Retention policy" options={options}
                isLoading={isLoading}
                selected={retention}
                setSelected={onRetention}
                keySelector={(option) => option.retentionPolicyId}
                labelSelector={(option) => option.name}
            />
        </div>
    )
}

export const dontChangeToggle = { name: "Don't change", value: 2 };
const BulkToggleSelector = ({ toggle, onToggle, isLoading }) => {

    const options = Object.values(({ 2: dontChangeToggle, 1: { name: "Scheduled", value: 1 }, 0: { name: "Not scheduled", value: 0 } }));


    return (
        <div className="sm:col-span-3">
            <SelectBox label="State" options={options}
                isLoading={isLoading}
                selected={toggle}
                setSelected={onToggle}
                keySelector={(option) => option?.value}
                labelSelector={(option) => option?.name}
            />
        </div>
    )
}

export const useBulkSettings = () => {

    const [schedule, setSchedule] = useState(dontChangeSchedule);
    const [storage, setStorage] = useState(dontChangeStorage);
    const [retention, setRetention] = useState(dontChangeRetention);
    const [toggle, setToggle] = useState(dontChangeToggle);
    const [notifySuccess, notifyInfo] = useNotifier();
    const [isApplying, setIsApplying] = useState(false);
    const dispatch = useDispatch();
    const onApply = (selected, afterApply) => {
        //axios goes here
        setIsApplying(true);
        axios.post(settings.backendUrl + "/bulk/repo-settings/update", {
            definitionIds: selected,
            scheduleId: schedule.scheduleId === dontChangeSchedule.scheduleId ? null : schedule.scheduleId,
            storageId: storage.storageId === dontChangeStorage.storageId ? null : storage.storageId,
            retentionPolicyId: retention.retentionPolicyId === dontChangeRetention.retentionPolicyId ? null : retention.retentionPolicyId,
            stateId: toggle.value === dontChangeToggle.value ? null : toggle.value,
        })
            .then(res => {
                if (res.data.isSuccess) {
                    dispatch(updateCards(res.data.result.cards));
                    notifySuccess("Succesfully updated");
                    if (afterApply !== undefined) afterApply();                    
                }
                else{
                    dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to update", text: res.data.errorDescription }));
                }
            })
            .catch(e => {
                processAxiosError(e);
                dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to update", text: "The settings couldn't be updated. Please try again later."}));
            })
            .finally(() => {
                setIsApplying(false);
            })
    }
    const nothingChanged = schedule === dontChangeSchedule && storage === dontChangeStorage && retention === dontChangeRetention && toggle === dontChangeToggle;
    return { nothingChanged, schedule, setSchedule, storage, setStorage, retention, setRetention, toggle, setToggle, onApply, isApplying }
}

export const BulkApplyBlock = ({ onApply, isApplying, isLoading,nothingChanged }) => {

    const navigate = useNavigate();
    const onSave = () => {

        onApply();
    }
    return (
        <div className="mt-6 sm:mt-4 sm:flex sm:flex-row-reverse">
            <FormButton onClick={() => navigate(-1)} btnText="Cancel" buttonType={ButtonType.Cancel}/>
            <FormButton disabled={nothingChanged||isApplying||isLoading} onClick={onSave} busy={isApplying} loading={isLoading} btnText={isApplying ? "Applying" : "Apply"}/> 
        </div>
    )
}


export const BulkSettingsSection = ({ description, selectedCount, bulkSettings, className, isLoading, setIsLoading, pageError, setPageError }) => {

   
    const dispatch = useDispatch();
    const loadSettings = () => {
        setIsLoading(true);
        axios.get(settings.backendUrl + "/v2/preload")
            .then((res) => {
                if (res.data.isSuccess) {
                    dispatch(preload(res.data.result));
                }
                else {
                    setPageError(res.data.errorDescription);
                }
            })
            .catch((e) => {
                processAxiosError(e);    
                setPageError("The settings couldn't be loaded. Please try again later.");            
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        loadSettings();
    }, [])

    const tryAgain = () => {
        setPageError(null);
        loadSettings();
    }
    return pageError!==null ? <ErrorPage canTryAgain={true} tryAgainAction={tryAgain} /> : (
        <div className={classNames("border-b border-gray-900/10 pb-6 !mt-2", className)}>
            <h2 className="text-base font-normal leading-6 text-gray-900">The settings below will be applied to
                <span className="mx-1 inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-green-700">
                    {selectedCount}
                </span>
                {description}
            </h2>
            <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                <BulkToggleSelector toggle={bulkSettings.toggle} onToggle={bulkSettings.setToggle}   isLoading={isLoading}/>
                <BulkScheduleSelector schedule={bulkSettings.schedule} onSchedule={bulkSettings.setSchedule} isLoading={isLoading}/>
                <BulkStorageSelector storage={bulkSettings.storage} onStorage={bulkSettings.setStorage}  isLoading={isLoading}/>
                <BulkRetentionSelector retention={bulkSettings.retention} onRetention={bulkSettings.setRetention}  isLoading={isLoading}/>                
            </div>
        </div>
    )
}