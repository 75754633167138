


import { LogoGithubIcon, MarkGithubIcon } from "@primer/octicons-react"
import CloudbackLogo from "./../../assets/logo-black.svg"
import { EmptyState } from "../../kit/EmptyState"
import { classNames } from "../application/ApplicationHeader"
import { dynamicSettings, settings } from "../../settings"

const cloudbackIcon = (props) => {
    return <img        
        src={CloudbackLogo}
        alt="Cloudback"
        {...props}
    />;
}

export const EmptyReposItems = [
    {
        title: 'Install',
        description: 'Ensure that you have installed the Cloudback application and granted the repository permissions',
        icon: cloudbackIcon,
        href: dynamicSettings.appInstallUrl
    },
    {
        title: 'Create a new repository',
        description: 'If you don\'t have any repositories yet, start with creating new one',
        icon: MarkGithubIcon,
        href: "https://github.com/new"
    },
]
export const EmptyRepoState = () => {
    return (
        <div className="w-full flex justify-center mt-10">
        <EmptyState title="No repositories to backup or restore" 
        text="Here are some friendly suggestions for what to do next"
        Icon={()=><></>}
        content={
            <div className="flex flex-col">
                <ul role="list" className="mt-4 grid grid-cols-1 gap-6 border-b border-t border-gray-200 py-6 sm:grid-cols-2">
                    {EmptyReposItems.map((item, itemIdx) => (
                        <li key={itemIdx} className="flex-col flex">
                            <div className="flex-1 relative -m-2 flex items-start space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-gray-500 hover:bg-gray-200">
                                <div
                                    className={classNames(
                                        item.background,
                                        'flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg'
                                    )}
                                >
                                    <item.icon className="h-8 w-8 !block" aria-hidden="true" />
                                </div>
                                <div>
                                    <h3 className="text-sm font-medium text-gray-900">
                                        <a href={item.href} className="focus:outline-none">
                                            <span className="absolute inset-0" aria-hidden="true" />
                                            <span>{item.title}</span>
                                            <span aria-hidden="true"> &rarr;</span>
                                        </a>
                                    </h3>
                                    <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        } />
        </div>
    )
}