import { useEffect, useMemo, useState } from 'react';
import { ReactComponent as CloudbackLogo } from './../../assets/logo-black.svg'
import { useDispatch, useSelector } from 'react-redux';
import { preload } from '../../slices/dataSlice';
import { timeZoneMap } from '../settings/AccountPage';
import { CircularSpinnerSecondary, FullScreenLoader, NewLoader } from '../../kit/Spinners';
import axios from 'axios';
import { settings } from '../../settings';
import { LoadingAutocomplete, useAutocomplete } from '../../kit/Autocomplete';
import { StorageImg } from '../../helpers/domainHelpers';
import { passInitialSetup, setModal } from '../../slices/userSlice';
import { Tooltip } from 'react-tooltip';
import { classNames } from '../application/ApplicationHeader';
import { FormButton } from '../../kit/Buttons';
import { processAxiosError } from '../util/ErrorBoundary';
import { ErrorPage } from '../../kit/ErrorPage';
import { Modals } from '../application/modals/ModalsCommon';

const TermsBlock = ({ onAccepted }) => {
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [policyAccepted, setPolicyAccepted] = useState(false);

    useEffect(() => {
        if (policyAccepted && termsAccepted) {
            onAccepted(true);
        }
        else {
            onAccepted(false);
        }
    }, [policyAccepted, termsAccepted])

    return (
        <>
            <div className="sm:col-span-full mt-3">
                <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                        <input
                            type="checkbox"
                            checked={termsAccepted}
                            value={termsAccepted}
                            onChange={e => setTermsAccepted(x => !x)}
                            className="h-4 w-4 rounded border-gray-300 text-gray-600"
                        />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                        <label className="font-medium text-gray-900">
                            I've read and accept the <a href="https://docs.cloudback.it/terms/" target='blank' className="font-semibold underline">Terms of service</a>
                        </label>
                    </div>
                </div>
            </div>
            <div className="sm:col-span-full">
                <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                        <input
                            type="checkbox"
                            checked={policyAccepted}
                            value={policyAccepted}
                            onChange={e => setPolicyAccepted(x => !x)}
                            className="h-4 w-4 rounded border-gray-300 text-gray-600"
                        />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                        <label className="font-medium text-gray-900">
                            I've read and accept the <a href="https://docs.cloudback.it/privacy/" target='blank' className="font-semibold underline">PrivacyPolicy</a>
                        </label>
                    </div>
                </div>
            </div>
        </>

    )
}

function getLessBusySchedule(selectedTimezone, schedulesList, lessBusy) {
    var selectedTimezoneOffsetHours = 0;
    if (selectedTimezone !== "(UTC) Coordinated Universal Time") {
      selectedTimezoneOffsetHours = Number.parseInt(selectedTimezone.substr(4, 3));
    }
    var lessBusyHourLocal = lessBusy + selectedTimezoneOffsetHours;
    if (lessBusyHourLocal < 0) lessBusyHourLocal += 24;
    if (lessBusyHourLocal >= 24) lessBusyHourLocal -= 24;

    const newScheduleId = lessBusyHourLocal === 0 ? 24 : lessBusyHourLocal;
    var schedule = schedulesList[newScheduleId];
    return schedule===null  || schedule===undefined? Object.values(schedulesList)[0]:schedule;
  }

  function initTimezone(){
    var dotnetTimeZone = "(UTC) Coordinated Universal Time";
    var index = timeZoneMap.findIndex((timezone) => timezone.id === dotnetTimeZone);
    var defaultTimezone = timeZoneMap[index];
    try {
      const javascriptTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      var index2 = timeZoneMap.findIndex((timezone) => timezone.jsName === javascriptTimeZone);
      if (index2 !== -1) {
        return timeZoneMap[index2];
      }
    return defaultTimezone;
    } catch (ex) {
        return defaultTimezone;
    }
  }

const EditableUserBlock = ({ initialSettings, isExternalLoading, setPageError }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [saveBusy, setSaveBusy] = useState(false);
    const [autoSchedule, setAutoSchedule] = useState(true);

    const loadSettings = () => {
        setIsLoading(true);
        axios.get(settings.backendUrl + "/v2/preload")
            .then((res) => {
                if (res.data.isSuccess) {
                    dispatch(preload(res.data.result));
                }
                else {
                    setPageError(res.data.errorDescription);
                }
            })
            .catch(e => {
                processAxiosError(e);
                setPageError(e);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        if (!isExternalLoading){
            loadSettings();
        }
    }, [isExternalLoading])

    const userSettings = initialSettings?.userSettings;

    const timeZoneOptionsDict = useMemo(() => timeZoneMap.reduce((acc, val) => ({ ...acc, [val.id]: val }), {}), [timeZoneMap])


    const [schedule, setSchedule] = useState(null);
    const schedules = useSelector((state) => state.data.schedules);

    //TODO: THis called by autocomplete, set auto when only called by user
    const onSetSchedule = (newSchedule)=>{
        if (schedule !== null)
        {
            setAutoSchedule(false);
        }       
        setSchedule(schedule);
    }
    const scheduleAutocomplete = useAutocomplete(schedules, isLoading, userSettings?.defaultScheduleId, schedule, onSetSchedule);

    const [storage, setStorage] = useState(null);
    const storages = useSelector((state) => state.data.storages);
    const storageAutocomplete = useAutocomplete(storages, isLoading, userSettings?.defaultStorageId, storage, setStorage);

    const [retention, setRetention] = useState(null);
    const retentions = useSelector((state) => state.data.retentions);
    const retentionAutocomplete = useAutocomplete(retentions, isLoading, userSettings?.defaultRetentionPolicyId, retention, setRetention);

    const onSetTimezone = (timezone) => {
        setTimezone(timezone);
        if (autoSchedule){
            setSchedule(getLessBusySchedule(timezone.id, schedules, initialSettings.lessBusyHour));
        }
    }

    const [timezone, setTimezone] = useState(null);
    const timezoneAutocomplete = useAutocomplete(timeZoneOptionsDict, isLoading, initTimezone().id, timezone, onSetTimezone);
    const onSave = () => {
        setSaveBusy(true);
        axios.post(settings.backendUrl + "/v2/user/init",
            {
                userSettings: {
                    ...userSettings,
                    defaultScheduleId: schedule.scheduleId,
                    defaultStorageId: storage.storageId,
                    defaultRetentionPolicyId: retention.retentionPolicyId,
                    timezone: timezone.id,
                    isSetupRequired: false
                }
            })
            .then(res => {
                if (res.data.isSuccess) {
                    dispatch(passInitialSetup());
                }
                else{
                    dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to initialize account", text: res.data.errorDescription }));
                }
            })
            .catch(e => {
                processAxiosError(e);
                dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to initialize account", text: "The settings couldn't be saved. Please try again later."}));
            })
            .finally(() => {
                setSaveBusy(false);
            })
    }

    const [accepted, setAccepted] = useState(false);

    const formLoading = timezoneAutocomplete.isLoading || scheduleAutocomplete.isLoading || storageAutocomplete.isLoading || retentionAutocomplete.isLoading || isLoading;

    return (
        <>
            <h2 className="text-base font-semibold leading-7 text-gray-900">Choose default settings</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">The settings will be applied for all the repositories Cloudback has access to. You can edit them later and choose a separate storage for each repository.</p>

            <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                <div className="sm:col-span-full">
                    <LoadingAutocomplete isLoading={timezoneAutocomplete.isLoading}
                        autocomplete={{
                            ...timezoneAutocomplete,
                            labelSelector: (option) => option?.id,
                            keySelector: (option) => option?.id,
                            label: "Timezone"

                        }}
                    />
                </div>
                <div className="sm:col-span-full">
                    <LoadingAutocomplete isLoading={scheduleAutocomplete.isLoading}
                        autocomplete={{
                            ...scheduleAutocomplete,
                            labelSelector: (option) => option?.name,
                            keySelector: (option) => option?.scheduleId,
                            label: "Default schedule"

                        }}
                    />
                </div>
                <div className="sm:col-span-full">
                    <LoadingAutocomplete isLoading={storageAutocomplete.isLoading}
                        autocomplete={{
                            ...storageAutocomplete,
                            labelSelector: (option) => option?.storageName,
                            keySelector: (option) => option?.storageId,
                            optionLeadingContentSelector: (option) => <StorageImg className="w-5 h-5 min-h-5 min-w-5" alt="storage-img" isCustomStorage={option?.isCustomStorage} storageName={option?.storageName} provider={option?.provider} />,
                            label: "Default storage"

                        }}
                    />
                </div>
                <div className="sm:col-span-full">
                    <LoadingAutocomplete isLoading={retentionAutocomplete.isLoading}
                        autocomplete={{
                            ...retentionAutocomplete,
                            labelSelector: (option) => option?.name,
                            keySelector: (option) => option?.retentionPolicyId,
                            label: "Default retention"

                        }}
                    />
                </div>
                <TermsBlock onAccepted={setAccepted} />
                <div className="sm:col-span-full mt-2">
                    <div className="flex items-center justify-end gap-x-6">
                        <FormButton tooltipId="initial-error" tooltipText="Accept the terms and policy first" showTooltip={!accepted}
                            onClick={onSave} busy={saveBusy} disabled={saveBusy || !accepted} loading={formLoading} btnText="Complete" />
                    </div>
                </div>
            </div>
        </>
    )
}


export const InitialSetup = () => {
    const [pageError, setPageError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [initialSettings, setInitialSettings] = useState(null);
    const loadUserSettings = () => {
        setIsLoading(true);
        axios.get(settings.backendUrl + "/v2/user/initial")
            .then(res => {
                if (res.data.isSuccess) {
                    setInitialSettings(res.data.result);
                }
                else{
                    setPageError(res.data.errorDescription);
                }
            })
            .catch((e) => {
                processAxiosError(e);
                setPageError("The settings couldn't be loaded. Please try again later.");
            })
            .finally(()=>{
                setIsLoading(false);
            })
    }

    useEffect(() => {
        loadUserSettings();
    }, [])

    return pageError!==null ? <ErrorPage/>:(
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <CloudbackLogo className="mx-auto h-10 w-auto" />
                <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    You are almost there
                </h2>
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                <div className="bg-white px-3 py-6 shadow sm:rounded-lg sm:px-6">
                    <EditableUserBlock initialSettings={initialSettings} isExternalLoading={isLoading} setPageError={setPageError}/>
                </div>
            </div>
        </div>
    )
}
