import { createSlice } from '@reduxjs/toolkit'
import { SnackbarKey } from 'notistack';

function findIndexesWhere(array, comparison) {
    return array.map((e, i) => comparison(e) ? i : '').filter(String);
  }

const initialState = [];

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action) => {
        const index = state.findIndex(x => x.options.key === action.payload.options.key);       
        if (index === -1) {
          let not = action.payload;
          state.push(not);
        }   
    },
    removeNotification: (state, action) => {
        const index = state.findIndex(x => x.options.key === action.payload);
        if (index !== -1) state.splice(index, 1);      
    },
    removeNotifications: (state, action) => {
        let indexes = findIndexesWhere(state, x => action.payload.findIndex(y => y === x.options.key) !== -1);
        indexes.forEach(x => state.splice(x, 1));
    }    
  }
});

// Action creators are generated for each case reducer function
export const { addNotification, removeNotification, removeNotifications } = notificationSlice.actions;

export default notificationSlice.reducer