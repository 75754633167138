import { InTextLink } from "../../../kit/Badges"
import { useState } from "react"
import { ProviderSettingsWrapper, ProviderStepWithTextArea, ProviderStepWithTextInput, ProviderStepWrapper, ProviderTestAndSave, StorageWrapperComponent, useStorageSection } from "./ProvidersCommon"
import { FullScreenLoader } from "../../../kit/Spinners";

export const AlibabaCloudStorageInfo = ({ onComplete, onCancel, onTest, nameProvided, connectionString, editMode, setPageError}) => {

    const [bucketName, setBucketName] = useState(connectionString || '');


    const storageEditor = useStorageSection(onCancel, onTest, onComplete, () => bucketName, editMode, setPageError);
    const serviceAccount = () => storageEditor?.providers?.list["AlibabaObjectStorageService"]?.userInterfaceHelper.parameters.Principal;
    const canContinue = bucketName !== '';
    return (
        <>

            <StorageWrapperComponent className="bg-red-50/20" providers={storageEditor.providers}>
                <ProviderStepWrapper stepTitle="Step 1"
                >
                    <p>
                        Create <InTextLink url="https://www.alibabacloud.com/help/doc-detail/31885.htm" title="Alibaba Cloud OSS Bucket" />
                    </p>
                </ProviderStepWrapper>
                <ProviderStepWithTextInput stepTitle="Step 2"
                    value={serviceAccount()} readonly={true} copy={true}
                >
                    <p>
                        Grant <b><InTextLink url="https://www.alibabacloud.com/help/doc-detail/85111.htm" title="Any Operation" /></b> access to the Cloudback RAM account:
                    </p>
                </ProviderStepWithTextInput>
                <ProviderStepWithTextArea
                    stepTitle="Step 3"
                    value={bucketName}
                    setValue={setBucketName}
                >
                    <p>
                        Type your <InTextLink url="https://www.alibabacloud.com/help/doc-detail/31834.htm" title="Bucket Domain Name" />:
                    </p>
                </ProviderStepWithTextArea>               
            </StorageWrapperComponent>
            <ProviderTestAndSave storageEditor={storageEditor}
                    canContinue={canContinue} nameIncluded={nameProvided}/>
        </>
    )
}