import ReactApexChart from "react-apexcharts";
import {NoChartDataFound} from "./NoChartDataFound";
import {apexColors} from "./../../util/Colors";


const BackupColumnChart = ({ title, ticker, stats, dates }) => {
  const statsAreEmpty = stats.every(value => value === "0.00");
    const series = [
        {
            name: title,
            data: stats
        }
    ];
    const options = {
        colors:apexColors,       
        chart: {
            type: 'bar',
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '90%',
                dataLabels: {                   
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: true,
            position: 'bottom',
            showForSingleSeries: true,
            onItemClick: {
                toggleDataSeries: false
            },
        },
        xaxis: {
            type: 'category',
            categories: dates,
            tickAmount: 10,
            labels: {
                datetimeUTC: false
              },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },            
            tooltip: {
                enabled: false,
            }
        },
        yaxis: {            
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: true,
                formatter: v=>v.toFixed(2)+ticker,// f***ing magic here, try remove toFixed!
            }
        },
    };
    return (
        <div className="w-full flex-1 min-h-[250px] border border-gray-50 rounded-lg relative">
          { statsAreEmpty && <NoChartDataFound text="No data available or no backups were created during the selected period."/> }
          <ReactApexChart options={options} series={series} type="bar" height="100%" width="98%" />
        </div>
    )
}

export const BackupTimeChart = ({stats}) => {
    return (
        <BackupColumnChart title="Elapsed time" stats={stats.elapsedTimeSec} dates={stats.dates}  ticker={stats.time}/>
    )
}
export const BackupSizeChart = ({stats}) => {    
    return (
        <BackupColumnChart title="Archive size" stats={stats.archiveSizeBytes} dates={stats.dates} ticker={stats.size}/>
    )
}