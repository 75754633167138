import { AuditLogInfoModal } from "./AuditLogInfoModal"
import { BackupInfoModal } from "./BackupInfoModal"
import { BadgeModal } from "./BadgeModal"
import { ConfirmDeleteAccountModal } from "./ConfirmDeleteAccountModal"
import { DownloadBackupModal } from "./DownloadBackupModal"
import { ErrorModalWithSimpleCloseButton } from "./ErrorModals"
import { RestoreInfoModal } from "./RestoreInfoModal"
import { SuccessModalWithSingleCloseButton } from "./SuccessModals"
import { TriggerMultipleResultModal } from "./TriggerMultipleResultModal"
import { WarningModalWithSingleCloseButton } from "./WarningModals"

export const Modals = {
    SuccessModalWithSingleCloseButton: "SuccessModalWithSingleCloseButton",
    ErrorModalWithSingleCloseButton:"ErrorModalWithSingleCloseButton",
    WarningModalWithSingleCloseButton:"WarningModalWithSingleCloseButton",
    BackupInfoModal:"BackupInfoModal",
    RestoreInfoModal:"RestoreInfoModal",   
    ConfirmDeleteAccountModal: "ConfirmDeleteAccountModal",
    DownloadBackupModal:"DownloadBackupModal",
    BadgeModal:"BadgeModal",
    AuditLogInfoModal:"AuditLogInfoModal",
    TriggerMultipleResultModal:"TriggerMultipleResultModal"
}

export const ModalsContent = {
    [Modals.SuccessModalWithSingleCloseButton]: SuccessModalWithSingleCloseButton,
    [Modals.ErrorModalWithSingleCloseButton]:ErrorModalWithSimpleCloseButton,
    [Modals.WarningModalWithSingleCloseButton]:WarningModalWithSingleCloseButton,
    [Modals.BackupInfoModal]: BackupInfoModal,
    [Modals.RestoreInfoModal]:RestoreInfoModal,    
    [Modals.ConfirmDeleteAccountModal]:ConfirmDeleteAccountModal,
    [Modals.DownloadBackupModal]:DownloadBackupModal,
    [Modals.BadgeModal]:BadgeModal,
    [Modals.AuditLogInfoModal]:AuditLogInfoModal,
    [Modals.TriggerMultipleResultModal]:TriggerMultipleResultModal
}