import { configureStore } from "@reduxjs/toolkit"
import dataReducer from "./dataSlice"
import notificationReducer from "./notificationSlice"
import userReducer from "./userSlice"
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web


const persistConfig = {
  key: 'user',
  storage,
  whitelist: ['tableView']
}

const persistedReducer = persistReducer(persistConfig, userReducer);


export const store = configureStore({
  reducer: {
    data: dataReducer,
    notifications: notificationReducer,
    user: persistedReducer,    
  },
  middleware: (defaultMiddleware)=>defaultMiddleware({ serializableCheck: false})
})
export const persistor = persistStore(store);