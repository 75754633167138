import { useDispatch, useSelector } from "react-redux"
import { StorageImg, cardCanBeSelectedOrBackedUp, getBackupStatus, getCard, getRestoreStatus, getScheduleStatusSecondary, selectCard } from "../../helpers/domainHelpers"
import { useMemo, useState } from "react";
import { CircularSpinnerSecondary } from "../../kit/Spinners";
import { ArrowUpOnSquareStackIcon, ChevronRightIcon, NoSymbolIcon, RocketLaunchIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { Paths } from "../../helpers/navigationHelper";
import { classNames } from "../application/ApplicationHeader";
import { createSelector } from "@reduxjs/toolkit";
import { SkipFillIcon, CheckCircleFillIcon, ClockIcon, DesktopDownloadIcon, InfoIcon, LinkExternalIcon, LockIcon, RepoIcon, RepoPushIcon } from "@primer/octicons-react";
import axios from "axios";
import { settings, getGitHubAvatarUrl } from "../../settings";
import { setModal } from "../../slices/userSlice";
import { Modals } from "../application/modals/ModalsCommon";
import { useNotifier } from "../../hooks/notifications/notificationActions";
import { BackupStatus, RestoreStatus } from "../../model/domain";
import { TableCell } from "../../kit/Table";
import { Tooltip } from "react-tooltip";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { processAxiosError } from "../util/ErrorBoundary";
import { LongInvisibleInput, LongTextLink, LongTextTag } from "../../kit/LongInvisibleInput";
import { BaseTransition } from "../../kit/Transitions";
import { StorageProviderName } from "../../helpers/domainHelpers";
import { ScheduleStatus } from "../../model/domain";
import ReactDOMServer from 'react-dom/server';

const RepoCardHeader = ({ repoId }) => {
    const card = useSelector((state) => selectCard(state, repoId));
    const repo = card.repository;
    const dispatch = useDispatch();
    const [notifySuccess, notifyInfo] = useNotifier();
    const [triggerBusy, setTriggerBusy] = useState(false);
    const triggerSelected = () => {
        //build cards list for trigger
        setTriggerBusy(true);
        axios.post(settings.backendUrl + "/v2/triggerSingle", {repositoryIds:[card.repositoryId]})
            .then((res) => {
                if (res.data.isSuccess) {                    
                    notifySuccess("Backup started", "backups_start");
                }
                else {
                    dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, text: res.data.errorDescription, title: "Unable to trigger" }));
                }
            }).catch(e => {
                processAxiosError(e);
                dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, text: "Something went wrong", title: "Unable to trigger" }));
            })
            .finally(() => {
                setTriggerBusy(false);
            });
    }
    return (
        <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="flex items-center flex-1 overflow-hidden">
                <div className="flex-shrink-0">
                    <div className="relative inline-block pr-2 align-middle">
                        <img
                            className="h-10 w-10 rounded-md"
                            src={getGitHubAvatarUrl(card.repository.owner, 40)}
                            alt=""
                        />
                        <span>
                            {repo.isPrivate ?
                                <LockIcon className="absolute right-0 bottom-0 block h-3 w-3 rounded-sm bg-white -translate-x-2/4" />
                                :
                                <RepoIcon className="absolute right-0 bottom-0 block h-3 w-3 rounded-sm bg-white -translate-x-2/4" />
                            }
                        </span>
                    </div>
                </div>
                <div className="ml-1 flex-1 overflow-hidden">
                    <LongTextLink href={"/repository/" + repoId} className="align-middle font-semibold leading-6 text-gray-900 hover:underline"
                    >{repo.name}</LongTextLink>

                    <LongTextTag className="text-sm text-gray-500" value={`by ${repo.owner}`} />
                </div>
            </div>
            <div className="flex flex-shrink-0 ml-2">
                {cardCanBeSelectedOrBackedUp(card) &&
                    <>
                        <button
                            type="button"
                            disabled={triggerBusy}
                            onClick={triggerSelected}
                            data-tooltip-id="backup-tooltip" data-tooltip-content="Trigger backup now!"
                            className="relative inline-flex items-center rounded-md bg-gray-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500"
                        >
                            {triggerBusy ? <CircularSpinnerSecondary light={true} /> :
                                <RocketLaunchIcon className="h-5 w-5 text-white" aria-hidden="true" />}
                        </button>
                    </>
                }
                <Link to={Paths.dashboard.repositoryDetails.linkPath(repoId)}>
                    <button
                        data-tooltip-id="details-tooltip" data-tooltip-content="Details"
                        type="button"
                        className="relative ml-1 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                        <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </button>
                </Link>
            </div>
        </div>
    )
}

const RepoFooter = ({ repoId }) => {

    const card = useSelector((state) => selectCard(state, repoId));
    const meta = useMemo(() => card === null || card === undefined ? { last: "-", next: "-", live: "-" } :
        {
            last: card.timeSinceLastBackupString,
            next: card.nextBackupString,
            live: card.backupsCount
        }, [card])

    return (
        <div className="flex flex-row bg-gray-900/5 w-full rounded-b-lg">
            <div key="storage" className="px-4 bg-white py-2 border-r flex flex-col justify-center items-center rounded-bl-lg">
                <p className="text-sm font-medium leading-6 text-gray-500">Storage</p>
                <div className="mt-2 flex flex-col gap-x-2 text-sm font-normal flex-1 justify-center"
                    data-tooltip-id="storage-tooltip" data-tooltip-content={card.storage.storageName}
                    data-tooltip-class-name="ellipsis-tooltip">
                    <StorageImg className="w-8 h-8 min-h-8 min-w-8 mb-2" alt="storage-img" isCustomStorage={card.storage.isCustomStorage} storageName={card.storage.storageName} provider={card.storage.provider} />
                </div>
            </div>
            <div key="count" className="flex-1 px-2 bg-white py-2 flex flex-col justify-center items-center border-r">
                <p className="text-sm font-medium leading-6 text-gray-500">Backups</p>
                <div className="flex flex-col gap-x-2 text-sm font-normal flex-1 justify-center">
                    <p className="font-medium text-gray-600 text-center">{meta.live}</p>
                </div>
            </div>
            <div key="last" className="flex-1 px-2 bg-white py-2 flex flex-col justify-center items-center border-r">
                <p className="text-sm font-medium leading-6 text-gray-500">Last</p>
                <div className="flex flex-col gap-x-2 text-sm font-normal flex-1 justify-center">
                    <p className="font-medium text-gray-600 text-center">{meta.last}</p>
                </div>
            </div>
            <div key="next" className="flex-1 px-2 bg-white py-2 flex flex-col justify-center items-center rounded-br-lg">
                <p className="text-sm font-medium leading-6 text-gray-500">Next</p>
                <div className="flex flex-col gap-x-2 text-sm font-normal flex-1 justify-center">
                    <p className="font-medium text-gray-600 text-center">{meta.next}</p>
                </div>
            </div>
        </div>
    )
}

export const backupIsRunning = (status) => {

    var running = status === null || status === undefined ? false :
        (status !== BackupStatus.failed && status !== BackupStatus.succeeded && status !== BackupStatus.undefined && status !== BackupStatus.deleted);
    return running;
}

export const restoreIsRunning = (status) => {

    var running = status === null || status === undefined ? false :
        (status !== RestoreStatus.failed && status !== RestoreStatus.succeeded && status !== RestoreStatus.undefined);
    return running;
}

export const selectScheduleStatusSecondary = createSelector(
    [state => state.data.dashboard.cards, (state, repoId) => repoId],
    (cards, repoId) => getScheduleStatusSecondary(getCard(cards, repoId)));

const selectLatest = createSelector(
    [state => state.data.dashboard.loadedBackups,
    (state => state.data.dashboard.loadedRestores),
    (state, defId) => defId],
    (backups, restores, defId) => [
        ...backups[defId].map(x => ({ type: "backup", data: x })),
        ...restores[defId].map(x => ({ type: "restore", data: x }))
    ].sort((b, a) => new Date(a.data.startTimeUtc) - new Date(b.data.startTimeUtc)).slice(0, 3)
)

const RepoCardRestoreTableRow = ({ restore }) => {
    const restoreStatus = getRestoreStatus(restore);
    const dispatch = useDispatch();
    return (
        <tr className="border-t border-gray-200 w-full  h-4">
            <TableCell className="font-normal py-1 pl-4 !border-none ">
                <div className="flex align-middle items-center">
                    <div className="flex align-middle items-center justify-center">
                        <restoreStatus.IconSrc className="mr-2 h-4 w-4"
                        />
                    </div>
                    <div className={`inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-normal ring-1 ring-inset ${restoreStatus.Style}`}>
                        <p className="font-medium">{restoreStatus.Text === "Succeeded" ?
                            "Restored" :
                            restoreStatus.Text === "Failed" ?
                                "Restore Failed" :
                                restoreStatus.Text}</p>
                    </div>
                </div>
            </TableCell>
            <TableCell className="font-normal px-1 py-1 !border-none ">
                <p className="font-medium text-gray-600">{restore.startTimeText}</p>
            </TableCell>
            <TableCell className="font-normal px-1 py-1 !border-none ">
                <div className="flex justify-end mr-1">
                    <div className="flex align-middle items-center text-center mr-2">
                        <button className="bg-white text-gray-900 hover:text-gray-500"
                            data-tooltip-id="info-tooltip" data-tooltip-content="Information"
                            onClick={() => dispatch(setModal({ id: Modals.RestoreInfoModal, restore }))}
                        >
                            <InfoIcon className="h-4 w-4 !block text-gray-600" verticalAlign="middle" size="small" alt="download-icon" />
                        </button>
                    </div>
                    {restore.status === RestoreStatus.succeeded ? <div className="flex align-middle items-center text-center mr-2">
                        <Link className="bg-white text-gray-900 hover:text-gray-500"
                            data-tooltip-id="go-tooltip" data-tooltip-content="Open restored repository"
                            to={restore.remoteRepositoryUrl}>
                            <LinkExternalIcon className="h-4 w-4 !block text-gray-600" verticalAlign="middle" size="small" alt="download-icon" />
                        </Link>
                    </div> : <div className="w-4  mr-2"></div>
                    }
                    <div className="w-4"></div>
                </div>
            </TableCell>
        </tr>
    )
}

const RepoCardBackupTableRow = ({ backup, owner, repo, repoId }) => {
    const backupStatus = getBackupStatus(backup, false);
    const dispatch = useDispatch();
    return (
        <tr className="w-full h-4">
            <TableCell className="font-normal py-1 pl-4 !pr-0 !border-none">
                <div className="flex align-middle items-center">
                    <div className="flex align-middle items-center justify-center" data-tooltip-id="backup-type-tooltip"
                        data-tooltip-content={backup.triggerType === "manual" ? "Triggered Manually" : "Triggered Automatically"} >
                        <backupStatus.IconSrc className="mr-2 h-4 w-4 text-gray-600"
                        />
                    </div>
                    <div className={`inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-normal ring-1 ring-inset ${backupStatus.Style}`}>
                        <p className="font-medium">{backupStatus.Text}</p>
                    </div>
                </div>
            </TableCell>
            <TableCell className="font-normal text-sm px-1 py-1 !border-none">
                <p className="font-medium text-gray-600">{backup.startTimeText}</p>
            </TableCell>
            <TableCell className="font-normal px-1 py-1 !pl-0 !border-none">
                <div className="flex justify-end mr-1">
                    <div className="flex align-middle items-center text-center mr-2">
                        <button className="bg-white text-gray-900 hover:text-gray-500"
                            data-tooltip-id="info-tooltip" data-tooltip-content="Information"
                            onClick={() => dispatch(setModal({ id: Modals.BackupInfoModal, owner, repo, repoId, backup }))}>
                            <InfoIcon className="h-4 w-4 !block text-gray-600" verticalAlign="middle" size="small" alt="download-icon" />
                        </button>
                    </div>
                    {backup.status === BackupStatus.succeeded ?
                        <>
                            <div className="flex align-middle items-center text-center mr-2">
                                <button className="bg-white text-gray-900 hover:text-gray-500"
                                    data-tooltip-id="download-tooltip" data-tooltip-content="Download this backup"
                                    onClick={() => {
                                      dispatch(setModal({
                                        id: Modals.DownloadBackupModal,
                                        backupId: backup.backupId,
                                        storageProviderName: StorageProviderName(backup.provider)
                                      }));
                                    }}>
                                    <DesktopDownloadIcon className="h-4 w-4 !block text-gray-600" verticalAlign="middle" size="small" alt="download-icon" />
                                </button>
                            </div>
                            <div className="flex align-middle items-center text-center">
                                <Link className="bg-white text-gray-900 hover:text-gray-500"
                                    to={`/restore/${owner}/${repoId}/${backup.backupId}`}
                                    data-tooltip-id="restore-tooltip" data-tooltip-content="Restore this backup">
                                    <RepoPushIcon className="h-4 w-4 !block text-gray-600" verticalAlign="middle" size="small" alt="restore-icon" />
                                </Link>
                            </div>
                        </> :
                        <>
                            <div className="w-4 mr-2"></div>
                            <div className="w-4"></div>
                        </>
                    }
                </div>
            </TableCell>
        </tr>
    )
}

const RepoCardContent = ({ repoId }) => {
    //TODO: get latest backups/restores, arrange and show
    const scheduleStatusData = useSelector(s => selectScheduleStatusSecondary(s, repoId));
    const card = useSelector((state) => selectCard(state, repoId));
    const latestActions = useSelector(state => selectLatest(state, card.definitionId));
    const tooltipHtml = card.scheduleStatus === ScheduleStatus.restoreOnly    
      ? ReactDOMServer.renderToStaticMarkup(
        <div className="max-w-80">
          <h3 className="text-base pb-3 ">This repository is in <b className="text-red-500">Restore only</b> state</h3>
          <p className="text-sm text-gray-200">This means that the repository has been removed from GitHub, or the Cloudback application no longer has access to it.</p>
        </div>) 
      : "";
    return (
        <div className="mx-auto max-w-7xl flex-1 h-full">
            <div className="flex flex-col h-full">
                <div className={classNames("py-1 pl-2 border-b border-gray-200", scheduleStatusData.Color)}>
                    <div className={`inline-flex align-middle items-center font-normal rounded-md !bg-transparent px-2 py-1 text-xs ${scheduleStatusData.Color}`}
                      data-tooltip-id="repo-status-tooltip" data-tooltip-html={tooltipHtml}>
                        {scheduleStatusData.Text === "Scheduled" ?
                            <CheckCircleFillIcon className="mr-2 h-4 w-4" style={scheduleStatusData.ImgStyle} alt="circle" /> :
                              scheduleStatusData.Text === "Not scheduled" ?
                                <SkipFillIcon className="mr-2 h-4 w-4" style={scheduleStatusData.ImgStyle} /> :
                                <ExclamationTriangleIcon className="mr-2 h-4 w-4" style={scheduleStatusData.ImgStyle} alt="exclamation-triangle" />
                        }
                        <p className="font-semibold text-md">{scheduleStatusData.Text}</p>
                    </div>
                </div>
                {latestActions.length === 0 ?
                    <div className="w-full flex flex-1 justify-center items-center border-t border-gray-200">
                        <div className="flex flex-col gap-2">
                            <div className="flex justify-center"><NoSymbolIcon className="h-6 w-6 text-gray-500" /></div>
                            <p className="text-sm text-gray-500">No backups or restores yet</p>
                        </div>
                    </div>
                    :
                    <table className="flex-1 border-none w-full divide-y divide-gray-300">
                        <tbody className="bg-white">
                            {latestActions.length > 0 &&
                                latestActions.map((x, i) =>
                                    x.type === "backup" ?
                                        <RepoCardBackupTableRow key={i} backup={x.data} repo={card.repository.name} owner={card.repository.owner} repoId={repoId} /> :
                                        <RepoCardRestoreTableRow key={i} restore={x.data} />
                                )
                            }
                            {latestActions.length > 0 && latestActions.length < 3 &&
                                Array(3 - latestActions.length).fill().map((x, i) =>
                                    <tr key={`extra-${i}`} className="w-full">
                                        <td className="h-4"></td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                }
            </div>
        </div >
    )
}

export const RepositoryCard = ({ repoId }) => {

    return (
        <BaseTransition>
            <div className={classNames("w-full h-72 divide-y divide-gray-200 rounded-lg bg-white shadow shadow-gray-400 flex flex-col")}>
                <div className="px-1 py-2 sm:px-2">
                    <RepoCardHeader repoId={repoId} />
                </div>
                <div className="flex-1">
                    <RepoCardContent repoId={repoId} />
                </div>
                <div className="">
                    <RepoFooter repoId={repoId} />
                </div>
            </div>
        </BaseTransition>
    )
}