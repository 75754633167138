import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNotifier } from "./notifications/notificationActions";
import axios from "axios";
import { settings } from "../settings";
import { loadUser } from "../slices/dataSlice";
import { rejectUser, setUser } from "../slices/userSlice";



export const useUser = ()=>{
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const userError = useSelector(state => state.user.error);
    const setupRequired = useSelector(state => state.user.setupRequired);
    const [notifySuccess, notifyInfo, notifyError] = useNotifier();
    const updateUserInfo = () => {
        axios.get(settings.backendUrl + "/me", { withCredentials: true, })
          .then(res => {
            if (res.status === 200) {
              dispatch(setUser(res.data));
            }
            else {
              dispatch(rejectUser());              
            }
          })
          .catch(e => {
            dispatch(rejectUser());            
          })
          .finally(() => {
            setIsLoading(false);
          })
      }
    
      useEffect(() => {
        updateUserInfo();
      }, []);

      return [isLoading, userError, notifyError,setupRequired];
}