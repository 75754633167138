
import { classNames } from './ApplicationHeader'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    BookOpenIcon,
    CalendarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    QuestionMarkCircleIcon,
    UsersIcon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { Link, useLocation } from 'react-router-dom'
import { ExtraPaths, Paths } from '../../helpers/navigationHelper'
import CloudbackLogo from "./../../assets/logo.svg"
import { LinkExternalIcon } from '@primer/octicons-react'


export const LeftNavBar = ({ sidebarOpen, setSidebarOpen }) => {
    const location = useLocation();
    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                                    <div className="flex h-16 shrink-0 items-center">
                                        <img
                                            className="h-8 w-auto"
                                            src={CloudbackLogo}
                                            alt="Cloudback"
                                        />
                                        <p className='text-white ml-2 font-bold text-lg'>Cloudback</p>
                                    </div>
                                    <nav className="flex flex-1 flex-col">
                                        <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                            <li key={1}>
                                                <ul role="list" className="-mx-2 space-y-1">
                                                    {Object.values(Paths).map(x =>
                                                        x.name === "divider" ?
                                                            <li key={"divider"}>
                                                                <div className='mt-3 mb-3 border-b border-gray-100/10'></div>
                                                            </li> :
                                                            <li key={x.name}>
                                                                <Link to={x.path} 
                                                                onClick={()=>setSidebarOpen(false)}
                                                                className={classNames(
                                                                    location.pathname === x.path ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')
                                                                }>{x.name}</Link>
                                                            </li>
                                                    )}
                                                </ul>
                                            </li>
                                            <li key={2} className="mt-auto">
                                                <ul role='list' className='-mx-2 space-y-1'>
                                                    {Object.values(ExtraPaths).filter(x => x.name !== "Restore").map(x =>
                                                        <li key={`extra-${x.path}`}>
                                                            <Link to={x.path} 
                                                            onClick={()=>setSidebarOpen(false)}
                                                            target={x.isExternal ? "_blank" : ""} className={classNames(
                                                                location.pathname === x.path ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold items-center')
                                                            }>
                                                                <span className='flex-1'>{x.name}</span>
                                                                {x.isExternal && <LinkExternalIcon className='h-4 w-4 ml-1' verticalAlign='middle'/>}
                                                            </Link>
                                                        </li>
                                                    )}
                                                </ul>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                        <img
                            className="h-8 w-auto"
                            src={CloudbackLogo}
                            alt="Cloudback"
                        />
                        <p className='text-white ml-2 font-bold text-xl'>Cloudback</p>
                    </div>
                    <nav className="flex flex-1 flex-col">
                        <ul role="list" className="flex flex-1 flex-col gap-y-7">
                            <li key={1}>
                                <ul role="list" className="-mx-2 space-y-1">
                                    {Object.values(Paths).map(x =>
                                        x.name === "divider" ?
                                            <li key={"divider"}>
                                                <div className='mt-3 mb-3 border-b border-gray-100/10'></div>
                                            </li> :
                                            <li key={x.name}>
                                                <Link to={x.path} className={classNames(
                                                    location.pathname === x.path ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')
                                                }>
                                                    <x.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                                    {x.name}
                                                </Link>
                                            </li>
                                    )}
                                </ul>
                            </li>
                            <li key={2} className="mt-auto">
                                <ul role='list' className='-mx-2 space-y-1'>
                                    {Object.values(ExtraPaths).filter(x => x.name !== "Restore").map(x =>
                                         <li key={`extra-${x.path}`}>
                                            <Link to={x.path} target={x.isExternal ? "_blank" : ""} className={classNames(
                                                location.pathname === x.path ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold items-center')
                                            }>
                                                <x.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                                <span className='flex-1'>{x.name}</span>
                                                {x.isExternal && <LinkExternalIcon className='h-4 w-4 ml-1' verticalAlign='middle'/>}
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            </li>

                        </ul>
                    </nav>
                </div>
            </div>
        </>
    )
}