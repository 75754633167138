import { InTextLink } from "../../../kit/Badges"
import { useState } from "react"
import { ProviderSettingsWrapper, ProviderStepWithTextArea, ProviderStepWithTextInput, ProviderTestAndSave, StorageWrapperComponent, useStorageSection } from "./ProvidersCommon"


export const AmazonS3GlacierStorageInfo = ({ onComplete, onCancel, onTest, nameProvided, connectionString, editMode, setPageError }) => {

    const [vaultArn, setVaultArn] = useState(connectionString || '');

    const buildConnectionString = () => vaultArn;
    const storageEditor = useStorageSection(onCancel, onTest, onComplete, buildConnectionString, editMode, setPageError);
    const vaultPolicy = () => {
        if (vaultArn === '' || storageEditor.providers.isLoading) return '';
        var policyTemplate = storageEditor.providers.list["AmazonS3Glacier"].userInterfaceHelper.parameters.PolicyTemplate;
        var principal = storageEditor.providers.list["AmazonS3Glacier"].userInterfaceHelper.parameters.Principal;
        policyTemplate = policyTemplate.replace(new RegExp("{{CLOUDBACK USER ARN}}", "g"), principal);
        policyTemplate = policyTemplate.replace(new RegExp("{{CLIENT VAULT ARN}}", "g"), vaultArn);
        return policyTemplate;
    }

    const canContinue = vaultArn !== '';


    return (
        <>
            <StorageWrapperComponent className="bg-red-50/20" providers={storageEditor.providers}>
                <ProviderStepWithTextInput stepTitle="Step 1" placeholder="arn:aws:s3:::"
                    value={vaultArn} setValue={setVaultArn}
                >
                    <p>
                        Create <InTextLink url="https://docs.aws.amazon.com/amazonglacier/latest/dev/getting-started-create-vault.html" title="Amazon S3 Glacier Vault" />
                        {' '}and type a <b>Vault ARN</b> below to generate access policy document:
                    </p>
                </ProviderStepWithTextInput>
                <ProviderStepWithTextArea
                    stepTitle="Step 2"
                    copy={true}
                    readonly={true}
                    value={vaultPolicy()}
                >
                    <p>
                        Copy to clipboard and apply generated <InTextLink url="https://docs.aws.amazon.com/amazonglacier/latest/dev/vault-access-policy.html" title="vault access policy" />  document:
                    </p>
                </ProviderStepWithTextArea>
            </StorageWrapperComponent>
            <ProviderTestAndSave storageEditor={storageEditor} nameIncluded={nameProvided} canContinue={canContinue} />
        </>
    )
}