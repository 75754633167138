import { ArrowPathIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline"

export const ErrorPage = ({canTryAgain, tryAgainAction}) => {
    return(
        <div className="flex flex-col items-center justify-center h-full">
            <ExclamationTriangleIcon className="h-10 w-10 text-red-600" />
            <h1 className="text-3xl font-semibold text-gray-900">Error occurred</h1>
            <p className="mt-4 text-gray-500">We are sorry, but something went wrong. Please try again later or <a className="underline" href="mailto:support@cloudback.it">contact support</a></p>
            {canTryAgain && (
                <button className="inline-flex items-center mt-4 px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-700" onClick={tryAgainAction}><ArrowPathIcon className="h-5 w-5 mr-1"/>Try Again</button>
            )}
        </div>
    )
}