import { useDispatch, useSelector } from "react-redux";
import { updateBackup, updateBackupsForCards, updateCard, updateRestore } from "../../slices/dataSlice";
import { useNotifier } from "../notifications/notificationActions";
import { setModal, triggerMultiple } from "../../slices/userSlice";
import { Modals } from "../../components/application/modals/ModalsCommon";
import { useMemo } from "react";

export const useConnectionHooks = (connection) =>{

  const dispatch = useDispatch();
  const [notifySuccess, notifyInfo] = useNotifier();
  const currentModal = useSelector(state => state.user.modal);

  const addConnectionHooks = () => {  
   
    if (connection){
      connection.on(`Backup`,
      message => {    
        dispatch(updateBackup(JSON.parse(message)));
        //notifyInfo("Backup status updated", "bckp_upd");     
      });

      connection.on(`Card`,
      message => {    
        dispatch(updateCard(JSON.parse(message)));
        //notifyInfo("Backup status updated", "bckp_upd");     
      });

      connection.on(`Restore`,
      message => {          
        dispatch(updateRestore(JSON.parse(message)));
        //notifyInfo("Backup status updated", "bckp_upd");     
      });    

      connection.on(`TriggerResult`,
        message => {  
          let result = JSON.parse(message);         
          dispatch(triggerMultiple(result));
          if (result.completed && currentModal?.id !== Modals.TriggerMultipleResultModal){
            dispatch(setModal({id:Modals.TriggerMultipleResultModal, ...result}));
          }        
        }); 
    }
   
  };
  
  const disposeConnectionHooks = () => {
    if(connection){
      connection.off(`Backup`, () => { });
      connection.off(`Restore`, () => { });
      connection.off(`Card`, () => { });
    }
  };

  return [addConnectionHooks, disposeConnectionHooks];

}

