import { LinkExternalIcon } from "@primer/octicons-react";
import { getRestoreStatus } from "../../helpers/domainHelpers";
import { EmptyState } from "../../kit/EmptyState";
import { TableCell, TableHeadCell } from "../../kit/Table";
import { RestoreStatus } from "../../model/domain";
import { useDispatch } from "react-redux";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { setModal } from "../../slices/userSlice";
import { Modals } from "../application/modals/ModalsCommon";

function RestoreRow(props) {
  const restore = props.restore;
  const restoreStatus = getRestoreStatus(restore);
  const id = props.id;
  const dispatch = useDispatch();
  return (
    <tr key={id}>
      <TableCell className="font-normal py-2 pl-4 pr-3">
        <div className="flex align-middle items-center">
          <restoreStatus.IconSrc className="mr-2" />
          <div className={`inline-flex items-center font-normal rounded-full bg-gray-50 px-2 py-1 text-xs font-medium ring-1 ring-inset ${restoreStatus.Style}`}>
            {restoreStatus.Text}
          </div>
        </div>
      </TableCell>
      <TableCell className="font-normal px-2 py-2">
        <div className="flex align-middle items-center font-normal">
          {restore.startTimeText}
        </div>
      </TableCell>
      <TableCell className="font-normal px-2 py-2">
        <div className="flex pl-0 pr-0">        
          <div className="text-center table-cell align-middle font-normal">
            {
              restore.status === RestoreStatus.succeeded
                ?
                <a href={restore.remoteRepositoryUrl} target="_blank" className="items-center flex">
                  <LinkExternalIcon className="mr-2 h-4 w-4" verticalAlign='middle'/>
                  {restore.remoteRepositoryUrl.split("github.com/")[1]}
                </a>
                :
                <span>-</span>
            }
          </div>
        </div>
      </TableCell>
      <TableCell className="font-normal px-2 py-2">
        <div className="flex align-middle items-center font-normal">
          {restore.backupStartTimeText}
        </div>
      </TableCell>
      <TableCell className="font-normal px-2 py-2">
        <div className="flex">
          <div className="flex align-middle items-center text-center mr-3">
            <button className="rounded-md bg-white p-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              data-tooltip-id="info-tooltip" data-tooltip-content="Information"
              onClick={() => dispatch(setModal({ id: Modals.RestoreInfoModal, restore }))}>
              <InformationCircleIcon className="h-5 w-5 !block" verticalAlign="middle" size="medium" alt="download-icon" />
            </button>
          </div>
        </div>
      </TableCell>
    </tr>
  );
}

function RestoreTable(props) {

  const tableRows = props.rows;

  return (
    <div className="w-full py-2">
      <table className="border-none min-w-full divide-y divide-gray-300 shadow ring-1 ring-black ring-opacity-5 rounded-t-lg">
        <thead className="bg-gray-50">
          <tr>
            <TableHeadCell stickyPage={true} className="!top-[113px] font-bold py-3 pl-4 pr-3 rounded-tl-lg">
              Status
            </TableHeadCell>
            <TableHeadCell stickyPage={true} className="!top-[113px] font-bold px-3 py-3.5">
              Start time
            </TableHeadCell>
            <TableHeadCell stickyPage={true} className="!top-[113px] font-bold px-3 py-3.5">
              Restored to
            </TableHeadCell>
            <TableHeadCell stickyPage={true} className="!top-[113px] font-bold px-3 py-3.5">
              Restored from backup
            </TableHeadCell>
            <TableHeadCell stickyPage={true} className="!top-[113px] font-bold px-3 py-3.5">
            </TableHeadCell>
          </tr>
        </thead>
        <tbody className="bg-white">
          {
            tableRows.map((row, id) => <RestoreRow key={id} restore={row} />)
          }
        </tbody>
      </table>
    </div>
  );
}

export default RestoreTable;