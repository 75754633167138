import { CircleStackIcon, BellIcon, BookOpenIcon, CalendarIcon, Cog6ToothIcon, CreditCardIcon, ExclamationCircleIcon, HomeIcon, QuestionMarkCircleIcon, Square3Stack3DIcon, UserCircleIcon, UserIcon, DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { ShieldCheckIcon } from "@primer/octicons-react";

export const ExtraPaths = {
    restore:{
        name:"Restore",
        path:"/restore",
        restoreAccount:{
            path:"/restore/:accountName",
            linkPath: (account)=>"/restore/"+account
        },
        restoreRepository:{
            path:"/restore/:accountName/:repoId",
            linkPath: (account,repoId)=>"/restore/"+repoId
        },
        restoreBackup:{
            path:"/restore/:accountName/:repoId/:backupId",
            linkPath: (account,repoId,backupId)=>"/restore/"+repoId+"/"+backupId
        }
    },
    docs:{
        name:"Documentation",
        path:"https://cloudback.it/docs",
        icon: BookOpenIcon,
        isExternal: true
    },
    reportIssue:{
        name:"Report an issue",
        path:"https://github.com/cloudback/issue-tracker/issues/new?template=bug_report.md",
        icon: ExclamationCircleIcon,
        isExternal: true
    },
    contactUs:{
        name:"Contact us",
        path:"/contactus",
        icon: QuestionMarkCircleIcon
    }
}

export const Paths = {
    dashboard: {
        name: "Dashboard",
        path: "/",
        icon: HomeIcon,
        repositoryDetails:{
            name: "Repository details",
            path: "/repository/:id",
            linkPath: (id)=>"/repository/"+id
        }
    },
    storages:{
        name: "Storages",
        path: "/storages",
        icon: CircleStackIcon,
        newStorage:{
            name: "New Storage",
            path: "/storages/new",
        },
        editStorage:{
            name: "Edit Storage",
            path: "/storages/edit/:id",
            linkPath: (id)=>"/storages/edit/"+id
        }
    },
    schedules: {
        name: "Schedules",
        path: "/schedules",
        icon: CalendarIcon,
        newSchedule:{
            name: "New Schedule",
            path: "/schedules/new",
        },
        editSchedule:{
            name: "Edit Schedule",
            path: "/schedules/edit/:id",
            linkPath: (id)=>"/schedules/edit/"+id
        }
    },
    bulk:{
        name: "Bulk operations",
        path: "/bulk",
        icon: Square3Stack3DIcon,
        bulkPreSelected:{
            name: "Selected",
            path: "/bulk/preselected",
        },
    },    
    accountDivider:{
        name:"divider"  
    },   
    billing:{
        name:"Subscriptions",
        path:"/subscriptions",
        icon: CreditCardIcon
    },    
    accountSettings:{
        name:"Account Settings",
        path: "/accountSettings",
        icon: UserCircleIcon
    },
    notifications:{
        name:"Notification Settings",
        path: "/notificationSettings",
        icon: BellIcon
    },
    audit:{
        name:"Audit log",
        path:"/auditLog",
        icon: DocumentMagnifyingGlassIcon
    },
};