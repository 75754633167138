import { useEffect, useMemo, useState } from "react"
import { CircularSpinnerSecondary, FullScreenLoader, NewLoader } from "../../kit/Spinners";
import axios from "axios";
import { getGitHubAvatarUrl, settings } from "../../settings";
import { LoadingAutocomplete, useAutocomplete } from "../../kit/Autocomplete";
import { preload } from "../../slices/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import { StorageImg } from "../../helpers/domainHelpers";
import { useNotifier } from "../../hooks/notifications/notificationActions";
import { FormButton } from "../../kit/Buttons";
import { processAxiosError } from "../util/ErrorBoundary";
import { ErrorPage } from "../../kit/ErrorPage";
import { setModal } from "../../slices/userSlice";
import { Modals } from "../application/modals/ModalsCommon";
import { EmptyRepoState } from "../repositories/EmptyRepoState";
import { EmptyInstallationPage } from "../application/EmptyInstallationPage";



export const UserSelector = ({ user, setUser, users, setUsers, isLoading, setIsLoading, setPageError }) => {

    
    const loadUsers = () => {
        setIsLoading(true);
        axios.get(settings.backendUrl + "/v2/accounts/get")
        .then(res => {
            if (res.data.isSuccess) {
                setUsers(res.data.result.reduce((acc, val) => ({ ...acc, [val.accountName]: val }), {}));
            }
            else {
                setPageError(res.data.errorDescription);
            }
        })
        .catch(e => {
            processAxiosError(e);
            setPageError(e);
        })
        .finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        loadUsers();
    }, [])

    const autocomplete = useAutocomplete(users, isLoading, undefined, user, setUser);

    return (<div className="border-b border-gray-900/10 pb-6">
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
            <div className="sm:col-span-4">
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                    Select GitHub Account
                </label>
                <div className="mt-2">
                    <LoadingAutocomplete isLoading={autocomplete.isLoading}
                        autocomplete={{
                            ...autocomplete,
                            labelSelector: (option) => option?.accountName,
                            keySelector: (option) => option?.accountName,
                            optionLeadingContentSelector: (option) => <img
                                className="h-8 w-8 rounded-md"
                                src={getGitHubAvatarUrl(option?.accountName)}
                                alt=""
                            />,
                            size: "lg"
                        }}
                    />
                </div>
            </div>
        </div>
    </div>
    )
}



const EditableAccountBlock = ({ isExternalLoading, accountSettings, setAccountSettings, setPageError }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [saveBusy, setSaveBusy] = useState(false);

    const loadSettings = () => {
        setIsLoading(true);
        axios.get(settings.backendUrl + "/v2/preload")
            .then((res) => {
                if (res.data.isSuccess) {
                    dispatch(preload(res.data.result));
                }
                else {
                    setPageError(res.data.errorDescription);
                }
            })
            .catch((e) => {
                processAxiosError(e);
                setPageError("The settings couldn't be loaded. Please try again later.");
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        loadSettings();
    }, [])

    const isCombinedLoading = isExternalLoading || isLoading;

    const timeZoneOptionsDict = useMemo(() => timeZoneMap.reduce((acc, val) => ({ ...acc, [val.id]: val }), {}), [timeZoneMap])

    const [schedule, setSchedule] = useState(null);
    const schedules = useSelector((state) => state.data.schedules);
    const scheduleAutocomplete = useAutocomplete(schedules, isCombinedLoading, accountSettings?.backupScheduleId, schedule, setSchedule);

    const [storage, setStorage] = useState(null);
    const storages = useSelector((state) => state.data.storages);
    const storageAutocomplete = useAutocomplete(storages, isCombinedLoading, accountSettings?.storageId, storage, setStorage);

    const [retention, setRetention] = useState(null);
    const retentions = useSelector((state) => state.data.retentions);
    const retentionAutocomplete = useAutocomplete(retentions, isCombinedLoading, accountSettings?.retentionPolicyId, retention, setRetention);

    const [timezone, setTimezone] = useState(null);
    const timezoneAutocomplete = useAutocomplete(timeZoneOptionsDict, isCombinedLoading, accountSettings?.timezone, timezone, setTimezone);

    const [archivedBehaviour, setArchivedBehaviour] = useState(null);
    const archivedBehaviours = useSelector((state) => state.data.archivedBehaviours);
    const archivedBehaviourAutocomplete = useAutocomplete(archivedBehaviours, isCombinedLoading, accountSettings?.archivedBehaviour, archivedBehaviour, setArchivedBehaviour);

    const [notifySuccess] = useNotifier();
    const onSave = () => {
        setSaveBusy(true);
        let newSettings =  {
            ...accountSettings,
            backupScheduleId: schedule.scheduleId,
            storageId: storage.storageId,
            retentionPolicyId: retention.retentionPolicyId,
            timezone: timezone.id,
            archivedBehaviour: archivedBehaviour.value
        };
        axios.post(settings.backendUrl + "/v2/account/set", newSettings)
            .then(res => {
                if (res.data.isSuccess) {
                    setAccountSettings(newSettings);
                    notifySuccess("Saved");
                }
                else {
                    dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to save", text: res.data.errorDescription }));
                }
            })
            .catch(e => {
                processAxiosError(e);
                dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to save", text: "The settings couldn't be saved. Please try again later." }));
            })
            .finally(() => {
                setSaveBusy(false);
            })
    }

    const formLoading = isCombinedLoading || timezoneAutocomplete.isLoading || scheduleAutocomplete.isLoading || storageAutocomplete.isLoading || retentionAutocomplete.isLoading ||
        archivedBehaviourAutocomplete.isLoading;

    return (
        <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Account's settings</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">The timezone is applied to all existing repositories and the new ones. The other settings will be applied to all new repositories of the account selected above</p>

            <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 rounded-lg bg-white shadow p-3">
                <div className="sm:col-span-full">
                    <LoadingAutocomplete isLoading={timezoneAutocomplete.isLoading}
                        autocomplete={{
                            ...timezoneAutocomplete,
                            labelSelector: (option) => option?.id,
                            keySelector: (option) => option?.id,
                            label: "Timezone"

                        }}
                    />
                </div>
                <div className="sm:col-span-full">
                    <LoadingAutocomplete isLoading={scheduleAutocomplete.isLoading}
                        autocomplete={{
                            ...scheduleAutocomplete,
                            labelSelector: (option) => option?.name,
                            keySelector: (option) => option?.scheduleId,
                            label: "Default schedule"

                        }}
                    />
                </div>
                <div className="sm:col-span-full">
                    <LoadingAutocomplete isLoading={storageAutocomplete.isLoading}
                        autocomplete={{
                            ...storageAutocomplete,
                            labelSelector: (option) => option?.storageName,
                            keySelector: (option) => option?.storageId,
                            optionLeadingContentSelector: (option) => <StorageImg className="w-5 h-5 min-h-5 min-w-5" alt="storage-img" isCustomStorage={option?.isCustomStorage} storageName={option?.storageName} provider={option?.provider} />,
                            label: "Default storage"

                        }}
                    />
                </div>
                <div className="sm:col-span-full">
                    <LoadingAutocomplete isLoading={retentionAutocomplete.isLoading}
                        autocomplete={{
                            ...retentionAutocomplete,
                            labelSelector: (option) => option?.name,
                            keySelector: (option) => option?.retentionPolicyId,
                            label: "Default retention"

                        }}
                    />
                </div>
                <div className="sm:col-span-full">
                    <LoadingAutocomplete isLoading={archivedBehaviourAutocomplete.isLoading}
                        autocomplete={{
                            ...archivedBehaviourAutocomplete,
                            labelSelector: (option) => option?.label,
                            keySelector: (option) => option?.value,
                            label: "What to do with archived repositories"

                        }}
                    />
                </div>
                <div className="sm:col-span-full mt-2">
                    <div className="flex items-center justify-end gap-x-6">
                        <FormButton onClick={onSave} busy={saveBusy} loading={formLoading}
                            btnText={saveBusy ? "Saving" : "Save"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const DangerZone = ({ accountSettings }) => {
    //TODO: Open dialog with confirmation
    const dispatch = useDispatch();
    const onDeleteAccount = () => {
        dispatch(setModal({ id: Modals.ConfirmDeleteAccountModal }));
    }
    return (
        <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Danger Zone</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">The settings in this area have drastic and permanent effects on your Cloudback account. Avoid this area unless you want to perform one of these actions - they cannot be undone once performed.</p>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                <div className="sm:col-span-full flex items-center justify-end ">
                    <button
                        onClick={onDeleteAccount}
                        type="button"
                        className="w-full sm:w-max justify-center sm:justify-start rounded-md bg-white px-3 py-2 text-sm font-semibold text-red-700 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50"
                    >
                        Delete Cloudback Account
                    </button>
                </div>
            </div>
        </div>
    )
}

export const AccountPage = () => {

    const [user, setUser] = useState(null);
    const [users, setUsers] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [pageError, setPageError] = useState(null);

    const onAccountSet = (account)=>{        
        setUsers({...users, [account.accountName]: account});
        setUser(account);
    }
    return pageError !== null ? <ErrorPage /> : (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-5 bg-gray-100">
            <div className="mx-auto max-w-3xl">
                <div className="space-y-6">
                    <UserSelector user={user} setPageError={setPageError} setUser={setUser} users={users} setUsers={setUsers} isLoading={isLoading} setIsLoading={setIsLoading} />
                    {!isLoading && user === null ?
                        <div className="border-b border-gray-900/10 pb-12">
                            <EmptyInstallationPage />
                        </div>
                        :
                        <EditableAccountBlock isExternalLoading={isLoading} accountSettings={user} setAccountSettings={onAccountSet} setPageError={setPageError} />
                    }
                    <DangerZone />
                </div>
            </div>
        </div>
    )
}


export const timeZoneMap = [
    { jsName: "Africa/Abidjan", id: "(UTC+01:00) West Central Africa" },
    { jsName: "Africa/Accra", id: "(UTC+01:00) West Central Africa" },
    { jsName: "Africa/Algiers", id: "(UTC+01:00) West Central Africa" },
    { jsName: "Africa/Bissau", id: "(UTC+01:00) West Central Africa" },
    { jsName: "Africa/Cairo", id: "(UTC+02:00) Cairo" },
    { jsName: "Africa/Casablanca", id: "(UTC+01:00) Casablanca" },
    { jsName: "Africa/Ceuta", id: "(UTC+01:00) West Central Africa" },
    { jsName: "Africa/El_Aaiun", id: "(UTC+01:00) West Central Africa" },
    { jsName: "Africa/Johannesburg", id: "(UTC+01:00) West Central Africa" },
    { jsName: "Africa/Juba", id: "(UTC+01:00) West Central Africa" },
    { jsName: "Africa/Khartoum", id: "(UTC+02:00) Khartoum" },
    { jsName: "Africa/Lagos", id: "(UTC+01:00) West Central Africa" },
    { jsName: "Africa/Maputo", id: "(UTC+01:00) West Central Africa" },
    { jsName: "Africa/Monrovia", id: "(UTC+00:00) Monrovia, Reykjavik" },
    { jsName: "Africa/Nairobi", id: "(UTC+03:00) Nairobi" },
    { jsName: "Africa/Ndjamena", id: "(UTC+01:00) West Central Africa" },
    { jsName: "Africa/Sao_Tome", id: "(UTC+01:00) West Central Africa" },
    { jsName: "Africa/Tripoli", id: "(UTC+02:00) Tripoli" },
    { jsName: "Africa/Tunis", id: "(UTC+01:00) West Central Africa" },
    { jsName: "Africa/Windhoek", id: "(UTC+02:00) Windhoek" },
    { jsName: "America/Adak", id: "(UTC-06:00) Central America" },
    { jsName: "America/Anchorage", id: "(UTC-06:00) Central America" },
    { jsName: "America/Araguaina", id: "(UTC-03:00) Araguaina" },
    { jsName: "America/Argentina/Buenos_Aires", id: "(UTC-06:00) Central America" },
    { jsName: "America/Argentina/Catamarca", id: "(UTC-06:00) Central America" },
    { jsName: "America/Argentina/Cordoba", id: "(UTC-06:00) Central America" },
    { jsName: "America/Argentina/Jujuy", id: "(UTC-06:00) Central America" },
    { jsName: "America/Argentina/La_Rioja", id: "(UTC-06:00) Central America" },
    { jsName: "America/Argentina/Mendoza", id: "(UTC-06:00) Central America" },
    { jsName: "America/Argentina/Rio_Gallegos", id: "(UTC-06:00) Central America" },
    { jsName: "America/Argentina/Salta", id: "(UTC-06:00) Central America" },
    { jsName: "America/Argentina/San_Juan", id: "(UTC-06:00) Central America" },
    { jsName: "America/Argentina/San_Luis", id: "(UTC-06:00) Central America" },
    { jsName: "America/Argentina/Tucuman", id: "(UTC-06:00) Central America" },
    { jsName: "America/Argentina/Ushuaia", id: "(UTC-06:00) Central America" },
    { jsName: "America/Asuncion", id: "(UTC-04:00) Asuncion" },
    { jsName: "America/Atikokan", id: "(UTC-06:00) Central America" },
    { jsName: "America/Bahia", id: "(UTC-06:00) Central America" },
    { jsName: "America/Bahia_Banderas", id: "(UTC-06:00) Central America" },
    { jsName: "America/Barbados", id: "(UTC-06:00) Central America" },
    { jsName: "America/Belem", id: "(UTC-06:00) Central America" },
    { jsName: "America/Belize", id: "(UTC-06:00) Central America" },
    { jsName: "America/Blanc-Sablon", id: "(UTC-06:00) Central America" },
    { jsName: "America/Boa_Vista", id: "(UTC-06:00) Central America" },
    { jsName: "America/Bogota", id: "(UTC-05:00) Bogota, Lima, Quito, Rio Branco" },
    { jsName: "America/Boise", id: "(UTC-06:00) Central America" },
    { jsName: "America/Cambridge_Bay", id: "(UTC-06:00) Central America" },
    { jsName: "America/Campo_Grande", id: "(UTC-06:00) Central America" },
    { jsName: "America/Cancun", id: "(UTC-06:00) Central America" },
    { jsName: "America/Caracas", id: "(UTC-04:00) Caracas" },
    { jsName: "America/Cayenne", id: "(UTC-03:00) Cayenne, Fortaleza" },
    { jsName: "America/Chicago", id: "(UTC-06:00) Central America" },
    { jsName: "America/Chihuahua", id: "(UTC-07:00) Chihuahua, La Paz, Mazatlan" },
    { jsName: "America/Costa_Rica", id: "(UTC-06:00) Central America" },
    { jsName: "America/Creston", id: "(UTC-06:00) Central America" },
    { jsName: "America/Cuiaba", id: "(UTC-04:00) Cuiaba" },
    { jsName: "America/Curacao", id: "(UTC-06:00) Central America" },
    { jsName: "America/Danmarkshavn", id: "(UTC-06:00) Central America" },
    { jsName: "America/Dawson", id: "(UTC-06:00) Central America" },
    { jsName: "America/Dawson_Creek", id: "(UTC-06:00) Central America" },
    { jsName: "America/Denver", id: "(UTC-06:00) Central America" },
    { jsName: "America/Detroit", id: "(UTC-06:00) Central America" },
    { jsName: "America/Edmonton", id: "(UTC-06:00) Central America" },
    { jsName: "America/Eirunepe", id: "(UTC-06:00) Central America" },
    { jsName: "America/El_Salvador", id: "(UTC-06:00) Central America" },
    { jsName: "America/Fort_Nelson", id: "(UTC-06:00) Central America" },
    { jsName: "America/Fortaleza", id: "(UTC-03:00) Cayenne, Fortaleza" },
    { jsName: "America/Glace_Bay", id: "(UTC-06:00) Central America" },
    { jsName: "America/Godthab", id: "(UTC-06:00) Central America" },
    { jsName: "America/Goose_Bay", id: "(UTC-06:00) Central America" },
    { jsName: "America/Grand_Turk", id: "(UTC-06:00) Central America" },
    { jsName: "America/Guatemala", id: "(UTC-06:00) Central America" },
    { jsName: "America/Guayaquil", id: "(UTC-06:00) Central America" },
    { jsName: "America/Guyana", id: "(UTC-06:00) Central America" },
    { jsName: "America/Halifax", id: "(UTC-06:00) Central America" },
    { jsName: "America/Havana", id: "(UTC-05:00) Havana" },
    { jsName: "America/Hermosillo", id: "(UTC-06:00) Central America" },
    { jsName: "America/Indiana/Indianapolis", id: "(UTC-05:00) Indiana (East)" },
    { jsName: "America/Indiana/Knox", id: "(UTC-05:00) Indiana (East)" },
    { jsName: "America/Indiana/Marengo", id: "(UTC-05:00) Indiana (East)" },
    { jsName: "America/Indiana/Petersburg", id: "(UTC-05:00) Indiana (East)" },
    { jsName: "America/Indiana/Tell_City", id: "(UTC-05:00) Indiana (East)" },
    { jsName: "America/Indiana/Vevay", id: "(UTC-05:00) Indiana (East)" },
    { jsName: "America/Indiana/Vincennes", id: "(UTC-05:00) Indiana (East)" },
    { jsName: "America/Indiana/Winamac", id: "(UTC-05:00) Indiana (East)" },
    { jsName: "America/Inuvik", id: "(UTC-06:00) Central America" },
    { jsName: "America/Iqaluit", id: "(UTC-06:00) Central America" },
    { jsName: "America/Jamaica", id: "(UTC-06:00) Central America" },
    { jsName: "America/Juneau", id: "(UTC-06:00) Central America" },
    { jsName: "America/Kentucky/Louisville", id: "(UTC-06:00) Central America" },
    { jsName: "America/Kentucky/Monticello", id: "(UTC-06:00) Central America" },
    { jsName: "America/La_Paz", id: "(UTC-06:00) Central America" },
    { jsName: "America/Lima", id: "(UTC-05:00) Bogota, Lima, Quito, Rio Branco" },
    { jsName: "America/Los_Angeles", id: "(UTC-06:00) Central America" },
    { jsName: "America/Maceio", id: "(UTC-06:00) Central America" },
    { jsName: "America/Managua", id: "(UTC-06:00) Central America" },
    { jsName: "America/Manaus", id: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan" },
    { jsName: "America/Martinique", id: "(UTC-06:00) Central America" },
    { jsName: "America/Matamoros", id: "(UTC-06:00) Central America" },
    { jsName: "America/Mazatlan", id: "(UTC-07:00) Chihuahua, La Paz, Mazatlan" },
    { jsName: "America/Menominee", id: "(UTC-06:00) Central America" },
    { jsName: "America/Merida", id: "(UTC-06:00) Central America" },
    { jsName: "America/Metlakatla", id: "(UTC-06:00) Central America" },
    { jsName: "America/Mexico_City", id: "(UTC-06:00) Central America" },
    { jsName: "America/Miquelon", id: "(UTC-03:00) Saint Pierre and Miquelon" },
    { jsName: "America/Moncton", id: "(UTC-06:00) Central America" },
    { jsName: "America/Monterrey", id: "(UTC-06:00) Guadalajara, Mexico City, Monterrey" },
    { jsName: "America/Montevideo", id: "(UTC-03:00) Montevideo" },
    { jsName: "America/Nassau", id: "(UTC-06:00) Central America" },
    { jsName: "America/New_York", id: "(UTC-06:00) Central America" },
    { jsName: "America/Nipigon", id: "(UTC-06:00) Central America" },
    { jsName: "America/Nome", id: "(UTC-06:00) Central America" },
    { jsName: "America/Noronha", id: "(UTC-06:00) Central America" },
    { jsName: "America/North_Dakota/Beulah", id: "(UTC-06:00) Central America" },
    { jsName: "America/North_Dakota/Center", id: "(UTC-06:00) Central America" },
    { jsName: "America/North_Dakota/New_Salem", id: "(UTC-06:00) Central America" },
    { jsName: "America/Ojinaga", id: "(UTC-06:00) Central America" },
    { jsName: "America/Panama", id: "(UTC-06:00) Central America" },
    { jsName: "America/Pangnirtung", id: "(UTC-06:00) Central America" },
    { jsName: "America/Paramaribo", id: "(UTC-06:00) Central America" },
    { jsName: "America/Phoenix", id: "(UTC-06:00) Central America" },
    { jsName: "America/Port_of_Spain", id: "(UTC-06:00) Central America" },
    { jsName: "America/Port-au-Prince", id: "(UTC-06:00) Central America" },
    { jsName: "America/Porto_Velho", id: "(UTC-06:00) Central America" },
    { jsName: "America/Puerto_Rico", id: "(UTC-06:00) Central America" },
    { jsName: "America/Punta_Arenas", id: "(UTC-06:00) Central America" },
    { jsName: "America/Rainy_River", id: "(UTC-06:00) Central America" },
    { jsName: "America/Rankin_Inlet", id: "(UTC-06:00) Central America" },
    { jsName: "America/Recife", id: "(UTC-06:00) Central America" },
    { jsName: "America/Regina", id: "(UTC-06:00) Central America" },
    { jsName: "America/Resolute", id: "(UTC-06:00) Central America" },
    { jsName: "America/Rio_Branco", id: "(UTC-06:00) Central America" },
    { jsName: "America/Santarem", id: "(UTC-06:00) Central America" },
    { jsName: "America/Santiago", id: "(UTC-04:00) Santiago" },
    { jsName: "America/Santo_Domingo", id: "(UTC-06:00) Central America" },
    { jsName: "America/Sao_Paulo", id: "(UTC-06:00) Central America" },
    { jsName: "America/Scoresbysund", id: "(UTC-06:00) Central America" },
    { jsName: "America/Sitka", id: "(UTC-06:00) Central America" },
    { jsName: "America/St_Johns", id: "(UTC-06:00) Central America" },
    { jsName: "America/Swift_Current", id: "(UTC-06:00) Central America" },
    { jsName: "America/Tegucigalpa", id: "(UTC-06:00) Central America" },
    { jsName: "America/Thule", id: "(UTC-06:00) Central America" },
    { jsName: "America/Thunder_Bay", id: "(UTC-06:00) Central America" },
    { jsName: "America/Tijuana", id: "(UTC-06:00) Central America" },
    { jsName: "America/Toronto", id: "(UTC-06:00) Central America" },
    { jsName: "America/Vancouver", id: "(UTC-06:00) Central America" },
    { jsName: "America/Whitehorse", id: "(UTC-06:00) Central America" },
    { jsName: "America/Winnipeg", id: "(UTC-06:00) Central America" },
    { jsName: "America/Yakutat", id: "(UTC-06:00) Central America" },
    { jsName: "America/Yellowknife", id: "(UTC-06:00) Central America" },
    { jsName: "Antarctica/Casey", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Antarctica/Davis", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Antarctica/DumontDUrville", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Antarctica/Macquarie", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Antarctica/Mawson", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Antarctica/Palmer", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Antarctica/Rothera", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Antarctica/Syowa", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Antarctica/Troll", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Antarctica/Vostok", id: "(UTC+10:00) Vladivostok" },
    { jsName: "Asia/Almaty", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Amman", id: "(UTC+02:00) Amman" },
    { jsName: "Asia/Anadyr", id: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky" },
    { jsName: "Asia/Aqtau", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Aqtobe", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Ashgabat", id: "(UTC+05:00) Ashgabat, Tashkent" },
    { jsName: "Asia/Atyrau", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Baghdad", id: "(UTC+03:00) Baghdad" },
    { jsName: "Asia/Baku", id: "(UTC+04:00) Baku" },
    { jsName: "Asia/Bangkok", id: "(UTC+07:00) Bangkok, Hanoi, Jakarta" },
    { jsName: "Asia/Barnaul", id: "(UTC+07:00) Barnaul, Gorno-Altaysk" },
    { jsName: "Asia/Beirut", id: "(UTC+02:00) Beirut" },
    { jsName: "Asia/Bishkek", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Brunei", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Chita", id: "(UTC+09:00) Chita" },
    { jsName: "Asia/Choibalsan", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Colombo", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Damascus", id: "(UTC+02:00) Damascus" },
    { jsName: "Asia/Dhaka", id: "(UTC+06:00) Dhaka" },
    { jsName: "Asia/Dili", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Dubai", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Dushanbe", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Famagusta", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Gaza", id: "(UTC+02:00) Gaza, Hebron" },
    { jsName: "Asia/Hebron", id: "(UTC+02:00) Gaza, Hebron" },
    { jsName: "Asia/Ho_Chi_Minh", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Hong_Kong", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Hovd", id: "(UTC+07:00) Hovd" },
    { jsName: "Asia/Irkutsk", id: "(UTC+08:00) Irkutsk" },
    { jsName: "Asia/Jakarta", id: "(UTC+07:00) Bangkok, Hanoi, Jakarta" },
    { jsName: "Asia/Jayapura", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Jerusalem", id: "(UTC+02:00) Jerusalem" },
    { jsName: "Asia/Kabul", id: "(UTC+04:30) Kabul" },
    { jsName: "Asia/Kamchatka", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Karachi", id: "(UTC+05:00) Islamabad, Karachi" },
    { jsName: "Asia/Kathmandu", id: "(UTC+05:45) Kathmandu" },
    { jsName: "Asia/Khandyga", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Kolkata", id: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi" },
    { jsName: "Asia/Krasnoyarsk", id: "(UTC+07:00) Krasnoyarsk" },
    { jsName: "Asia/Kuala_Lumpur", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Kuching", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Macau", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Magadan", id: "(UTC+11:00) Magadan" },
    { jsName: "Asia/Makassar", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Manila", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Nicosia", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Novokuznetsk", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Novosibirsk", id: "(UTC+07:00) Novosibirsk" },
    { jsName: "Asia/Omsk", id: "(UTC+06:00) Omsk" },
    { jsName: "Asia/Oral", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Pontianak", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Pyongyang", id: "(UTC+09:00) Pyongyang" },
    { jsName: "Asia/Qatar", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Qostanay", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Qyzylorda", id: "(UTC+05:00) Qyzylorda" },
    { jsName: "Asia/Riyadh", id: "(UTC+03:00) Kuwait, Riyadh" },
    { jsName: "Asia/Sakhalin", id: "(UTC+11:00) Sakhalin" },
    { jsName: "Asia/Samarkand", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Seoul", id: "(UTC+09:00) Seoul" },
    { jsName: "Asia/Shanghai", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Singapore", id: "(UTC+08:00) Kuala Lumpur, Singapore" },
    { jsName: "Asia/Srednekolymsk", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Taipei", id: "(UTC+08:00) Taipei" },
    { jsName: "Asia/Tashkent", id: "(UTC+05:00) Ashgabat, Tashkent" },
    { jsName: "Asia/Tbilisi", id: "(UTC+04:00) Tbilisi" },
    { jsName: "Asia/Tehran", id: "(UTC+03:30) Tehran" },
    { jsName: "Asia/Thimphu", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Tokyo", id: "(UTC+09:00) Osaka, Sapporo, Tokyo" },
    { jsName: "Asia/Tomsk", id: "(UTC+07:00) Tomsk" },
    { jsName: "Asia/Ulaanbaatar", id: "(UTC+08:00) Ulaanbaatar" },
    { jsName: "Asia/Urumqi", id: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi" },
    { jsName: "Asia/Ust-Nera", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Vladivostok", id: "(UTC+10:00) Vladivostok" },
    { jsName: "Asia/Yakutsk", id: "(UTC+09:00) Yakutsk" },
    { jsName: "Asia/Yangon", id: "(UTC+06:30) Yangon (Rangoon)" },
    { jsName: "Asia/Yekaterinburg", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Asia/Yerevan", id: "(UTC+04:00) Yerevan" },
    { jsName: "Atlantic/Azores", id: "(UTC-01:00) Azores" },
    { jsName: "Atlantic/Bermuda", id: "(UTC-04:00) Atlantic Time (Canada)" },
    { jsName: "Atlantic/Canary", id: "(UTC-04:00) Atlantic Time (Canada)" },
    { jsName: "Atlantic/Cape_Verde", id: "(UTC-04:00) Atlantic Time (Canada)" },
    { jsName: "Atlantic/Faroe", id: "(UTC-04:00) Atlantic Time (Canada)" },
    { jsName: "Atlantic/Madeira", id: "(UTC-04:00) Atlantic Time (Canada)" },
    { jsName: "Atlantic/Reykjavik", id: "(UTC+00:00) Monrovia, Reykjavik" },
    { jsName: "Atlantic/South_Georgia", id: "(UTC-04:00) Atlantic Time (Canada)" },
    { jsName: "Atlantic/Stanley", id: "(UTC-04:00) Atlantic Time (Canada)" },
    { jsName: "Australia/Adelaide", id: "(UTC+09:30) Adelaide" },
    { jsName: "Australia/Brisbane", id: "(UTC+10:00) Brisbane" },
    { jsName: "Australia/Broken_Hill", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Australia/Currie", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Australia/Darwin", id: "(UTC+09:30) Darwin" },
    { jsName: "Australia/Eucla", id: "(UTC+08:45) Eucla" },
    { jsName: "Australia/Hobart", id: "(UTC+10:00) Hobart" },
    { jsName: "Australia/Lindeman", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Australia/Lord_Howe", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Australia/Melbourne", id: "(UTC+10:00) Canberra, Melbourne, Sydney" },
    { jsName: "Australia/Perth", id: "(UTC+08:00) Perth" },
    { jsName: "Australia/Sydney", id: "(UTC+10:00) Canberra, Melbourne, Sydney" },
    { jsName: "Europe/Amsterdam", id: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
    { jsName: "Europe/Andorra", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Europe/Astrakhan", id: "(UTC+04:00) Astrakhan, Ulyanovsk" },
    { jsName: "Europe/Athens", id: "(UTC+02:00) Athens, Bucharest" },
    { jsName: "Europe/Belgrade", id: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
    { jsName: "Europe/Berlin", id: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
    { jsName: "Europe/Brussels", id: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris" },
    { jsName: "Europe/Bucharest", id: "(UTC+02:00) Athens, Bucharest" },
    { jsName: "Europe/Budapest", id: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
    { jsName: "Europe/Chisinau", id: "(UTC+02:00) Chisinau" },
    { jsName: "Europe/Copenhagen", id: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris" },
    { jsName: "Europe/Dublin", id: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London" },
    { jsName: "Europe/Gibraltar", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Europe/Helsinki", id: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
    { jsName: "Europe/Istanbul", id: "(UTC+03:00) Istanbul" },
    { jsName: "Europe/Kaliningrad", id: "(UTC+02:00) Kaliningrad" },
    { jsName: "Europe/Kiev", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Europe/Kirov", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Europe/Lisbon", id: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London" },
    { jsName: "Europe/London", id: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London" },
    { jsName: "Europe/Luxembourg", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Europe/Madrid", id: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris" },
    { jsName: "Europe/Malta", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Europe/Minsk", id: "(UTC+03:00) Minsk" },
    { jsName: "Europe/Monaco", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Europe/Moscow", id: "(UTC+03:00) Moscow, St. Petersburg" },
    { jsName: "Europe/Oslo", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Europe/Paris", id: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris" },
    { jsName: "Europe/Prague", id: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
    { jsName: "Europe/Riga", id: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
    { jsName: "Europe/Rome", id: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
    { jsName: "Europe/Samara", id: "(UTC+04:00) Izhevsk, Samara" },
    { jsName: "Europe/Saratov", id: "(UTC+04:00) Saratov" },
    { jsName: "Europe/Simferopol", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Europe/Sofia", id: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
    { jsName: "Europe/Stockholm", id: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
    { jsName: "Europe/Tallinn", id: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
    { jsName: "Europe/Tirane", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Europe/Ulyanovsk", id: "(UTC+04:00) Astrakhan, Ulyanovsk" },
    { jsName: "Europe/Uzhgorod", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Europe/Vienna", id: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
    { jsName: "Europe/Vilnius", id: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
    { jsName: "Europe/Volgograd", id: "(UTC+04:00) Volgograd" },
    { jsName: "Europe/Warsaw", id: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb" },
    { jsName: "Europe/Zaporozhye", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Europe/Zurich", id: "(UTC) Coordinated Universal Time" },
    { jsName: "Indian/Chagos", id: "(UTC-05:00) Indiana (East)" },
    { jsName: "Indian/Christmas", id: "(UTC-05:00) Indiana (East)" },
    { jsName: "Indian/Cocos", id: "(UTC-05:00) Indiana (East)" },
    { jsName: "Indian/Kerguelen", id: "(UTC-05:00) Indiana (East)" },
    { jsName: "Indian/Mahe", id: "(UTC-05:00) Indiana (East)" },
    { jsName: "Indian/Maldives", id: "(UTC-05:00) Indiana (East)" },
    { jsName: "Indian/Mauritius", id: "(UTC-05:00) Indiana (East)" },
    { jsName: "Indian/Reunion", id: "(UTC-05:00) Indiana (East)" },
    { jsName: "Pacific/Apia", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Auckland", id: "(UTC+12:00) Auckland, Wellington" },
    { jsName: "Pacific/Bougainville", id: "(UTC+11:00) Bougainville Island" },
    { jsName: "Pacific/Chatham", id: "(UTC+12:45) Chatham Islands" },
    { jsName: "Pacific/Chuuk", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Easter", id: "(UTC-06:00) Easter Island" },
    { jsName: "Pacific/Efate", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Enderbury", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Fakaofo", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Fiji", id: "(UTC+12:00) Fiji" },
    { jsName: "Pacific/Funafuti", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Galapagos", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Gambier", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Guadalcanal", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Guam", id: "(UTC+10:00) Guam, Port Moresby" },
    { jsName: "Pacific/Honolulu", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Kiritimati", id: "(UTC+14:00) Kiritimati Island" },
    { jsName: "Pacific/Kosrae", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Kwajalein", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Majuro", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Marquesas", id: "(UTC-09:30) Marquesas Islands" },
    { jsName: "Pacific/Nauru", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Niue", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Norfolk", id: "(UTC+11:00) Norfolk Island" },
    { jsName: "Pacific/Noumea", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Pago_Pago", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Palau", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Pitcairn", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Pohnpei", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Port_Moresby", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Rarotonga", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Tahiti", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Tarawa", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Tongatapu", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Wake", id: "(UTC-08:00) Pacific Time (US & Canada)" },
    { jsName: "Pacific/Wallis", id: "(UTC-08:00) Pacific Time (US & Canada)" }
];
