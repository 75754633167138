function Loader(props) {
    return (
      <div className="w-100 h-100 text-center d-table">
        <div className="d-table-cell v-align-middle">
          <div className="busy-container">
            <img src="lib/logo/busy.svg" alt="busy" />
          </div>
          <span id={props.id} className="badge badge-info">
            {props.text === undefined ? "Please wait..." : props.text}
          </span>
        </div>
      </div>
    );
  }
  
  export default Loader;