import { useState, useEffect } from "react";
import { useConnectionHooks } from "./signalHooks"
import * as signalR from '@microsoft/signalr/dist/esm'
import { settings } from "../../settings";

export const useConnection = () => {
   
    const [connection, setConnection] = useState(null);       

    const [addConnectionHooks, disposeConnectionHooks] = useConnectionHooks(connection);

    //console.log(connection);

    useEffect(() => {  
      setConnection(new signalR.HubConnectionBuilder()
      .withUrl(settings.backendUrl+"/dashboardHub")
      .withAutomaticReconnect()
      .build());
      //TO BE FIXED
      return () => {    
        if (connection) disposeConnectionHooks();
      };
    }, []);

    useEffect(() => {
      if (connection) {
        connection.start()
          .then((result) => {          
            addConnectionHooks();
          })
          .catch((e) => console.log("Connection failed: ", e));
      }
    },
      [connection]);
  
    return;
  };