import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useEffect, useMemo, useState } from "react"
import { classNames } from "../components/application/ApplicationHeader";
import { CircularSpinnerSecondary } from "./Spinners";
import { log } from "../components/util/ErrorBoundary";

export const useDependentAutocomplete = (selection, externalLoading, initialValueKey, value, setValue, dependencyValue, sort, name) => {
    const options = useMemo(() => sort === undefined ? Object.values(selection) : Object.values(selection).sort(sort), [selection, sort]);
    const [isLoading, setIsLoading] = useState(true);
    const waitingForNotNull = !isLoading && options.length > 0 && value === null;
    useEffect(() => {
        if (!externalLoading && dependencyValue !== null) {
            //Time to select initial value or do smth else
            if (initialValueKey !== undefined  && selection[initialValueKey] !== undefined) {
                //found initial in options
                setValue(selection[initialValueKey]);
            }
            else if (options.length > 0) {
                setValue(options[0]);
            }
            else {
                setValue(null);
            }
            setIsLoading(false);
        }
        else if (!isLoading && dependencyValue === null && value !== null) {
            setValue(null);
        }
        else if (!externalLoading && isLoading && dependencyValue === null) {
            setValue(null);
            setIsLoading(false);
        }
    }, [externalLoading, dependencyValue, initialValueKey])
    return { isLoading: externalLoading || isLoading || waitingForNotNull, options, value, onChange: setValue }
}


//repos, isDashboardLoading, repo!==null?repo.id:initialRepoId, repo, setRepo, user)
export const useAutocomplete = (selection, externalLoading, initialValueKey, value, setValue, sort) => {
    const options = useMemo(() => sort === undefined ? Object.values(selection) : Object.values(selection).sort(sort), [selection, sort]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (externalLoading) { return; }
        if (initialValueKey !== undefined && selection[initialValueKey] !== undefined) {
            //found initial in options           
            setValue(selection[initialValueKey]);
        }
        else if (options.length > 0) {
            setValue(options[0]);
        }
        setIsLoading(false);
    }, [externalLoading, initialValueKey])

    return { isLoading: externalLoading || isLoading, options, value, onChange: setValue }
}

export const LoadingAutocomplete = ({ isLoading, autocomplete }) => {
    return (
        <Autotomplete
            isLoading={isLoading}
            {...autocomplete}
        />
    )
}

const NothingToSelectInput = ({size}) => {
    return (
        <div className={classNames("pl-3 w-full border-0 bg-white rounded-md pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6",
        size === "lg" ? "py-3" : "py-1.5")}
        >
            Nothing to select
        </div>
    )
}

export const LoadingAutocompleteInner = ({size}) => {
    return (
        <div className={classNames("w-full border-0 bg-white rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6",
        )}
        >
            <CircularSpinnerSecondary className={size === "lg" ? "py-3.5" : "py-2"}/>
        </div>
    )
}

const Autotomplete = ({ options, isLoading, label, labelSelector, labelLeft, value, onChange, keySelector, leadingContent, optionLeadingContentSelector, size, disabled }) => {

    const compareBy = (a,b)=>{
       return keySelector(a)===keySelector(b);
    }

    const [query, setQuery] = useState(labelSelector(value) || '');
    useEffect(() => {
        setQuery(labelSelector(value) || '');
    }, [labelSelector, value])

    const filtered = useMemo(() => query === '' || query === labelSelector(value)
        ? options
        : options.filter((item) => {
            var selValue = labelSelector(item);
            if (selValue === undefined){
                //LOG
                log({message:`Autocomplete selected label is undefined, ${label}, ${options}`}, undefined);
            }
            return selValue===undefined ? false : selValue.toLowerCase().includes(query.toLowerCase())
        }), [query, labelSelector, options, value]);
    const onChangeInternal = (newValue) => {
        if (labelSelector(newValue) === '') {
            onChange(value);
        }
        onChange(newValue);
    }
    return (
        <Combobox as="div" by={compareBy} value={value} onChange={onChangeInternal}>
            <div className={labelLeft ? "flex flex-row" : ""}>
                <Combobox.Label className={classNames(labelLeft ? "w-1/3 self-center" : "block", "text-sm font-medium leading-6 text-gray-900")}>{label}</Combobox.Label>
                <div className={classNames("relative", labelLeft ? "flex-1 ml-2" : label===undefined? "" : "mt-1")}>
                    {isLoading ?
                        <LoadingAutocompleteInner size={size}/> :
                        options.length === 0 ?
                            <NothingToSelectInput size={size}/> :
                            <Combobox.Button as="div" className="flex">
                                {leadingContent !== undefined && leadingContent}
                                <Combobox.Input
                                    spellCheck={false}
                                    className={classNames(
                                        size === "lg" ? "py-3" : "py-1.5",
                                        leadingContent !== undefined ? "rounded-r-md" : "rounded-md",
                                        "w-full border-0 bg-white pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6",
                                        optionLeadingContentSelector !== undefined ? size === "lg" ? "pl-12" : "pl-8" : "pl-3")}
                                    onChange={(event) => disabled ? {} : setQuery(event.target.value)}
                                    displayValue={(item) => labelSelector(item)}
                                />
                                {optionLeadingContentSelector !== undefined && <div className="absolute -translate-y-1/2 top-1/2 left-2">
                                    {optionLeadingContentSelector(value)}
                                </div>}
                                <ChevronUpDownIcon className={classNames(size === "lg" ? "mt-3.5" : "mt-2", "h-5 w-5 text-gray-400 mr-2 absolute right-0")} aria-hidden="true" />
                            </Combobox.Button>
                    }
                    {!isLoading && !disabled && filtered.length > 0 && (
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 min-w-full max-w-[300px] w-max overflow-y-auto overflow-x-hidden rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filtered.map((item) => (
                                <Combobox.Option
                                    key={keySelector(item)}
                                    value={item}
                                    className={({ active }) =>
                                        classNames(
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                            active ? 'bg-gray-600 text-white' : 'text-gray-900'
                                        )
                                    }
                                >
                                    {({ active, selected }) => (
                                        <>
                                            <div className="inline-flex items-center align-middle w-full overflow-hidden">
                                                <div className="mr-1">{optionLeadingContentSelector !== undefined && optionLeadingContentSelector(item)}</div>
                                                <span className={classNames('block truncate', selected && 'font-semibold')}>{labelSelector(item)}</span>
                                            </div>

                                            {selected && (
                                                <span
                                                    className={classNames(
                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                        active ? 'text-white' : 'text-gray-600'
                                                    )}
                                                >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            )}
                                        </>
                                    )}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>
                    )}
                </div>
            </div>
        </Combobox>
    )
}