import { Tooltip } from "react-tooltip"
import { useEffect, useMemo, useState } from "react"
import { ProviderSettingsWrapper, ProviderStepWrapper, ProviderTestAndSave, StorageWrapperComponent, useBroadcastChannel, useStorageSection } from "./ProvidersCommon"
import { useDispatch, useSelector } from "react-redux"
import { isDevelopment, settings } from "../../../settings"
import { CircularSpinnerSecondary } from "../../../kit/Spinners"
import { CheckCircleIcon } from "@heroicons/react/24/outline"
import postscribe from 'postscribe';
import { InTextClickButton, InTextLink } from "../../../kit/Badges"
import { setModal } from "../../../slices/userSlice"
import { Modals } from "../../application/modals/ModalsCommon"

const parseConnectionString = cs => {
    if (!cs) return {
        accountId: null,
        selection: null,
    }
    let parts = cs.split(';');
    let partsDict = parts.map(x => { let keyValue = x.split('='); return { key: keyValue[0], value: keyValue[1] } }).reduce((acc, val) => ({ ...acc, [val.key]: val.value }), {});
    return (
        {
            accountId: partsDict['AccountId'],
            selection: {
                driveId: partsDict['DriveId'],
                folderId: partsDict['FolderId']
            }
        }
    )
}
export const OneDriveBusinessStorageInfo = ({ onComplete, onCancel, onTest, nameProvided, connectionString, editMode, setPageError }) => {
    const parsedConnectionString = useMemo(() => parseConnectionString(connectionString), [connectionString])
    const userId = useSelector(state => state.user.id);
    const [accountId, setAccountId] = useState(parsedConnectionString.accountId);
    const [selection, setSelection] = useState(parsedConnectionString.selection);
    const canContinue = accountId !== null && selection !== null;
    const buildConnectionString = () => `IdentityId=${userId};AccountId=${accountId};DriveId=${selection.driveId};FolderId=${selection.folderId};`;

    const storageEditor = useStorageSection(onCancel, onTest, onComplete, buildConnectionString, editMode, setPageError);
    const permissionsBroadcast = useBroadcastChannel();
    const onGrantPermission = () => {
        permissionsBroadcast.setBusy(true);
        const userState = encodeURIComponent(JSON.stringify({
            InitiatorIdentityId: userId
        }));
        const winUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${storageEditor.providers.list["MicrosoftOneDriveBusiness"].userInterfaceHelper.parameters.ClientId}&scope=https://graph.microsoft.com/user.read+files.readwrite+offline_access&response_type=code&redirect_uri=${settings.identityUrl}/signin-microsoft&state=${userState}`;
        const newpopup = window.open(winUrl,
            'Grant OneDrive permission',
            (isDevelopment ? "scrollbars=yes,resizable=yes,status=yes,toolbar=yes,menubar=yes,location=yes," : "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,") + 'height=800,width=1016');
        permissionsBroadcast.checkExit(newpopup, "Failed to grant permissions");
    }
    useEffect(() => {
        if (permissionsBroadcast.extParams !== null && permissionsBroadcast.extParams.error === undefined) {
            setAccountId(permissionsBroadcast.extParams.accountId);
        }
        else if (permissionsBroadcast.extParams !== null && permissionsBroadcast.extParams.error === undefined) {
            //Show alert with error
        }
    }, [permissionsBroadcast.extParams])

    const dispatch = useDispatch();
    //Picker
    const [pickerLoading, setPickerLoading] = useState(true);
    const [pickerBusy, setPickerBusy] = useState(false);
    useEffect(() => {
        postscribe('#onedrive-picker', '<script language="javascript" src="https://js.live.net/v7.2/OneDrive.js"></script>', {
            afterAsync: () => setPickerLoading(false)
        });
    }, [])

    const onPicker = () => {
        setPickerBusy(true);
        const options = {
            clientId: storageEditor.providers.list["MicrosoftOneDriveBusiness"].userInterfaceHelper.parameters.ClientId,
            ation: "query",
            openInNewWindow: true,
            multiSelect: false,
            viewType: "folders",
            advanced: {
                redirectUri: `${settings.currentUrl}/picker.html`,
                filter: ".png,.jpg,.jpeg",
                queryParameters: "select=id,name,folder,parentReference",
                scopes: "Files.Read"
            },
            cancel: ()=>{
                dispatch(setModal({ id: Modals.WarningModalWithSingleCloseButton, title: "Failed to select folder", text: "Popup was closed. Please try again" }));
                setPickerBusy(false);
            },
            success: response => {
                try{
                    if (response && response.value.length > 0) {
                        var folder = response.value[0];
                        setSelection({
                            folderId: folder.id,
                            driveId: folder.parentReference.driveId
                        })
                    }
                }
                finally{
                    setPickerBusy(false);
                }                
            },
            error: e => {
                dispatch(setModal({ id: Modals.WarningModalWithSingleCloseButton, title: "Failed to select folder", text: "Error occured: "+e }));
                setPickerBusy(false);
            }
        }
        /*global OneDrive*/
        OneDrive.open(options);
    }

    return (
        <>
            <StorageWrapperComponent className="bg-blue-50/20" providers={storageEditor.providers}>
                <ProviderStepWrapper stepTitle="Step 1"
                >
                    <p>
                        Ensure you have an access to your <InTextLink url="https://www.microsoft.com/en-ww/microsoft-365/onedrive/onedrive-for-business"
                            title="OneDrive for Business Account" />
                    </p>
                </ProviderStepWrapper>
                <ProviderStepWrapper stepTitle="Step 2"
                >
                    <p>Grant a permission to Cloudback application:{' '}</p>
                    <div className="ml-1">
                        <InTextClickButton busy={permissionsBroadcast.busy} onClick={onGrantPermission} title="Grant permission" extra={
                            accountId !== null &&
                            <CheckCircleIcon className="h-5 w-5 text-green-400 mr-1" aria-hidden="true" data-tooltip-id="onedrive-permissions-tooltip" data-tooltip-content="Permission granted" />
                        } />
                    </div>

                    <Tooltip id="onedrive-permissions-tooltip" />
                </ProviderStepWrapper>
                <ProviderStepWrapper stepTitle="Step 3"
                >
                    <p>Choose a destination folder to store backups:{' '}</p>
                    <div className="ml-1">
                        {pickerLoading ? <CircularSpinnerSecondary/> :
                            <>
                                <InTextClickButton busy={pickerBusy} onClick={onPicker} title="Open file picker" extra={
                                    selection !== null && <CheckCircleIcon className="h-5 w-5 text-green-400 mr-1" aria-hidden="true" data-tooltip-id="onedrive-selection-tooltip" data-tooltip-content="Folder selected" />} />
                            </>
                        }
                    </div>
                    <Tooltip id="onedrive-selection-tooltip" />
                </ProviderStepWrapper>
            </StorageWrapperComponent>
            <ProviderTestAndSave storageEditor={storageEditor} nameIncluded={nameProvided} canContinue={canContinue} />
        </>
    )
}