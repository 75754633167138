import { useMemo, useState } from "react"
import { classNames } from "../application/ApplicationHeader";
import { SelectBox } from "../../kit/SelectBox";
import { useDispatch, useSelector } from "react-redux";
import { getBackupStatus, getCard, getRestoreStatus, selectCard, selectLatestBackup, selectLatestRestore } from "../../helpers/domainHelpers";
import { LockIcon, RepoIcon, RepoPushIcon } from "@primer/octicons-react";
import { Link } from "react-router-dom";
import { RocketLaunchIcon } from "@heroicons/react/24/outline";
import { ScheduleStatus } from "../../model/domain";
import { Tooltip } from "react-tooltip";
import { CircularSpinnerSecondary, NewLoader } from "../../kit/Spinners";
import { backupIsRunning, restoreIsRunning } from "../repositories/RepositoryCard";
import axios from "axios";
import { settings, getGitHubAvatarUrl } from "../../settings";
import { setModal } from "../../slices/userSlice";
import { Modals } from "../application/modals/ModalsCommon";
import { useNotifier } from "../../hooks/notifications/notificationActions";
import { processAxiosError } from "../util/ErrorBoundary";
import { LongTextTag } from "../../kit/LongInvisibleInput";
import { updateBackupsForCards } from "../../slices/dataSlice";

const RepositoryHeadInfo = ({ cardId }) => {
    const card = useSelector((state) => selectCard(state, cardId));
    const restore = useMemo(()=>card?.lastRestore,[card]);
    const backup =  useMemo(()=>card?.lastBackup,[card]);
    const running = useMemo(() => backupIsRunning(backup?.status), [backup?.status]);
    const restoreRunning = useMemo(() => restoreIsRunning(restore?.status), [restore?.status]);
    const [triggerBusy, setTriggerBusy] = useState(false);
    const [notifySuccess] = useNotifier();
    const dispatch = useDispatch();
    const triggerSelected = () => {
        //build cards list for trigger
        setTriggerBusy(true);
        axios.post(settings.backendUrl + "/v2/triggerSingle", {repositoryIds:[card.repositoryId]})
            .then((res) => {
                if (res.data.isSuccess) {
                    notifySuccess("Backup started", "backups_start");
                    dispatch(updateBackupsForCards([{repositoryId:card.repositoryId}]));
                }
                else {
                    dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to trigger", text: res.data.errorDescription }));
                }
            }).catch(e => {
                processAxiosError(e);
                dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to trigger", text: "Something went wrong" }));
            })
            .finally(() => {
                setTriggerBusy(false);
            });
    }

    return card === undefined ? <NewLoader size="md" className="!justify-start ml-5 pt-3 flex-1" /> : (
        <div className="pt-3 pb-2">
            <div className="flex flex-nowrap items-center justify-between sm:flex-nowrap w-full">
                <div className="flex items-center w-full overflow-hidden">
                    <div className="flex-shrink-0">
                        <div className="relative inline-block pr-2 align-middle">
                            <img
                                className="h-10 w-10 rounded-md"
                                src={getGitHubAvatarUrl(card.repository.owner, 40)}
                                alt=""
                            />
                            <span>
                                {card.repository.isPrivate ?
                                    <LockIcon className="absolute right-0 bottom-0 block h-3 w-3 rounded-sm bg-white -translate-x-2/4" />
                                    :
                                    <RepoIcon className="absolute right-0 bottom-0 block h-3 w-3 rounded-sm bg-white -translate-x-2/4" />
                                }
                            </span>
                        </div>
                    </div>
                    <div className="ml-2 flex flex-row items-center overflow-hidden">
                        <LongTextTag className="text-base font-semibold leading-6 text-gray-900" value={`${card.repository.owner}/${card.repository.name}`}/>
                    </div>
                </div>
                <div className="flex">
                {
                    running ?
                        <p className={classNames("inline-flex items-center rounded-md px-2 py-1 text-md font-medium tracking-tight whitespace-nowrap", getBackupStatus(backup).Style, "!ring-none")}>
                            Backup {getBackupStatus(backup).Text}
                        </p> :
                        card.scheduleStatus !== ScheduleStatus.restoreOnly && <div className="flex items-center">

                            <button
                                disabled={triggerBusy}
                                onClick={triggerSelected}
                                className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                            >
                                {triggerBusy ? <CircularSpinnerSecondary className="mr-1" light={true} size="sm" /> : <RocketLaunchIcon className="h-5 w-5 sm:mr-1" />}
                                <span className="hidden sm:block whitespace-nowrap">Backup now</span></button>
                        </div>}
                {
                    restoreRunning ?
                        <p className={classNames("inline-flex items-center rounded-md px-2 py-1 text-md font-medium tracking-tight sm:ml-1 whitespace-nowrap", getRestoreStatus(restore).Style, "!ring-none")}>
                            Restore {getRestoreStatus(restore).Text}
                        </p> :
                        backup !== null && backup !== undefined && <div className="flex items-center">
                            <Link
                                disabled={triggerBusy}
                                to={`/restore/${card.repository.owner}/${card.repositoryId}`}
                                className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 ml-2 sm:ml-3 sm:w-auto"
                            >
                                <RepoPushIcon className="h-5 w-5 sm:mr-1 !block" />
                                <span className="hidden sm:block whitespace-nowrap">Restore now</span></Link>
                        </div>
                }
                </div>
            </div>
        </div>
    )
}

export const RepositoryDetailsHead = ({ tabs, currentTab, setCurrentTab, cardId }) => {
    const tabsArray = useMemo(() => Object.values(tabs), [tabs]);
    return (
        <div className="shadow sticky top-0 bg-gray-100 z-[11] h-[113px]">
            <div className="px-6 h-full">
                <RepositoryHeadInfo cardId={cardId} />
                <div className="sm:hidden">
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <SelectBox
                        options={tabsArray}
                        selected={currentTab}
                        setSelected={(v) => setCurrentTab(v)}
                        keySelector={t => t.id}
                        labelSelector={t => t.name} />
                </div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {tabsArray.map((tab) => (
                                <button
                                    key={tab.name}
                                    onClick={() => setCurrentTab(tab)}
                                    className={classNames(
                                        currentTab.id === tab.id
                                            ? 'border-gray-700 text-gray-700'
                                            : 'border-gray-300 text-gray-500 hover:border-gray-700 hover:text-gray-700',
                                        'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                                    )}
                                    aria-current={currentTab.id === tab.id ? 'page' : undefined}
                                >
                                    <tab.icon
                                        className={classNames(
                                            currentTab.id === tab.id ? 'text-gray-700' : '',
                                            '-ml-0.5 mr-2 h-5 w-5'
                                        )}
                                        aria-hidden="true"
                                    />
                                    {tab.name}
                                    {tab.count !== undefined ? (
                                        <span
                                            className={classNames(
                                                currentTab.id === tab.id ? 'bg-gray-300 text-gray-700' : 'bg-gray-300 text-gray-500',
                                                'ml-1 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                                            )}
                                        >
                                            {tab.count !== -1 ? tab.count : <NewLoader size="xs" className="flex-1"/>}
                                        </span>
                                    ) : null}
                                </button>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}