import { useSelector } from "react-redux"
import { DashboardCard } from "../../../kit/DashboardCard"
import { classNames } from "../ApplicationHeader";
import { TableCell, TableHeadCell } from "../../../kit/Table";
import { CircularSpinnerSecondary, FullScreenLoader, NewLoader } from "../../../kit/Spinners";
import { CheckIcon } from "@primer/octicons-react";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog } from "@headlessui/react";





export const TriggerMultipleResultModal = ({ onClose, data }) => {
    const triggerInfo = useSelector(state => state.user.triggerNotifications[data.correlationId]);
    const loaded = triggerInfo?.totalCount !== undefined;
    return (
        <div className="w-full h-ful flex bg-white flex-col gap-5">
            <div className="relative inline-flex w-full bg-white">
                        <h3 className="font-semibold">Trigger backups</h3>
                        <div className="absolute right-0 top-0 hidden sm:block">
                            <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                                onClick={onClose}
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
            {!loaded ? <FullScreenLoader title={null} className="" /> :
                <div className="w-full h-full flex flex-col gap-2 pt-1">
                    {loaded && <div className="inline-flex w-full justify-start pb-2">
                        <div className="flex flex-row gap-3">
                            {triggerInfo?.completed ?
                                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                </div>
                                : <NewLoader />}
                            <div className="flex flex-col gap-1">
                                <p>Triggered: {triggerInfo.triggeredCount}</p>
                                <p>Skipped: {triggerInfo?.notTriggered?.length}</p>
                                {!triggerInfo.completed && <p>Remaining: {triggerInfo?.totalCount - triggerInfo.triggeredCount - triggerInfo?.notTriggered?.length}</p>}
                            </div>
                        </div>
                    </div>}
                    {!triggerInfo?.completed && <p>You can return to the dashboard. We will notify you once the operation is completed.</p>}
                    {triggerInfo?.notTriggered?.length > 0 &&
                        <>
                            <div className="flex items-center align-middle">
                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <Dialog.Title as="h2" className="text-base text-sm font-semibold leading-6 text-gray-900">
                                        {`Following backup${triggerInfo?.notTriggered?.length > 1 ? "s are" : " is"} not triggered`}
                                    </Dialog.Title>
                                </div>
                            </div>
                            <table className="min-w-full h-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <TableHeadCell sticky={true} className="font-weight-bold rounded-tl-lg !-top-6" scope="row">Repository</TableHeadCell>
                                        <TableHeadCell sticky={true} className="font-weight-bold rounded-tr-lg !-top-6" scope="row">Reason</TableHeadCell>
                                    </tr>
                                </thead>
                                <tbody>
                                    {triggerInfo?.notTriggered.map((error) =>
                                        <tr key={error.repositoryId}>
                                            <TableCell>{error.owner}/{error.repositoryName}</TableCell>
                                            <TableCell><span className="break-words">{error.reason}</span></TableCell>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </>}
                    <div className="inline-flex w-full justify-end">
                        <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                            onClick={onClose}
                        >
                            Close
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}
