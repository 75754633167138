import { memo, useLayoutEffect, useMemo, useRef } from "react";
import { TableCell, TableHeadCell } from "../../kit/Table";
import { deselectAll, selectAll, setOrder } from "../../slices/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLongDownIcon, ArrowLongUpIcon } from "@heroicons/react/20/solid";



export const SelectBoxHeaderCell = ({isLoading}) => {

    const dispatch = useDispatch();
    const selectedCount = useSelector((state) => Object.keys(state.data.selectors.selectedCards).length);
    const totalCount = useSelector((state) => state.data.dashboard.cards.length);
    const isIndeterminate = useMemo(()=>selectedCount > 0 && selectedCount < totalCount, [selectedCount, totalCount]);
    const handleSelectAll = (event) => {
        if (event.target.checked && !isIndeterminate) {
            dispatch(selectAll());
            return;
        }
        dispatch(deselectAll());
    };
    const checkbox = useRef();

    useLayoutEffect(() => {
        checkbox.current.indeterminate = selectedCount > 0 && selectedCount < totalCount;
      }, [selectedCount])
    

    return (
        <TableHeadCell padding="checkbox" sticky={true} className="!w-[40px]">
            <input                
                disabled={isLoading}
                type="checkbox"
                className="h-6 w-6 rounded border-gray-300 hover:bg-gray-300 hover:text-gray-300 text-gray-600"
                ref={checkbox}
                checked={totalCount > 0 && selectedCount === totalCount}
                onChange={handleSelectAll}
            />            
        </TableHeadCell>
    );
}


export const GenericHeaderCell = memo((props) => {
    const headCell = props.headCell;
    const dispatch = useDispatch();
    const orderSet = useSelector((state) => state.data.selectors.orderSet);
    const handleSort = () => {
        const isAsc = orderSet.orderBy === headCell.orderBy && orderSet.order === "asc";
        dispatch(setOrder({ order: isAsc ? "desc" : "asc", orderBy: headCell.orderBy }));
    };
    return (
        <TableHeadCell key={props.id} sticky={true}
            className={props.className}
            >
            <button
                type="button"
                className="inline-flex items-center gap-x-1.5"
                onClick={handleSort}>
                <span className="whitespace-nowrap">{headCell.label}</span>
                {orderSet.orderBy === headCell.orderBy &&
                        (orderSet.order === "desc" ? <ArrowLongDownIcon className="-mr-0.5 h-4 w-4" aria-hidden="true"/> : <ArrowLongUpIcon className="-mr-0.5 h-4 w-4" aria-hidden="true"/>)
                }
            </button>
        </TableHeadCell>
    );
})


const headCells = [
    { orderBy: "owner-repo", label: "Repository" },
    { orderBy: "schedule", label: "Schedule" },
    { orderBy: "storage", label: "Storage" },
    { orderBy: "lastBackupStatus", label: "Last backup" }
];


export const HeaderInner = memo(({isLoading}) => {
    return (
        <tr>
            <SelectBoxHeaderCell isLoading={isLoading}/>            
            <GenericHeaderCell headCell={headCells[0]} id={0} className="w-full"/>
            <GenericHeaderCell headCell={headCells[1]} id={1} />
            <GenericHeaderCell headCell={headCells[2]} id={2} />
            <GenericHeaderCell headCell={headCells[3]} id={3} />
            <TableHeadCell sticky={true} className="!w-5"><div className="w-5"></div></TableHeadCell>
        </tr>
    );
});