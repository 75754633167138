import { SnackbarKey, SnackbarMessage } from "notistack";
import { useDispatch } from "react-redux"
import { addNotification } from "../../slices/notificationSlice"

export const useNotifier = ()=>{
    
    const dispatch = useDispatch();

    function notifySuccess(message, key = "") {
   
        dispatch(addNotification(
            {
                message: message,
                options: {
                    variant: "success",
                    key: key === "" ? new Date().getTime() + Math.random() : key
                }
            }
        ));
    };

    function notifyInfo(message, key = "") {
        dispatch(addNotification(
            {
                message: message,
                options: {
                    variant: "info",
                    key: key === "" ? new Date().getTime() + Math.random() : key
                }
            }
        ));
    };

    function notifyError(message, key = "") {
        dispatch(addNotification(
            {
                message: message,
                options: {
                    variant: "error",
                    key: key === "" ? new Date().getTime() + Math.random() : key
                }
            }
        ));
    };

    return [notifySuccess, notifyInfo, notifyError];
}



