import { EmptyState } from "../../kit/EmptyState"
import { dynamicSettings, settings } from "../../settings"
import { CloudbackIcon, EmptyReposItems } from "../repositories/EmptyRepoState"
import { classNames } from "./ApplicationHeader"



export const EmptyInstallationPage = () => {
    var item = EmptyReposItems[0];
    return(
        <EmptyState title="No GitHub application installations found" 
        text="Here are some friendly suggestions for what to do next"
        Icon={()=><></>}
        content={
            <div className="flex-1 relative mt-4  -m-2 flex items-start space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-gray-500 hover:bg-gray-200">
                                <div
                                    className={classNames(
                                        'flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg'
                                    )}
                                >
                                    <item.icon className="h-8 w-8 !block" aria-hidden="true" />
                                </div>
                                <div>
                                    <h3 className="text-sm font-medium text-gray-900">
                                        <a href={dynamicSettings.appInstallUrl} className="focus:outline-none">
                                            <span className="absolute inset-0" aria-hidden="true" />
                                            <span>{item.title}</span>
                                            <span aria-hidden="true"> &rarr;</span>
                                        </a>
                                    </h3>
                                    <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                </div>
                            </div>
        } />
    )
}