import { createSelector } from "@reduxjs/toolkit"
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { settings } from "../../settings";
import { loadBackups } from "../../slices/dataSlice";
import { FullScreenLoader } from "../../kit/Spinners";
import BackupTable from "./BackupTable";
import { NoSymbolIcon } from "@heroicons/react/24/outline";
import { useDependentMixedData } from "../../hooks/useMixedData";

const selectBackups = createSelector([
    state => state.data.dashboard.loadedBackups,
    (state, defId) => defId
], (backups, defId) => defId === 0 || backups === undefined || !(defId in backups) ? [] : backups[defId].filter(x => x.status !== "deleted"))

export const RepositoryBackups = ({ defId, repoId, repo, owner }) => {

    const [backups, isLoading] = useDependentMixedData(() => axios.get(settings.backendUrl + "/v2/backups?definitionId=" + defId),
        (res, dispatch) => dispatch(loadBackups(res.data.result)), (state) => selectBackups(state, defId), defId, 
        (data) => data !== undefined && data !== null && data.length > 0);

    return (
        <>
            {isLoading ? <FullScreenLoader className="h-96" fillColor="gray" strokeColor="black" /> :
                <div className="p-2">
                    {backups.length > 0 ? <BackupTable rows={backups} repo={repo} repoId={repoId} owner={owner} />
                        :
                        <div className="flex flex-col mt-10">
                            <div className="flex align-middle justify-center items-center pb-2">
                                <NoSymbolIcon className="h-6 w-6" style={{ opacity: 0.3 }} />
                            </div>
                            <div className="flex align-middle justify-center items-center">
                                <div className="align-middle text-center">
                                    <span className="text-nowrap text-gray-400">
                                        No backups here yet
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    )
}