import { InTextLink } from "../../../kit/Badges"
import { useMemo, useState } from "react"
import { ProviderStepWithDoubleTextInput, ProviderStepWithTextArea, ProviderStepWithTextInput, ProviderTestAndSave, StorageWrapperComponent, useStorageSection } from "./ProvidersCommon"

const parseConnectionString = cs => {
    if (!cs) return {
        bucketArn: '',
        keyId: '',
        keySecret: '',
        httpHeaders: '',
        endpointUrl: ''
    }
    let parts = cs.split(';');
    let partsDict = parts.map(x => { let keyValue = x.split('='); return { key: keyValue[0], value: keyValue[1] } }).reduce((acc, val) => ({ ...acc, [val.key]: val.value }), {});

    return (
        {
            bucketArn: partsDict['BucketName'],
            keyId: partsDict['AccessKeyId'],
            keySecret: partsDict['AccessKeySecret'],
            httpHeaders: partsDict['Headers'] !== undefined ? atob(partsDict['Headers']) : '',
            endpointUrl: partsDict['ServiceUrl'] || '',
        }
    )
}
export const AmazonS3AccessKeyStorageInfo = ({ onComplete, onCancel, onTest, nameProvided, connectionString, editMode, setPageError }) => {
    const parsedConnectionString = useMemo(() => parseConnectionString(connectionString), [connectionString])
    const [bucketArn, setBucketArn] = useState(parsedConnectionString.bucketArn);
    const [keyId, setKeyId] = useState(parsedConnectionString.keyId);
    const [keySecret, setKeySecret] = useState(parsedConnectionString.keySecret);
    const [httpHeaders, setHttpHeaders] = useState(parsedConnectionString.httpHeaders);
    const [endpointUrl, setEndpointUrl] = useState(parsedConnectionString.endpointUrl);


    const buildConnectionString = () => `BucketName=${bucketArn};AccessKeyId=${keyId};AccessKeySecret=${keySecret};ServiceUrl=${endpointUrl};Headers=${btoa(httpHeaders)}`;
    const storageEditor = useStorageSection(onCancel, onTest, onComplete, buildConnectionString, editMode, setPageError);
    const canContinue = bucketArn !== '' && keyId !== '' && keySecret !== '';


    return (
        <>
            <StorageWrapperComponent className="bg-red-50/20" providers={storageEditor.providers}>
                <ProviderStepWithTextInput stepTitle="Step 1" placeholder="arn:aws:s3:::"
                    value={bucketArn} setValue={setBucketArn}
                >
                    <p>
                        Create an <InTextLink url="https://docs.aws.amazon.com/AmazonS3/latest/gsg/CreatingABucket.html" title="Amazon S3 Bucket" />
                        {' '}and type a <b>Bucket ARN</b> below:
                    </p>
                </ProviderStepWithTextInput>
                <ProviderStepWithDoubleTextInput
                    stepTitle="Step 2"
                    firstTitle="Access Key ID"
                    secondTitle="Access Key Secret"
                    firstPlaceholder=""
                    secondPlaceholder=""
                    firstValue={keyId}
                    setFirstValue={setKeyId}
                    secondValue={keySecret}
                    setSecondValue={setKeySecret}
                >
                    <p>
                        Create an <InTextLink url="https://aws.amazon.com/premiumsupport/knowledge-center/create-access-key/" title="AWS access key" />,
                        configure <InTextLink url="https://docs.cloudback.it/custom-storages/amazon-s3-bucket-access-key/#required-permissions" title="permissions" />
                        {' '}and type its <b>id</b> and <b>secret</b> below:
                    </p>
                </ProviderStepWithDoubleTextInput>
                <ProviderStepWithTextInput stepTitle="Step 3 (optional)" placeholder="http(s)://"
                    value={endpointUrl} setValue={setEndpointUrl}
                >
                    <p>
                        Provide a <InTextLink url="https://docs.aws.amazon.com/general/latest/gr/rande.html" title="Service Endpoint URL" />:
                    </p>
                </ProviderStepWithTextInput>
                <ProviderStepWithTextArea
                    stepTitle="Step 4 (optional)"
                    value={httpHeaders}
                    setValue={setHttpHeaders}
                >
                    <p>
                        Provide additional HTTP headers, if requred {'('}for example, for <InTextLink url="https://docs.cloudback.it/features/s3-object-lock/" title="S3 Object Lock" />{')'}:
                    </p>
                </ProviderStepWithTextArea>
            </StorageWrapperComponent>
            <ProviderTestAndSave storageEditor={storageEditor} nameIncluded={nameProvided} canContinue={canContinue} />
        </>
    )
}