import axios from "axios";
import React, { ErrorInfo } from "react";
import { settings } from "../../settings";
import { ErrorPage } from "../../kit/ErrorPage";

const isString = value => typeof value === 'string';
export const processAxiosError = (error) => {
    if (isString(error)) {
        log({ message: error }, null);
    }
    else {
        log({ message: JSON.stringify(error) },null);
    }
}

export const log = (error, info) => {
    axios.post(settings.backendUrl + "/v2/log", { error: { message: error.message, name: error.name, stack: error.stack }, info: info })
        .then((res) => { })
        .catch(e => {
            console.log(e);
            console.log("Error");
        });
}

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        log(error, info);
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <ErrorPage />
                </>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;