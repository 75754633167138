import ReactApexChart from "react-apexcharts";
import PropTypes from 'prop-types';
import {NoChartDataFound} from "./NoChartDataFound";
import {apexColors} from "./../../util/Colors";

export const DeduplicationSavings = ({ stats }) => {
  const statsAreEmpty = stats.dailySavingsBytes.every(value => value === "0.00") && stats.accumulatedSavingsBytes.every(value => value === "0.00");
    const series = [
        {
            name: 'Deduplicated size',
            data: stats.dailySavingsBytes
        },
        {
            name: 'Total deduplicated size',
            data: stats.accumulatedSavingsBytes
        },
    ];
    const options = {
        colors: apexColors,
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '90%',
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: true,
            position: 'bottom',
            showForSingleSeries: true,
            onItemClick: {
                toggleDataSeries: false
            },
        },
        xaxis: {
            type: 'category',
            categories: stats.dates,
            tickAmount: 10,
            labels: {
                datetimeUTC: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            tooltip: {
                enabled: false,
            }
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: true,
                formatter: function (val) {
                    return val.toFixed(2) + stats.size;
                }
            }

        },
    };

    return (
        <div className="w-full flex-1 min-h-[250px] border border-gray-50 rounded-lg relative">
          { statsAreEmpty && <NoChartDataFound/> }
          <ReactApexChart options={options} series={series} type="bar" height="100%" width="98%" />
        </div>
    )
}

DeduplicationSavings.propTypes = {
  stats: PropTypes.shape({
    dailySavingsBytes: PropTypes.arrayOf(PropTypes.string).isRequired,
    accumulatedSavingsBytes: PropTypes.arrayOf(PropTypes.string).isRequired,
    dates: PropTypes.arrayOf(PropTypes.string).isRequired,
    size: PropTypes.string.isRequired,
  }).isRequired,
};
