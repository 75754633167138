import { Tooltip } from "react-tooltip"
import { CircularSpinnerSecondary } from "./Spinners"
import { classNames } from "../components/application/ApplicationHeader"

export const ButtonType = {
    Primary:"Primary",
    Secondary:"Secondary",
    Cancel:"Cancel"
};

export const FormButton = ({buttonType=ButtonType.Primary, onClick, disabled, loading, busy, showTooltip, tooltipText, tooltipId, btnText, className, leftIcon}) => {
    return (
        <>
            <button
                data-tooltip-id={tooltipId} data-tooltip-content={tooltipText}
                onClick={onClick}
                disabled={disabled || loading}
                type="submit"
                className={classNames(
                    "mt-3 inline-flex w-full justify-center items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm  sm:ml-3 sm:mt-0 sm:w-auto whitespace-nowrap",
                    buttonType===ButtonType.Primary?"bg-green-600 hover:bg-green-500 text-white" : 
                    buttonType===ButtonType.Secondary?"bg-gray-600 hover:bg-gray-500 text-white" : "bg-white text-gray-900 hover:bg-gray-50 ring-1 ring-inset ring-gray-300",
                    loading ? "!bg-gray-200" : "",disabled?"!bg-gray-200 hover:!bg-gray-200":"",className)}
            >
                {busy && <CircularSpinnerSecondary light={true} className="mr-1" />}
                {leftIcon && leftIcon}               
                {btnText}
            </button>
            {showTooltip && <Tooltip className="z-[55]" id={tooltipId} />}
        </>
    )
}