import { CalendarIcon, ClockIcon, LockIcon, RepoIcon, UnlockIcon } from "@primer/octicons-react"
import { DashboardCard, Statement } from "../../../kit/DashboardCard"
import { ArchiveBoxIcon } from "@heroicons/react/24/outline"
import { useMemo } from "react"
import { getRestoreStatus } from "../../../helpers/domainHelpers"
import { classNames } from "../ApplicationHeader"
import { ErrorCode, RestoreStatus } from "../../../model/domain"
import { XCircleIcon } from "@heroicons/react/20/solid"

export const RestoreInfoBlock = ({ title, restore, onClose }) => {
    const restoreStatus = useMemo(() =>
        getRestoreStatus(restore)
        , [restore?.status])
    return (
        <DashboardCard title={title || "Restore info"} isLoading={restore === undefined} rightContent={
            <p className={classNames("inline-flex items-center rounded-md px-2 py-1 text-md font-medium tracking-tight", restoreStatus.Style, "!ring-none")}>
                {restoreStatus.Text}
            </p>
        }>
            {restore && restore?.status === RestoreStatus.failed &&
                <div className="rounded-md bg-red-50 p-2 mb-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800">Error during restore:</h3>
                            <div className="mt-2 text-sm text-red-700">
                                <p>{restore.errorDescription}</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="flex flex-col gap-1 flex-1">    
                <Statement title="Start time" value={restore?.startTimeText} Icon={CalendarIcon} />    
                <Statement title="Elapsed time" value={restore?.meta.elapsedText} Icon={ClockIcon} />            
                <Statement title="Source repository" valueLink={`https://github.com/${restore?.meta.owner}/${restore?.meta.repo}`} value={`${restore?.meta.owner}/${restore?.meta.repo}`} Icon={ArchiveBoxIcon} />
                <Statement title="Target repository" valueLink={restore?.remoteRepositoryUrl} value={restore?.remoteRepositoryUrl?.replace("https://github.com/", "")} Icon={RepoIcon} />
                <Statement title="Backup size" value={restore?.meta.compressedSizeText} Icon={ArchiveBoxIcon} />
                <Statement title="Backup time" value={restore?.backupStartTimeText} Icon={CalendarIcon} />
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                {onClose && <button type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={onClose}>
                    Close
                </button>}
            </div>
        </DashboardCard>
    )
}

export const RestoreInfoModal = ({ onClose, data }) => {
    return (
        <RestoreInfoBlock restore={data.restore} onClose={onClose} />
    )
}