export const NoChartDataFound = (props) => {
  const text = props.text || "No data available or nothing was deduplicated during the selected period.";
  return (
    <div className="w-full absolute inset-0 flex items-center justify-center bg-white rounded-2xl bg-opacity-75 z-10">
      <div className="text-gray-600 font-medium text-sm text-center px-12">
        {text}
      </div>
    </div>
  )
}
