import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Delete, Get } from "../../helpers/requests";
import { settings } from "../../settings";
import { deleteStorage, loadStorages } from "../../slices/dataSlice";
import { Link, useNavigate } from "react-router-dom";
import { Paths } from "../../helpers/navigationHelper";
import { CircularSpinnerSecondary, FullScreenLoader } from "../../kit/Spinners";
import { TableCell, TableHeadCell } from "../../kit/Table";
import { StorageImg, StorageProviderName } from "../../helpers/domainHelpers";
import { PencilIcon, PlusCircleIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import { WarningWindow } from "../../kit/WarningWindow";
import { classNames } from "../application/ApplicationHeader";
import { useMixedData } from "../../hooks/useMixedData";
import axios from "axios";
import { processAxiosError } from "../util/ErrorBoundary";
import { setModal } from "../../slices/userSlice";
import { Modals } from "../application/modals/ModalsCommon";
import { ErrorPage } from "../../kit/ErrorPage";
import { DummyRow } from "../repositories/VirtualizedRepoTable";
import { LongTableWrapper, LongTextTag } from "../../kit/LongInvisibleInput";
import { BaseTransition } from "../../kit/Transitions";


const StorageRows = ({ ikey, groupName, storages, onDelete, isCustomStorages, deleteBusy, isLoading }) => {
    return (
        <Fragment key={ikey}>
            <BaseTransition>
                <tr className="border-t border-gray-200 w-full">
                    <td
                        colSpan={isCustomStorages && !isLoading ? 1 : 3}
                        scope="colgroup"
                        className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-xs text-gray-900 sm:pl-3"
                    >
                        {groupName}
                    </td>
                    {!isLoading && isCustomStorages && <td
                        colSpan={2}
                        scope="colgroup"
                        className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-xs text-gray-900 sm:pl-3"
                    >
                        <div className="flex justify-end w-full">
                            <Link to={Paths.storages.newStorage.path}
                                className="whitespace-nowrap inline-flex align-middle items-center rounded text-white px-2 py-1 text-xs font-semibold bg-green-600 shadow-sm hover:bg-green-500"
                            >
                                <PlusIcon className="w-4 h-4 mr-1" />
                                Add a new storage
                            </Link>
                        </div>
                    </td>}
                </tr>
            </BaseTransition>
            {isLoading && [1, 2].map(x => <tr key={x}><DummyRow colSpan={3} /></tr>)}
            {!isLoading && isCustomStorages && storages.length === 0 &&
                <BaseTransition>
                    <tr>
                        <td className="text-gray-700" scope="colgroup" colSpan={3}>
                            <div className="flex justify-center py-3">
                                <h3 className="font-normal text-sm">You haven't configured custom storages yet</h3>
                            </div>
                        </td>
                    </tr>
                </BaseTransition>
            }
            {!isLoading && storages.map((storage, id) =>
                <BaseTransition key={id}>
                    <tr className="text-gray-700">
                        <TableCell className={classNames("py-4 pl-4 pr-3 !font-medium")} >
                            <LongTableWrapper>
                                <LongTextTag value={storage.storageName} />
                            </LongTableWrapper>
                        </TableCell>
                        <TableCell className={classNames("font-normal px-3 py-4")} >
                            <div className="whitespace-nowrap items-center flex text-sm !font-medium text-gray-600">
                                <StorageImg className="mr-1 w-5 h-5 min-h-5 min-w-5" alt="storage-img" isCustomStorage={storage.isCustomStorage} storageName={storage.storageName} provider={storage.provider} />
                                {StorageProviderName(storage.provider)}
                            </div>
                        </TableCell>
                        <TableCell className="px-3 py-4">
                            {storage.storageId >= 100 &&
                                <div className="flex float-right">
                                    <Link className="mr-2" to={Paths.storages.editStorage.linkPath(storage.storageId)}
                                        data-tooltip-id="edit-storage-tooltip" data-tooltip-content="Edit Schedule">
                                        <button className="rounded-md bg-gray-600 p-1 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                            data-tooltip-id="edit-storage-tooltip" data-tooltip-content="Edit Storage">
                                            <PencilIcon className="h-5 w-5" />
                                        </button>

                                    </Link>
                                    <Tooltip id="edit-schedule-tooltip" className="z-[6]" />
                                    <button className="rounded-md bg-red-400 p-1 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                        data-tooltip-id="delete-storage-tooltip" data-tooltip-content="Delete Storage"
                                        disabled={deleteBusy}
                                        onClick={() => onDelete(storage.storageId)}>
                                        {deleteBusy ? <CircularSpinnerSecondary light={true} /> : <TrashIcon className="h-5 w-5" />}
                                    </button>
                                    <Tooltip id="delete-storage-tooltip" className="z-[6]" />
                                </div>}
                        </TableCell>
                    </tr>
                </BaseTransition>
            )}
        </Fragment>
    )
}

export const StoragesList = () => {

    const dispatch = useDispatch();

    const [storages, isLoading, pageError, tryAgain] = useMixedData(() => axios.get(settings.backendUrl + "/v2/storages"), (res, dispatch) =>
        dispatch(loadStorages(res.data.result)), state => state.data.storages, 
        undefined, (data) => data !== undefined && data !== null && Object.keys(data).length > 0);
    const [deleteBusy, setDeleteBusy] = useState(false);
    const onDelete = (id) => {
        const storage = storages[id];
        if (storage !== undefined && storage !== null) {
            setDeleteBusy(true);
            axios.get(settings.backendUrl + "/v2/storages/delete?storageId=" + storage.storageId)
                .then(res => {
                    if (res.data.isSuccess) {
                        dispatch(deleteStorage(storage));
                    }
                    else {
                        dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to delete", text: res.data.errorDescription }));
                    }
                })
                .catch((e) => {
                    processAxiosError(e);
                    dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to delete", text: "The storage couldn't be deleted. Please try again later." }));
                })
                .finally(() => setDeleteBusy(false));
        }
    };

    const sortedStorages = useMemo(() => Object.values(storages).sort((x, y) => y.storageId - x.storageId), [storages]);
    return pageError !== null ? <ErrorPage canTryAgain={true} tryAgainAction={tryAgain} /> : (
        <div className="w-full bg-gray-100">
            <div className="w-full px-3 mx-auto max-w-xl md:max-w-4xl mt-2 pb-6">
                <table className="border-none min-w-full divide-y divide-gray-300  shadow ring-1 ring-black ring-opacity-5 rounded-t-lg">
                    <thead className="bg-gray-50">
                        <tr>
                            <TableHeadCell stickyPage={true} className="whitespace-nowrap py-3 pl-4 pr-3 rounded-tl-lg" >Storage name</TableHeadCell>
                            <TableHeadCell stickyPage={true} className="whitespace-nowrap px-3 py-3.5">Storage Provider</TableHeadCell>
                            <TableHeadCell stickyPage={true} className="px-3 py-3.5 rounded-tr-lg" ></TableHeadCell>
                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        <StorageRows ikey="custom"
                            isLoading={isLoading}
                            isCustomStorages={true}
                            onDelete={onDelete} deleteBusy={deleteBusy} groupName="Customer managed storages"
                            storages={sortedStorages.filter(x => x.storageId >= 100)} />
                        <StorageRows ikey="custom" isLoading={isLoading} onDelete={onDelete} groupName="System storages"
                            storages={sortedStorages.filter(x => x.storageId < 100)} />
                    </tbody>
                </table>
            </div>
        </div>
    )
}