import { forwardRef, memo, useEffect, useMemo, useState } from "react";
import { ErrorPage } from "../../kit/ErrorPage";
import { TableVirtuoso } from "react-virtuoso";
import { BaseTransition } from "../../kit/Transitions";
import { classNames } from "../application/ApplicationHeader";
import { COMPONENTS, VirtualizedDummyRow } from "../repositories/VirtualizedRepoTable";
import { TableCell, TableHeadCell } from "../../kit/Table";
import { DateRangePicker } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';
import { LoadingAutocomplete, useAutocomplete } from "../../kit/Autocomplete";
import { getGitHubAvatarUrl, settings } from "../../settings";
import { useMixedData } from "../../hooks/useMixedData";
import axios from "axios";
import { loadDashboard } from "../../slices/dataSlice";
import { SelectBox } from "../../kit/SelectBox";
import { ButtonType, FormButton } from "../../kit/Buttons";
import { ArrowPathIcon, DocumentArrowDownIcon, ExclamationCircleIcon, MagnifyingGlassCircleIcon, MagnifyingGlassIcon, NoSymbolIcon } from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { setModal } from "../../slices/userSlice";
import { Modals } from "../application/modals/ModalsCommon";
import { processAxiosError } from "../util/ErrorBoundary";
import { CircularSpinnerSecondary, FullScreenLoader } from "../../kit/Spinners";
import { EmptyRepoState } from "../repositories/EmptyRepoState";
import { capitalizeFirstLetter } from "../../helpers/internalFunctions";
import { MultiCascader } from 'rsuite';
import 'rsuite/MultiCascader/styles/index.css';
import './audit.css';
import { LongTableWrapper, LongTextTag } from "../../kit/LongInvisibleInput";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
const AuditHeaderInner = ({ isLoading }) => {
    return (
        <tr>
            <TableHeadCell sticky={true} className="text-sm !py-0.5 whitespace-nowrap"></TableHeadCell>
            <TableHeadCell sticky={true} className="text-sm !py-0.5 whitespace-nowrap">Time</TableHeadCell>
            <TableHeadCell sticky={true} className="text-sm !py-0.5 whitespace-nowrap">Action</TableHeadCell>
            <TableHeadCell sticky={true} className="text-sm !py-0.5 whitespace-nowrap">Description</TableHeadCell>
            <TableHeadCell sticky={true} className="text-sm !py-0.5 whitespace-nowrap">Account</TableHeadCell>
            <TableHeadCell sticky={true} className="text-sm !py-0.5 whitespace-nowrap">User</TableHeadCell>
            <TableHeadCell sticky={true} className="text-sm !py-0.5 whitespace-nowrap">IP</TableHeadCell>
            <TableHeadCell sticky={true} className="text-sm !py-0.5 whitespace-nowrap">Device</TableHeadCell>
            <TableHeadCell sticky={true} className="text-sm !py-0.5 whitespace-nowrap">Location</TableHeadCell>
            <TableHeadCell sticky={true} className="text-sm !py-0.5 whitespace-nowrap">Error</TableHeadCell>
        </tr>
    );
};
export function intToIP(int) {
    if (int === undefined || int === null) return "";
    var part1 = int & 255;
    var part2 = (int >> 8) & 255;
    var part3 = (int >> 16) & 255;
    var part4 = (int >> 24) & 255;
    return part4 + "." + part3 + "." + part2 + "." + part1;
}

const AuditRowContentWrapper = ({ children, className }) => {
    return (
        <TableCell className={classNames("border-b border-gray-200 !text-xs", className)}>
            <LongTableWrapper className="!min-w-0">
                <LongTextTag className="font-medium text-gray-600" value={children} />
            </LongTableWrapper>
        </TableCell>
    )
}

const RowInner = (data) => {
    const dispatch = useDispatch();
    return (
        <>
            <TableCell className="border-b border-gray-200 !text-xs w-[30px] min-w-[30px] !pl-0">
                <div className="inline-flex gap-1 items-center align-middle">
                    <FormButton
                        buttonType="Cancel"
                        className="!p-0.5"
                        onClick={() => dispatch(setModal({ id: Modals.AuditLogInfoModal, ...data }))}
                        leftIcon={<MagnifyingGlassIcon className="h-4 w-4" />}
                    />
                    {data.error !== null && data.error !== undefined && <ExclamationTriangleIcon className="h-4 w-4 text-yellow-500" />}
                </div>
            </TableCell>
            <AuditRowContentWrapper className="w-[170px] min-w-[170px]">{new Date(data.timestamp).toLocaleString()}</AuditRowContentWrapper>
            <AuditRowContentWrapper className="w-[170px] min-w-[170px]">{capitalizeFirstLetter(data.actionType)}</AuditRowContentWrapper>
            <AuditRowContentWrapper className="w-[400px] min-w-[400px]">{data.formattedMessage}</AuditRowContentWrapper>
            <AuditRowContentWrapper className="w-[100px] min-w-[100px]">{data.linkedAccount?.linkedAccountId}</AuditRowContentWrapper>
            <AuditRowContentWrapper className="w-[100px] min-w-[100px]">{data.userInfo?.userSettings?.name}</AuditRowContentWrapper>
            <AuditRowContentWrapper className="w-[120px] min-w-[120px]">{intToIP(data.userInfo?.userIp)}</AuditRowContentWrapper>
            <AuditRowContentWrapper className="w-[100px] min-w-[100px] border-b border-gray-200 !text-xs">{data.userInfo?.userDevice?.device}</AuditRowContentWrapper>
            <AuditRowContentWrapper className="w-[130px] min-w-[130px] border-b border-gray-200 !text-xs">{data.userInfo?.userLocation?.location}</AuditRowContentWrapper>
            <AuditRowContentWrapper className="w-[300px] min-w-[300px]">{data.error !== null && data.error !== undefined &&
                `${data.error?.errorCode}: ${data.error?.errorMessage}`}</AuditRowContentWrapper>
        </>
    )
}

const SearchInput = ({ onSearchEnter, searchTerm, setSearchTerm }) => {
    return (
        <div className="w-full sm:w-max min-w-[150px]">
            <div className="flex flex-col justify-end">
                <label className="block text-sm font-medium leading-6 text-gray-900 mb-1">Search term</label>
                <div className="relative flex flex-1">
                    <MagnifyingGlassIcon
                        className="pointer-events-none absolute inset-y-0 left-0 h-full ml-2 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                    <input
                        id="search-field"
                        onKeyDown={(e) => { if (e.key === "Enter") onSearchEnter() }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="block w-full rounded-md pl-8 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                        placeholder="Search..."
                        type="search"
                        name="search"
                    />
                </div>
            </div>
        </div>
    )
}

const getRanges = () => {
    let result = [];
    let today = new Date();
    today.setHours(23, 59, 59, 999);
    let effectivePastDate = new Date();
    effectivePastDate.setDate(today.getDate() - 1);

    result.push({
        label: 'Last 24h',
        value: [
            new Date(effectivePastDate.getTime()),
            today
        ]
    });
    effectivePastDate.setDate(today.getDate() - 3);
    result.push({
        label: 'Last 3 days',
        value: [
            new Date(effectivePastDate.getTime()),
            today
        ]
    });

    effectivePastDate.setDate(today.getDate() - 7);
    result.push({
        label: 'Last 7 days',
        value: [
            new Date(effectivePastDate.getTime()),
            today
        ]
    });

    effectivePastDate.setDate(today.getDate() - 30);
    result.push({
        label: 'Last 30 days',
        value: [
            new Date(effectivePastDate.getTime()),
            today
        ]
    });

    return result;
};

const AccountSelector = ({ account, setAccount, setCustomPageError }) => {

    const [accountNames, setAccountNames] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageError, setPageError] = useState(null);
    const loadAccounts = () => {
        setIsLoading(true);
        axios.get(settings.backendUrl + "/v2/accounts/getNames")
            .then(res => {
                if (res.data.isSuccess) {
                    setAccountNames(res.data.result);
                }
                else {
                    setPageError("Failed to load accounts");
                }
            })
            .catch(e => {
                processAxiosError(e);
                setPageError("Failed to load accounts");
            })
            .finally(() => setIsLoading(false));
    }
    useEffect(() => {
        loadAccounts();
    }, [])

    const accountNamesDict = useMemo(() => accountNames.reduce((acc, v) => { acc[v] = v; return acc; }, {}), [accountNames]);

    const autocomplete = useAutocomplete(accountNamesDict, isLoading, undefined, account, setAccount);
    return (
        <div className="w-full sm:w-max min-w-[150px]">
            <LoadingAutocomplete isLoading={autocomplete.isLoading}
                autocomplete={{
                    ...autocomplete,
                    label: "Account",
                    labelSelector: (option) => option,
                    keySelector: (option) => option,
                    optionLeadingContentSelector: (option) => <img
                        className="h-5 w-5 rounded-md"
                        src={getGitHubAvatarUrl(option)}
                        alt=""
                    />,
                }}
            />
        </div>
    )
}

const AuditDateSelector = ({ dates, setDates }) => {
    return (
        <div className="sm:w-max w-full max-w-[350px] min-w-[350px]">
            <div className="flex flex-col justify-end">
                <label className="block text-sm font-medium leading-6 text-gray-900 mb-1">Time range</label>
                <DateRangePicker
                    className="cursor-default w-full shadow-sm rounded-md"
                    editable={false}
                    value={dates}
                    size="md"
                    onChange={setDates}
                    format="yyyy-MM-dd HH:mm:ss"
                    ranges={getRanges()}
                    cleanable={false}
                />
            </div>
        </div>
    )
}

const LogTypeSelector = ({ onClose, selectedLogTypes, setSelectedLogTypes, tableLoading }) => {


    return (
        <div className="sm:w-max w-full min-w-[200px] sm:max-w-[300px]">
            <div className="flex flex-col justify-end">
                <label className="block text-sm font-medium leading-6 text-gray-900 mb-1">Actions</label>
                <MultiCascader
                    className="w-full !font-normal"
                    value={selectedLogTypes}
                    onChange={setSelectedLogTypes}
                    data={reducedLogTypes}
                    onClose={onClose}
                    preventOverflow={false}
                    placeholder="All"
                    countable={false}
                    renderExtraFooter={() => <div className="w-full px-3 pt-1">
                        <div className="inline-flex items-center align-middle">
                            <input type="checkbox" className="form-checkbox h-4 w-4 rounded border-gray-300 hover:bg-gray-300 hover:text-gray-300 text-gray-600 mr-1"
                                checked={selectedLogTypes.length === logTypes.length}
                                onChange={(e) => setSelectedLogTypes(e.target.checked ? plainLogTypes : [])}
                            />
                            <p>All</p>
                        </div>
                    </div>}
                    cleanable={false} />
            </div>
        </div>
    )
}

const logTypes = [
    { id: "Login", name: "Login" },
    { id: "Logout", name: "Logout" },
    { id: "AuditLogExported", name: "AuditLogExported" },
    { id: "UserSettingsUpdated", name: "UserSettingsUpdated", group: "Settings" },
    { id: "UserSettingsDeleted", name: "UserSettingsDeleted", group: "Settings" },
    { id: "InstantNotificationSent", name: "InstantNotificationSent", group: "Notifications" },
    { id: "EmailNotificationSent", name: "EmailNotificationSent", group: "Notifications" },
    { id: "StorageCreated", name: "StorageCreated", group: "Storage" },
    { id: "StorageUpdated", name: "StorageUpdated", group: "Storage" },
    { id: "StorageDeleted", name: "StorageDeleted", group: "Storage" },
    { id: "ScheduleCreated", name: "ScheduleCreated", group: "Schedule" },
    { id: "ScheduleUpdated", name: "ScheduleUpdated", group: "Schedule" },
    { id: "ScheduleDeleted", name: "ScheduleDeleted", group: "Schedule" },
    { id: "RetentionPolicyCreated", name: "RetentionPolicyCreated", group: "Retention" },
    { id: "RetentionPolicyUpdated", name: "RetentionPolicyUpdated", group: "Retention" },
    { id: "RetentionPolicyDeleted", name: "RetentionPolicyDeleted", group: "Retention" },
    { id: "BackupTriggered", name: "BackupTriggered", group: "Backup" },
    { id: "BackupCompleted", name: "BackupCompleted", group: "Backup" },
    { id: "BackupDeleted", name: "BackupDeleted", group: "Backup" },
    { id: "BackupDownloaded", name: "BackupDownloaded", group: "Backup" },
    { id: "RestoreTriggered", name: "RestoreTriggered", group: "Restore" },
    { id: "RestoreCompleted", name: "RestoreCompleted", group: "Restore" },
    { id: "AccountCreated", name: "AccountCreated", group: "Account" },
    { id: "AccountDeleted", name: "AccountDeleted", group: "Account" },
    { id: "AccountRenamed", name: "AccountRenamed", group: "Account" },
    { id: "AccountSettingsUpdated", name: "AccountSettingsUpdated", group: "Account" },
    { id: "InstallationCreated", name: "InstallationCreated", group: "Installation" },
    { id: "InstallationSuspended", name: "InstallationSuspended", group: "Installation" },
    { id: "InstallationUnsuspended", name: "InstallationUnsuspended", group: "Installation" },
    { id: "InstallationDeleted", name: "InstallationDeleted", group: "Installation" },
    { id: "InstallationNewPermissionsAccepted", name: "InstallationNewPermissionsAccepted", group: "Installation" },
    { id: "PurchaseCreated", name: "PurchaseCreated", group: "Purchase" },
    { id: "PurchaseChanged", name: "PurchaseChanged", group: "Purchase" },
    { id: "PurchaseCancelled", name: "PurchaseCancelled", group: "Purchase" },
    { id: "RepositoryAdded", name: "RepositoryAdded", group: "Repository" },
    { id: "RepositoryUpdated", name: "RepositoryUpdated", group: "Repository" },
    { id: "RepositoryPublicized", name: "RepositoryPublicized", group: "Repository" },
    { id: "RepositoryPrivatized", name: "RepositoryPrivatized", group: "Repository" },
    { id: "RepositoryArchived", name: "RepositoryArchived", group: "Repository" },
    { id: "RepositoryUnarchived", name: "RepositoryUnarchived", group: "Repository" },
    { id: "RepositoryRenamed", name: "RepositoryRenamed", group: "Repository" },
    { id: "RepositoryRemoved", name: "RepositoryRemoved", group: "Repository" },
]

const plainLogTypes = logTypes.map(log => log.id);

const reducedLogTypes = logTypes.reduce((acc, { group, subGroup, ...rest }) => {
    if (group === undefined) {
        acc.push({
            value: rest.id,
            label: rest.name
        });
        return acc;
    }

    let groupObj = acc.find(g => g.value === group);
    if (!groupObj) {
        groupObj = { value: group, label: group, children: [] };
        acc.push(groupObj);
    }

    if (subGroup !== undefined) {
        let subGroupObj = groupObj.children.find(sg => sg.value === subGroup);
        if (!subGroupObj) {
            subGroupObj = { value: subGroup, label: subGroup, children: [] };
            groupObj.children.push(subGroupObj);
        }
        subGroupObj.children.push({
            value: rest.id,
            label: rest.name
        });
    }
    else {
        groupObj.children.push({
            value: rest.id,
            label: rest.name
        });
    }

    return acc;
}, []);

const transformSelectedIntoPlainList = (selected) => {
    if (selected.length === 0) return [];
    if (selected.length === logTypes.length) return [];
    //Selected is plain list of group name, subgroup name or item name
    //Get plain list of items, including child items of groups and subgroups, but do not include groups and subgroups
    let result = [];
    selected.forEach(item => {
        const logType = logTypes.find(log => log.id === item || log.group === item || log.subGroup === item);
        if (logType) {
            if (logType.subGroup === item) {
                const subGroup = logTypes.filter(log => log.subGroup === item);
                result.push(...subGroup.map(log => log.id));
            } else if (logType.group === item) {
                const group = logTypes.filter(log => log.group === item);
                result.push(...group.map(log => log.id));
            } else {
                result.push(logType.id);
            }
        }
    });
    return result;
}

const AuditLogPageHeader = ({ isTableLoading, onSubmit, setCustomPageError }) => {

    const [dates, setDates] = useState(getRanges()[0].value);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedLogTypes, setSelectedLogTypes] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const onSubmitInner = () => {
        onSubmit({
            accountNames: [selectedAccount],
            actionTypes: transformSelectedIntoPlainList(selectedLogTypes),
            dateFrom: dates[0],
            dateTo: dates[1],
            searchTerm: searchTerm
        });
    }

    const onSearchEnter = () => {
        if (selectedAccount === null) {
            return;
        }
        onSubmitInner();
    }

    const onSetSearchTerm = (term) => {
        if (selectedAccount === null) {
            setSearchTerm(term);
            return;
        }
        if (term === "") {
            onSubmit({
                accountNames: [selectedAccount],
                actionTypes: transformSelectedIntoPlainList(selectedLogTypes),
                dateFrom: dates[0],
                dateTo: dates[1],
                searchTerm: term
            });
        }
        setSearchTerm(term);
    }

    const onLogTypeClose = () => {
        if (selectedAccount === null) {
            return;
        }
        onSubmit({
            accountNames: [selectedAccount],
            actionTypes: transformSelectedIntoPlainList(selectedLogTypes),
            dateFrom: dates[0],
            dateTo: dates[1],
            searchTerm: searchTerm
        });
    }

    const onDatesSelected = (dates) => {
        if (selectedAccount === null) {
            setDates(dates);
            return;
        }
        onSubmit({
            accountNames: [selectedAccount],
            actionTypes: transformSelectedIntoPlainList(selectedLogTypes),
            dateFrom: dates[0],
            dateTo: dates[1],
            searchTerm: searchTerm
        });
        setDates(dates);
    }

    const onAccountSelected = (account) => {
        if (account === null) {
            return;
        }
        onSubmit({
            accountNames: [account],
            actionTypes: transformSelectedIntoPlainList(selectedLogTypes),
            dateFrom: dates[0],
            dateTo: dates[1],
            searchTerm: searchTerm
        });
        setSelectedAccount(account);
    }
    const [exportBusy, setExportBusy] = useState(false);
    const dispatch = useDispatch();
    const onCheckExportAsync = async (requestId) => {
        return await axios.post(settings.backendUrl + "/v2/auditLog/check?requestId=" + requestId);
    }
    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }
    const onExport = async () => {
        setExportBusy(true);
        try {
            let res1 = await
                axios.post(settings.backendUrl + "/v2/auditLog/init", {
                    accountNames: [selectedAccount],
                    actionTypes: transformSelectedIntoPlainList(selectedLogTypes),
                    dateFrom: dates[0],
                    dateTo: dates[1],
                    searchTerm: searchTerm
                });
            if (res1.status !== 200) {
                dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unexpected error", text: "Something went wrong" }))
                setExportBusy(false);
                return;
            }

            while (true) {
                let res = await onCheckExportAsync(res1.data.requestId);
                if (res.status === 200) {
                    if (res.data.errorMessage !== null && res.data.errorMessage !== undefined) {
                        dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unexpected error", text: "Something went wrong" }))
                        setExportBusy(false);
                        return;
                    }
                    else if (res.data.isCompleted) {
                        break;
                    }
                }
                else {
                    dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unexpected error", text: "Something went wrong" }))
                    setExportBusy(false);
                    return;
                }
                await delay(1000);
            }
            window.open(settings.backendUrl + "/v2/auditLog/download?requestId=" + res1.data.requestId, '_blank').focus();
            setExportBusy(false);
        }
        catch (e) {
            processAxiosError(e);
            dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unexpected error", text: "Something went wrong" }))
        }
    }

    return (
        <div className="w-full flex flex-col sm:flex-row flex-nowrap gap-2 justify-between py-2 items-end">
            <div className="w-full flex flex-col flex-wrap sm:flex-row gap-2 items-end">
                <AuditDateSelector dates={dates} setDates={onDatesSelected} />
                <LogTypeSelector selectedLogTypes={selectedLogTypes} onClose={onLogTypeClose} setSelectedLogTypes={setSelectedLogTypes} tableLoading={isTableLoading} />
                <AccountSelector account={selectedAccount} setAccount={onAccountSelected} setCustomPageError={setCustomPageError} />
                <SearchInput searchTerm={searchTerm} onSearchEnter={onSearchEnter} setSearchTerm={onSetSearchTerm} />
            </div>
            <FormButton
                className="!ml-0"
                buttonType={ButtonType.Secondary}
                btnText={"Refresh"}
                leftIcon={<ArrowPathIcon className="h-5 w-5 mr-1" />}
                onClick={onSubmitInner}
                isLoading={isTableLoading}
                busy={isTableLoading}
                disabled={isTableLoading || selectedAccount === null} />
            <FormButton
                className="!ml-0"
                buttonType={ButtonType.Secondary}
                btnText={"Export to CSV"}
                leftIcon={<DocumentArrowDownIcon className="h-5 w-5 mr-1" />}
                onClick={onExport}
                isLoading={exportBusy || isTableLoading}
                busy={exportBusy}
                disabled={exportBusy || isTableLoading || selectedAccount === null} />
        </div>
    )
}

export const AuditLog = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [pageError, setPageError] = useState(null);
    const [contToken, setContToken] = useState(null);
    const [isEnd, setIsEnd] = useState(false);
    const [records, setRecords] = useState([]);
    const [currentParams, setCurrentParams] = useState(null);
    const [isLoadingCont, setIsLoadingCont] = useState(false);
    const dispatch = useDispatch();
    const onSubmit = (requestParams, continuation = false) => {
        if (requestParams === null) return;
        var oldParams = currentParams;
        if (!continuation) setIsLoading(true)
        else setIsLoadingCont(true);
        setCurrentParams(requestParams);
        axios.post(settings.backendUrl + "/v2/auditLog/get", {
            ...requestParams,
            continuationToken: contToken
        })
            .then(res => {
                if (res.status === 200) {
                    setIsEnd(res.data.continuationToken === null);
                    setContToken(res.data.continuationToken);
                    if (oldParams !== requestParams) {
                        setRecords(res.data.entries);
                    }
                    else {
                        setRecords(r => [...r, ...res.data.entries]);
                    }
                }
                else {
                    dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unexpected error", text: "Something went wrong" }))
                }
            })
            .catch(e => {
                processAxiosError(e);
                dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unexpected error", text: "Something went wrong" }))
            })
            .finally(() => {
                setIsLoading(false);
                setIsLoadingCont(false);
            })
    }
    return pageError !== null ? <ErrorPage /> : (
        <div className="px-3 pt-2 flex flex-col w-full h-full pb-3">
            <AuditLogPageHeader isTableLoading={isLoading} onSubmit={onSubmit} setCustomPageError={setPageError} />
            <div className="w-full flex-1">
                {!isLoading && records.length === 0 ?
                    <div className="w-full pt-5 flex flex-1 justify-center items-center border-t border-gray-200">
                        <div className="flex flex-col gap-2">
                            <p className="text-sm text-gray-500">No records</p>
                        </div>
                    </div> :
                    <>
                        <TableVirtuoso
                            useWindowScroll={false}
                            style={{ height: "100%" }}
                            data={isLoading ? [1, 2, 3, 4] : records}
                            overscan={{ main: 50, reverse: 50 }}
                            increaseViewportBy={{ bottom: 2, top: 2 }}
                            defaultItemHeight={30}
                            components={{
                                ...COMPONENTS,
                                TableRow: (props) => {
                                    return (
                                        <BaseTransition>
                                            <tr {...props} className="h-[30px] max-h-[30px] whitespace-nowrap" />
                                        </BaseTransition>)
                                },
                            }}
                            //initialItemCount={20} - enable this to let table draw 20 rows at the start instead of 1, but it will blink a bit
                            computeItemKey={(index, data) => { return isLoading ? index : index + data.timestamp.toString() }}
                            fixedHeaderContent={() => (
                                <AuditHeaderInner isLoading={isLoading} />
                            )}
                            itemContent={(index, data) => isLoading ? <VirtualizedDummyRow firstColCheckbox={false} colSpan={9} /> : <RowInner {...data} />}
                            endReached={() => { if (records.length > 0 && !isEnd) onSubmit(currentParams, true) }}
                            fixedFooterContent={() => (
                                <tr>
                                    {!isEnd && isLoadingCont && <td colSpan={9} className="py-2">
                                        <div className="w-full  inline-flex justify-center">
                                            <div className="w-8 rounded-md shadow-sm bg-gray-200 px-10 py-1">
                                                <CircularSpinnerSecondary size="sm" />
                                            </div>
                                        </div>
                                    </td>}
                                </tr>
                            )}
                        />
                    </>
                }
            </div>
        </div>
    )
}