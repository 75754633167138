import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { StorageImg, getBackupStatus, selectCard } from "../../helpers/domainHelpers";
import { SettingsWindow } from "./RepoSettings";
import axios from "axios";
import { settings } from "../../settings";
import { useEffect, useMemo, useState } from "react";
import { CircularSpinnerSecondary, FullScreenLoader } from "../../kit/Spinners";
import { updateCard } from "../../slices/dataSlice";
import { RepositoryDetailsHead } from "./RepositoryDetailsHead";
import { ArchiveBoxIcon, ArrowUpOnSquareStackIcon, ArrowsPointingInIcon, CalendarIcon, ClockIcon, Cog6ToothIcon, CurrencyDollarIcon, DocumentDuplicateIcon, InformationCircleIcon, RectangleStackIcon } from "@heroicons/react/24/outline";
import { RepositoryBackups } from "./RepositoryBackups";
import { RepositoryRestores } from "./RepositoryRestores";
import { CheckIcon, CommentIcon, DesktopDownloadIcon, GitPullRequestIcon, IssueOpenedIcon, MilestoneIcon, RepoIcon, RepoPushIcon, TableIcon, TagIcon } from "@primer/octicons-react";
import { DeduplicationDonut } from "./charts/DeduplicationDonut";
import { DeduplicationSavings } from "./charts/DeduplicationSavings";
import { BackupStatus, ErrorCode, ScheduleStatus } from "../../model/domain";
import { BackupSizeChart, BackupTimeChart } from "./charts/BackupCharts";
import { ErrorAlert } from "../../kit/Alerts";
import { useMixedData } from "../../hooks/useMixedData";
import { DashboardCard, StatLabel, Statement } from "../../kit/DashboardCard";
import { classNames } from "../application/ApplicationHeader";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { ErrorPage } from "../../kit/ErrorPage";
import { processAxiosError } from "../util/ErrorBoundary";
import { SelectBox } from "../../kit/SelectBox";
import { FormButton } from "../../kit/Buttons";
import { closeModal, setModal } from "../../slices/userSlice";
import { Modals } from "../application/modals/ModalsCommon";
import { StorageProviderName } from "../../helpers/domainHelpers";


const selectCorrectBackupStatsSize = (stats) => {
  var maxSize = stats.archiveSizeBytes.reduce((a, b) => Math.max(a || 0, b || 0));
  var maxElapsed = stats.elapsedTimeSec.reduce((a, b) => Math.max(a || 0, b || 0));
  var sizeMultiplier = 1 / 1024;
  var size = " kb";
  if (maxSize * sizeMultiplier > 1024 * 1024) {
    size = " Gb";
    sizeMultiplier = 1 / (1024 * 1024 * 1024);
  }
  else if (maxSize * sizeMultiplier > 1024) {
    size = " Mb";
    sizeMultiplier = 1 / (1024 * 1024);
  }

  var timeMultiplier = 1;
  var time = " s";
  if (maxElapsed * timeMultiplier > 60 * 60) {
    time = " h";
    timeMultiplier = 1 / (60 * 60);
  } else if (maxElapsed * timeMultiplier > 60) {
    time = " m";
    timeMultiplier = 1 / (60);
  }

  return { sizes: stats.archiveSizeBytes.map(x => (x * sizeMultiplier).toFixed(2)), times: stats.elapsedTimeSec.map(x => (x * timeMultiplier).toFixed(2)), size, time };
}

export const BackupStatsBlock = ({ defId }) => {

  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState({
    dates: [],
    elapsedTimeSec: [],
    archiveSizeBytes: [],
    size: " kb",
    time: " s"
  });
  const [error, setError] = useState(null);
  const [period, setPeriod] = useState(periodOptions[1]);
  const loadStats = (localPeriod) => {
    setIsLoading(true);
    setError(null);
    axios.get(settings.backendUrl + "/v2/backupStats?period=" + localPeriod.id + "&definitionId=" + defId)
      .then(res => {
        if (res.data.isSuccess) {
          var correctSizes = selectCorrectBackupStatsSize(res.data.result);
          setStats({
            ...res.data.result,
            elapsedTimeSec: correctSizes.times,
            archiveSizeBytes: correctSizes.sizes,
            size: correctSizes.size,
            time: correctSizes.time,
            dates: res.data.result.dates.map(x => new Date(x).toLocaleString('default', { month: 'short' }) + " " + new Date(x).getDate())
          });
        }
        else {
          setError(res.data.errorDescription);
        }
      }
      ).catch(err => {
        processAxiosError(err);
        setError("Something went wrong");
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    if (defId !== null && defId !== undefined && defId !== 0) {
      loadStats(period);
    }
  }, [defId, period])

  return (
    <DashboardCard title="Statistics" rightContent={
      <div>
        <SelectBox
          options={periodOptions}
          keySelector={x => x.id}
          labelSelector={x => x.name}
          selected={period}
          setSelected={setPeriod}
          leftContentSelector={x => isLoading ? <CircularSpinnerSecondary /> : null}
        />
      </div>
    }>
      {error !== null ? <ErrorPage canTryAgain={true} tryAgainAction={loadStats} /> :
        <div className="flex flex-row gap-2">
          <div className="flex-1">
            <BackupTimeChart stats={stats} />
          </div>
          <div className="flex-1">
            <BackupSizeChart stats={stats} />
          </div>
        </div>}
    </DashboardCard>
  )
}

export const BackupInfoBlock = ({ title, owner, repoId, repo, backup, onClose, singleColumn = true, className }) => {
  const backupStatus = useMemo(() =>
    getBackupStatus(backup, false)
    , [backup?.status])

  const dispatch = useDispatch();
  const alive = backup && backup?.status !== BackupStatus.failed && backup?.status !== BackupStatus.deleted;
  const isSingleColumn = singleColumn || !alive;

  return (
    <DashboardCard className={className} title={title || "Backup details"} isLoading={repoId === undefined || backup === undefined}>
      {backup && backup?.status === BackupStatus.failed &&
        <div className="rounded-md bg-red-50 p-2 mb-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Error during backup:</h3>
              <div className="mt-2 text-sm text-red-700">
                <p>{backup.errorDescription}</p>
              </div>
            </div>
          </div>
        </div>
      }
      <div className={classNames(isSingleColumn ? "flex flex-col gap-1" : "flex flex-col sm:flex-row gap-3")}>
        <div className="flex flex-col gap-1 flex-1 overflow-hidden">
          <div className="flex flex-row items-center align-middle my-2">
            <p className="font-semibold text-gray-500">General information</p>
          </div>
          <div className="flex flex-row items-center align-middle py-1 w-full border-b border-gray-100/2">
            <CheckIcon className="h-5 w-5 mr-1" size="medium" verticalAlign="middle" />
            <div className="flex flex-row justify-between w-full items-center">
              <StatLabel title={"Status"} />
              <p className={classNames("inline-flex items-center rounded-md px-2 py-0.5 text-sm font-medium tracking-tight", backupStatus.Style, "!ring-none")}>
                {backupStatus.Text}
              </p>
            </div>
          </div>
          <Statement title="Start time" value={backup?.startTimeText} Icon={CalendarIcon} />
          <Statement title="Elapsed time" value={backup?.meta.elapsedText} Icon={ClockIcon} />
          <Statement title="Archive size" value={backup?.compressedSizeText} Icon={ArchiveBoxIcon} />
          <Statement title="Deduplicated" value={backup?.meta.isDeduplicated ? "Yes" : "No"} Icon={DocumentDuplicateIcon} />
          <Statement title="Storage" value={backup?.storageName}
            Icon={({ className }) => <StorageImg isCustomStorage={backup?.isCustomStorage} storageName={backup?.storageName} provider={backup?.provider} className={className} />} />
          <Statement title="Repository" valueLink={`https://github.com/${owner}/${repo}`} value={`${owner}/${repo}`}
            Icon={RepoIcon} isLast={true} />
        </div>
        {!isSingleColumn && <div className="flex ring-1 ring-gray-300">
        </div>}
        <div className="flex flex-col gap-1">
          {backup && backup?.status !== BackupStatus.failed && backup?.status !== BackupStatus.deleted &&
            <>
              <div className="flex flex-row items-center align-middle my-2">
                <p className="font-semibold text-gray-500">Collected metadata</p>
              </div>
              <Statement title="Projects" value={backup?.meta.numberOfProjects} Icon={TableIcon} />
              <Statement title="Pull requests" value={backup?.meta.numberOfPullRequests} Icon={GitPullRequestIcon} />
              <Statement title="Releases" value={backup?.meta.numberOfReleases} Icon={TagIcon} />
              <Statement title="Issues" value={backup?.meta.numberOfIssues} Icon={IssueOpenedIcon} />
              <Statement title="Comments" value={backup?.meta.numberOfIssueComments} Icon={CommentIcon} />
              <Statement title="Labels" value={backup?.meta.numberOfLabels} Icon={TagIcon} />
              <Statement title="Milestones" value={backup?.meta.numberOfMilestones} Icon={MilestoneIcon} isLast={true} />
            </>
          }
        </div>
      </div>
      <div className="mt-5 sm:mt-5 sm:flex sm:flex-row-reverse">
        {onClose && <button type="button"
          className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:w-auto"
          onClick={onClose}>
          Close
        </button>}
        {backup && backup.status === BackupStatus.succeeded &&
          <Link to={`/restore/${owner}/${repoId}/${backup.backupId}`} 
          onClick={()=>dispatch(closeModal())}
          className="mt-3  inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500  sm:mt-0  sm:w-auto"
          > <RepoPushIcon className="h-5 w-5 !block mr-1" verticalAlign="middle" size="medium" alt="restore-icon" />Restore</Link>
        }
        {backup && backup.status === BackupStatus.succeeded &&
          <FormButton btnText="Download" className="sm:ml-0 sm:mr-3"
            onClick={() => dispatch(setModal({
              id: Modals.DownloadBackupModal,
              backupId: backup?.backupId,
              storageProviderName: StorageProviderName(backup.provider)
            }))}
            leftIcon={<DesktopDownloadIcon className="h-5 w-5 mr-1" />} />
        }

      </div>
    </DashboardCard>
  )
}

const selectCorrectDedupSize = (stats) => {
  var total = stats.accumulatedSavingsBytes.slice(-1);
  var multiplier = 1 / 1024;
  var size = " kB";
  if (total * multiplier > 1024 * 1024) {
    size = " GB";
    multiplier = 1 / (1024 * 1024 * 1024);
  }
  if (total * multiplier > 1024) {
    size = " MB";
    multiplier = 1 / (1024 * 1024);
  }
  return { multiplier, size };
}

const periodOptions = [
  { id: 1, name: "Last Week" },
  { id: 2, name: "Last Month" },
  { id: 3, name: "Last 3 Months" },
  { id: 4, name: "Last 6 Months" },
  { id: 5, name: "Last Year" },
]

const DeduplicationInfoBlock = ({ defId }) => {

  const [deduplicationStats, setDeduplicationStats] = useState({
    deduplicatedCount: 0,
    totalCount: 0,
    deduplicatedBytes: 0,
    accumulatedSavingsBytes: [],
    dailySavingsBytes: [],
    size: " Kb",
    totalBytes: 0,
    dates: []
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [period, setPeriod] = useState(periodOptions[1]);

  const loadStats = (localPeriod) => {
    setIsLoading(true);
    setError(null);
    axios.get(settings.backendUrl + "/v2/deduplicationStats?period=" + localPeriod.id + "&definitionId=" + defId)
      .then(res => {
        if (res.data.isSuccess) {
          var dedupSizes = selectCorrectDedupSize(res.data.result);
          setDeduplicationStats({
            ...res.data.result,
            accumulatedSavingsBytes: res.data.result.accumulatedSavingsBytes.map(x => (x * dedupSizes.multiplier).toFixed(2)),
            dailySavingsBytes: res.data.result.dailySavingsBytes.map(x => (x * dedupSizes.multiplier).toFixed(2)),
            size: dedupSizes.size,
            deduplicatedBytes: (res.data.result.deduplicatedBytes * dedupSizes.multiplier).toFixed(2),
            totalBytes: (res.data.result.totalBytes * dedupSizes.multiplier).toFixed(2),
            dates: res.data.result.dates.map(x => new Date(x).toLocaleString('default', { month: 'short' }) + " " + new Date(x).getDate())
          });
        }
        else {
          setError(res.data.errorDescription);
        }
      })
      .catch(error => {
        processAxiosError(error);
        setError("Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  useEffect(() => {
    if (defId !== null && defId !== undefined && defId !== 0) {
      loadStats(period);
    }
  }, [defId, period])

  return (
    <DashboardCard title="Deduplication over period" rightContent={
      <div>
        <SelectBox
          options={periodOptions}
          keySelector={x => x.id}
          labelSelector={x => x.name}
          selected={period}
          setSelected={setPeriod}
          leftContentSelector={x => isLoading ? <CircularSpinnerSecondary /> : null}
        />
      </div>
    }>
      {error !== null ? <ErrorPage /> :
        <div className="flex flex-col gap-2 flex-1">
          <div className="w-full flex flex-col sm:flex-row justify-start gap-10 items-stretch">
            <div className="flex flex-col gap-1 w-full sm:w-1/2 xl:min-w-[33%] xl:w-1/3 justify-center">
              <Statement title="Backups processed" value={deduplicationStats.dates.length === 0 ? "-" : deduplicationStats.totalCount} Icon={RectangleStackIcon} />
              <Statement title="Backups deduplicated" value={deduplicationStats.dates.length === 0 ? "-" : deduplicationStats.deduplicatedCount} Icon={RectangleStackIcon} />
              <Statement title="Backups size" value={deduplicationStats.dates.length === 0 ? "-" : `${deduplicationStats.totalBytes}${deduplicationStats.size}`} Icon={ArrowsPointingInIcon} />
              <Statement title="Space saved" value={deduplicationStats.dates.length === 0 ? "-" : `${deduplicationStats.deduplicatedBytes}${deduplicationStats.size}`} Icon={ArrowsPointingInIcon} />
            </div>
            <DeduplicationDonut stats={deduplicationStats} />
          </div>
          <DeduplicationSavings stats={deduplicationStats} />
        </div>}
    </DashboardCard>
  )
}

const RepositoryDetailsTab = ({ cardId }) => {
  const card = useSelector((state) => selectCard(state, cardId));

  const backup = useMemo(() => card?.lastBackup, [card]);
  return (
    <div className="flex flex-col items-center mt-5 pb-5 gap-2">
      <div className="flex flex-col lg:flex-row w-full gap-2">
        <div className="flex w-full lg:w-1/3 min-w-[330px]">
          <SettingsWindow repoId={card?.repositoryId} />
        </div>
        <div className="flex w-full">
          <BackupStatsBlock defId={card?.definitionId} />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row w-full gap-2">
        <div className="flex w-full lg:w-1/3 min-w-[330px] min-h-[500px]">
          <BackupInfoBlock owner={card?.repository.owner} repo={card?.repository.name} repoId={cardId} backup={backup} title="Last backup" />
        </div>
        <div className="flex w-full min-h-[500px]">
          <DeduplicationInfoBlock defId={card?.definitionId} />
        </div>
      </div>
    </div>
  )
}

export const RepositoryDetails = () => {

  let { id } = useParams();
  const [card, isLoading, pageError, tryAgain] = useMixedData(() => axios.get(settings.backendUrl + "/v2/card?repoId=" + id),
    (res, dispatch) => dispatch(updateCard(res.data.result)), (state) => selectCard(state, Number(id)),
    undefined, (data) => data !== undefined && data !== null);

  const [tabs, setTabs] = useState({
    overview: { id: "overview", name: "Overview", icon: InformationCircleIcon },
    backups: { id: "backups", name: "Backups", icon: ArchiveBoxIcon, count: -1 },
    restores: { id: "restores", name: "Restores", icon: RepoIcon, count: -1 },
  });
  const [currentTab, setCurrentTab] = useState(tabs.overview);
  useEffect(() => {
    if (card === undefined) return;
    setTabs(t => ({
      ...t,
      [t.backups.id]: { ...t.backups, count: card.backupsCount },
      [t.restores.id]: { ...t.restores, count: card.restoresCount }
    })
    )
  }, [card, currentTab])
  return pageError !== null ? <ErrorPage canTryAgain={true} tryAgainAction={tryAgain} /> : (
    <div className="w-full bg-gray-100">
      <RepositoryDetailsHead tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab} cardId={card?.repositoryId} />
      <div className="px-2 sm:px-6">
        {!isLoading && card.scheduleStatus === ScheduleStatus.restoreOnly &&
          <div className="pt-2 -mb-3">
            <ErrorAlert
              title={
                <span className="text-base">This repository is in <b>Restore only</b> state</span>
              }
              content={
                <p className="text-sm text-gray-600">This means that the repository has been removed from GitHub, or the Cloudback application no longer has access to it.</p>
              } />
          </div>
        }
        {
          {
            [tabs.overview.id]: <RepositoryDetailsTab cardId={card?.repositoryId} />,
            [tabs.backups.id]: <RepositoryBackups defId={card?.definitionId} repo={card?.repository.name} repoId={card?.repositoryId} owner={card?.repository.owner} />,
            [tabs.restores.id]: <RepositoryRestores defId={card?.definitionId} />
          }[currentTab.id]
        }
      </div>
    </div>
  )
}