import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScheduleStatus } from "../../model/domain";
import { StorageImg, cardCanBeSelectedOrBackedUp, selectCard } from "../../helpers/domainHelpers";
import { useNotifier } from "../../hooks/notifications/notificationActions";
import axios from "axios";
import { preload, updateCard } from "../../slices/dataSlice";
import { LoadingAutocomplete, useAutocomplete } from "../../kit/Autocomplete";
import Toggle from "../../kit/Toggle";
import { settings } from "../../settings";
import { setModal } from "../../slices/userSlice";
import { Modals } from "../application/modals/ModalsCommon";
import { DashboardCard } from "../../kit/DashboardCard";
import { processAxiosError } from "../util/ErrorBoundary";
import { ErrorPage } from "../../kit/ErrorPage";
import { ButtonType, FormButton } from "../../kit/Buttons";
import { CircularSpinnerSecondary } from "../../kit/Spinners";
import { FeedTagIcon, MarkGithubIcon } from "@primer/octicons-react";



export const SettingsWindow = memo((props) => {

    //load schedules, storages and retentions via preload when block opens  
    const [pageError, setPageError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [saveBusy, setSaveBusy] = useState(false);
    const card = useSelector((state) => selectCard(state, props.repoId));
    const canBeEdited = useMemo(() => cardCanBeSelectedOrBackedUp(card), [card]);
    const dispatch = useDispatch();
    const loadSettings = () => {
        setIsLoading(true);
        axios.get(settings.backendUrl + "/v2/preload")
            .then((res) => {
                if (res.data.isSuccess) {
                    dispatch(preload(res.data.result));
                }
                else {
                    setPageError(res.data.errorDescription);
                }
            })
            .catch((e) => {
                processAxiosError(e);
                setPageError("Something went wrong");
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        loadSettings();
    }, [])

    const [schedule, setSchedule] = useState(null);
    const schedules = useSelector((state) => state.data.schedules);
    const scheduleAutocomplete = useAutocomplete(schedules, isLoading, card?.schedule.scheduleId, schedule, setSchedule);
    const scheduleChanged = schedule !== null && card?.schedule?.scheduleId !== schedule?.scheduleId;
    const [storage, setStorage] = useState(null);
    const storages = useSelector((state) => state.data.storages);
    const storageAutocomplete = useAutocomplete(storages, isLoading, card?.storage.storageId, storage, setStorage);
    const storageChanged = storage !== null && card?.storage?.storageId !== storage?.storageId;
    const [retention, setRetention] = useState(null);
    const retentions = useSelector((state) => state.data.retentions);
    const retentionAutocomplete = useAutocomplete(retentions, isLoading, card?.retention.retentionPolicyId, retention, setRetention);
    const retentionChanged = retention !== null && card?.retention?.retentionPolicyId !== retention?.retentionPolicyId;
    const [scheduled, setScheduled] = useState(card?.scheduleStatus === ScheduleStatus.scheduled);
    const [notifySuccess, notifyInfo, notifyError] = useNotifier();
    const scheduledChanged = card !== undefined &&
        ((card.scheduleStatus === ScheduleStatus.scheduled && !scheduled) || (card.scheduleStatus !== ScheduleStatus.scheduled && scheduled));

    const isChanged = !isLoading && (scheduleChanged || storageChanged || retentionChanged || scheduledChanged);

    useEffect(() => {
        if (card !== undefined && card !== null) {
            setScheduled(card.scheduleStatus === ScheduleStatus.scheduled);
        }
    }, [card])

    const switchBackup = () => {

        if (scheduled && card.scheduleStatus === ScheduleStatus.scheduled) {
            return;
        }
        else if (!scheduled && card.scheduleStatus === ScheduleStatus.notScheduled) {
            return;
        }
        axios.post(settings.backendUrl + "/v2/switchCard", null, {
            params: {
                repoId: card.repositoryId,
                enable: scheduled
            }
        })
            .then((res) => {
                if (res.data.isSuccess) {
                    dispatch(updateCard(res.data.result));
                    notifySuccess(scheduled ? "Backup enabled" : "Backup disabled");
                }                
                else {
                    dispatch(setModal({
                        id: Modals.ErrorModalWithSingleCloseButton,
                        text: `${res.data.errorDescription}`, title: `Can't ${scheduled ? "enable" : "disable"} backup`,
                        errorCode: res.data.errorCode,
                    }));
                }
            })
            .catch(e => {
                processAxiosError(e);
                dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, text: "Something went wrong", title: `Can't ${scheduled ? "enable" : "disable"} backup` }));
            })
            .finally(() => {
                setSaveBusy(false);
            });
    }

    const applySettings = () => {

        setSaveBusy(true);

        if (card.schedule.scheduleId === schedule.scheduleId &&
            card.storage.storageId === storage.storageId &&
            card.retention.retentionPolicyId === retention.retentionPolicyId) {
            switchBackup();
            return;
        }

        axios.post(settings.backendUrl + "/v2/updatecard", null, {
            params: {
                repoId: card.repositoryId,
                scheduleId: schedule.scheduleId,
                storageId: storage.storageId,
                retentionId: retention.retentionPolicyId
            }
        })
            .then((res) => {
                if (res.data.isSuccess) {
                    dispatch(updateCard(res.data.result));
                    notifySuccess("Repository settings updated");
                    switchBackup();
                }
                else {
                    dispatch(setModal({
                        id: Modals.ErrorModalWithSingleCloseButton,
                        text: `${res.data.errorDescription}`, title: "Can't apply settings"
                    }));
                }
            })
            .catch(e => {
                processAxiosError(e);
                dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, text: "Something went wrong", title: "Can't apply settings" }));
            })
            .finally(() => {
                setSaveBusy(false);
            });
    }

    const onToggle = (checked) => {
        setScheduled(checked);
    }

    return (
        <DashboardCard title="Settings" isLoading={isLoading}>
            {pageError !== null ? <ErrorPage canTryAgain={true} onTryAgain={loadSettings} /> :
                <>
                    <div className="flex flex-col ">
                        <div className="w-full flex flex-row pb-2 items-center align-middle">
                            <div className="flex-1 mr-2">
                                <Toggle checked={scheduled}
                                    label={scheduled ? "Scheduled" : "Not scheduled"}
                                    onChange={onToggle}
                                    disabled={!canBeEdited}
                                />
                            </div>
                            <p className="text-sm font-normal">Next: <span className="font-semibold">{card?.nextBackupString || "-"}</span></p>
                        </div>
                        <div className="mt-2 flex flex-col gap-4">
                            <LoadingAutocomplete isLoading={scheduleAutocomplete.isLoading}
                                autocomplete={{
                                    ...scheduleAutocomplete,
                                    labelSelector: (option) => option?.name,
                                    keySelector: (option) => option?.scheduleId,
                                    label: "Schedule",
                                    labelLeft: true,
                                    disabled: !canBeEdited
                                }}
                            />
                            <LoadingAutocomplete isLoading={storageAutocomplete.isLoading}
                                autocomplete={{
                                    ...storageAutocomplete,
                                    labelSelector: (option) => option?.storageName,
                                    keySelector: (option) => option?.storageId,
                                    optionLeadingContentSelector: (option) => <StorageImg className="w-5 h-5 min-h-5 min-w-5" alt="storage-img" isCustomStorage={option?.isCustomStorage} storageName={option?.storageName} provider={option?.provider} />,
                                    label: "Storage",
                                    labelLeft: true,
                                    disabled: !canBeEdited
                                }}
                            />
                            <LoadingAutocomplete isLoading={retentionAutocomplete.isLoading}
                                autocomplete={{
                                    ...retentionAutocomplete,
                                    labelSelector: (option) => option?.name,
                                    keySelector: (option) => option?.retentionPolicyId,
                                    label: "Retention",
                                    labelLeft: true,
                                    disabled: !canBeEdited
                                }}
                            />
                            <div className="pt-2 flex flex-col sm:flex-row border-t border-gray-200 w-full justify-between items-center">                               
                                {card === null || card === undefined ? <CircularSpinnerSecondary/>:
                                      <img src={settings.backendUrl + `/badge/${card?.repository.owner}/${card?.repository.name}`} style={{height:20}}/>                      
                                }
                                        <FormButton btnText="Get badge URL"
                                        leftIcon={<FeedTagIcon className="mr-1 w-4 h-4"/>}
                                        loading={isLoading}
                                        disabled={isLoading}       
                                        buttonType={ButtonType.Secondary}                                 
                                        className="!px-2 !py-1 !text-xs"
                                            onClick={() => dispatch(setModal({ id: Modals.BadgeModal, repository: card.repository }))}
                                        />
                            </div>
                        </div>
                    </div>
                    {isChanged &&
                        <>
                            <div className="relative pt-3 pb-2">
                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div className="w-full border-t border-gray-300" />
                                </div>
                            </div>

                            <div className="flex pt-3 justify-end w-full">
                                <FormButton busy={saveBusy} loading={isLoading} btnText={saveBusy ? "Saving..." : "Save"} onClick={applySettings} disabled={saveBusy} />
                            </div>
                        </>
                    }
                </>
            }
        </DashboardCard >
    );
})