import { Fragment, useMemo } from 'react'
import { Menu, Transition } from '@headlessui/react'
import {
    ArrowRightStartOnRectangleIcon,
    Bars3Icon, MagnifyingGlassIcon, Squares2X2Icon, TableCellsIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import { HomeIcon } from '@heroicons/react/20/solid'
import { Link, useLocation, useMatches } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setSearch } from '../../slices/dataSlice'
import { dynamicSettings, settings } from '../../settings'
import { Paths } from '../../helpers/navigationHelper'
import { switchView } from '../../slices/userSlice'
import { LinkExternalIcon } from '@primer/octicons-react'
export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const userNavigation = [
    { name: Paths.billing.name, href: Paths.billing.path },
    { name: Paths.accountSettings.name, href: Paths.accountSettings.path },
    { isDivider: true },
    { name: "Manage GitHub installations", href: dynamicSettings.appInstallUrl,target:"_blank",  rightIcon: <LinkExternalIcon className="ml-1 h-4 w-4" verticalAlign='middle'/>},
    { name: "Manage GitHub marketplace plans", href: settings.marketplaceUrl,target:"_blank", rightIcon: <LinkExternalIcon className="ml-1 h-4 w-4" verticalAlign='middle'/> },
    { isDivider: true },
    { name: 'Sign out', href: settings.backendUrl + '/Auth/SignOut', leftIcon: <ArrowRightStartOnRectangleIcon className="mr-1 h-5 w-5" /> },
]

/*const pages = [
    { name: 'Projects', href: '#', current: false },
    { name: 'Project Nero', href: '#', current: true },
]*/

const Breadcrumbs = () => {
    const matches = useMatches();
    const pages = useMemo(() => matches.filter((match) => Boolean(match.handle?.crumb)).map(x =>
        <Link to={x.pathname} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{x.handle?.crumb(x.data).name}</Link>
    ), [matches])
    return (
        <nav className="flex bg-white relative flex-1" aria-label="Breadcrumb">
            <ol role="list" className="flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
                <li className="flex">
                    <div className="flex items-center">
                        <Link to={Paths.dashboard.path} className="text-gray-400 hover:text-gray-500">
                            <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <span className="sr-only">Home</span>
                        </Link>
                    </div>
                </li>
                {pages.map((page, i) => (
                    <li key={i} className="flex">
                        <div className="flex items-center">
                            <svg
                                className="h-full w-6 flex-shrink-0 text-gray-200"
                                viewBox="0 0 24 44"
                                preserveAspectRatio="none"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                            </svg>
                            {page}
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    )
}

const ViewSwitch = () => {
    const tableView = useSelector(state => state.user.tableView);
    const dispatch = useDispatch();
    return (
        <span className="isolate inline-flex rounded-md shadow-sm h-max-content">
            <button
                type="button"
                disabled={!tableView}
                onClick={() => dispatch(switchView())}
                className={classNames(
                    tableView ? "bg-white text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-100" : "bg-gray-700 text-white",
                    "relative inline-flex items-center rounded-l-md px-2 py-1 text-sm font-semibold   focus:z-10")}
            >
                <Squares2X2Icon className="h-6 w-6 sm:mr-1" />
                <p className='hidden sm:flex'>Cards</p>
            </button>
            <button
                type="button"
                disabled={tableView}
                onClick={() => dispatch(switchView())}
                className={classNames(
                    tableView ? "bg-gray-700 text-white" : "bg-white text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-100",
                    "relative -ml-px inline-flex items-center rounded-r-md px-2 py-1 text-sm font-semibold focus:z-10")}
            >
                <TableCellsIcon className="h-6 w-6 sm:mr-1" />
                <p className='hidden sm:flex'>Table</p>
            </button>
        </span>
    )
}

export const ApplicationHeader = ({ setSidebarOpen }) => {
    const location = useLocation();
    const mainPage = location.pathname === "/";
    const searchTerm = useSelector(state => state.data.selectors.searchTerm);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    return (
        <div className="sticky top-0 z-40 gap-x-1 flex h-16 shrink-0 items-center border-b border-gray-200 bg-white shadow-sm sm:gap-x-6 pr-4 sm:pr-6 lg:pr-8">
            <button type="button" className="p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

            <div className={classNames("flex flex-1 gap-x-2 self-stretch lg:gap-x-6", mainPage ? "justify-end" : "")}>
                {!mainPage && <Breadcrumbs />}
                {mainPage &&
                    <div className="relative flex flex-1 mx-1 sm:mx-6 lg:mx-8 min-w-24">
                        <label htmlFor="search-field" className="sr-only">
                            Search
                        </label>
                        <MagnifyingGlassIcon
                            className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                            aria-hidden="true"
                        />
                        <input
                            value={searchTerm}
                            onChange={e => dispatch(setSearch(e.target.value))}
                            className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                            placeholder="Search..."
                            type="text"
                            name="search"
                        />
                    </div>
                }
                {mainPage &&
                    <>
                        <div className='flex items-center'>
                            <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />
                        </div>
                        <div className="flex items-center h-full mr-2">
                            <ViewSwitch />
                        </div>
                    </>}
                <div className="flex items-center gap-x-4 lg:gap-x-6 min-w-8">
                    <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative">
                        <Menu.Button className="-m-1.5 flex items-center p-1.5">
                            <span className="sr-only">Open user menu</span>
                            <img
                                className="h-8 w-8 rounded-full bg-gray-50"
                                src={user.avatar}
                                alt=""
                            />
                            <span className="hidden lg:flex lg:items-center">
                                <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                                    {user.name}
                                </span>
                                <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute right-0 z-10 mt-2.5 w-max origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                <Menu.Item>
                                    {({ active }) => (
                                        <>
                                            <p className='block px-3 py-1 text-sm leading-6 text-gray-900'>Hello, <span className='font-semibold'>{user.name}</span>!</p>
                                            <div className="h-0.5 my-1 bg-gray-200" />
                                        </>
                                    )}
                                </Menu.Item>
                                {userNavigation.map((item, i) => (
                                    <Menu.Item key={item.name+i}>
                                        {({ active }) => (
                                            item.isDivider
                                                ? <div className="h-0.5 my-1 bg-gray-200" />
                                                :
                                                item.name === "Sign out" ?
                                                    <a href={item.href} className={classNames(
                                                        active ? 'bg-gray-50' : '',
                                                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                    )}>
                                                        <div className='inline-flex items-center'>
                                                            {item.leftIcon && item.leftIcon}
                                                            {item.name}
                                                            {item.rightIcon && item.rightIcon}
                                                        </div>
                                                    </a>
                                                    :
                                                    <Link to={item.href} target={item.target} className={classNames(
                                                        active ? 'bg-gray-50' : '',
                                                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                    )}>
                                                        <div className='inline-flex items-center'>
                                                            {item.leftIcon && item.leftIcon}
                                                            {item.name}
                                                            {item.rightIcon && item.rightIcon}
                                                        </div>
                                                    </Link>
                                        )}
                                    </Menu.Item>
                                ))}
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
        </div>
    )
}