import { createSlice } from '@reduxjs/toolkit'

const initialState = {   
    loggedIn: false,
    name: '',  
    avatar: '',
    id:null,
    setupRequired: true,
    error: false,
    tableView:false,
    topAlerts: {},
    modal:null,
    triggerNotifications:{}
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {   
        triggerMultiple(state,action){
            state.triggerNotifications[action.payload.correlationId] = action.payload;
        },
        passInitialSetup(state,action){
            state.setupRequired = false;
        },
        setModal(state,action){
            state.modal = action.payload;
        },
        closeModal(state, action){
            state.modal = null;
        },
        addTopAlert(state,action){
            state.topAlerts[action.payload.id] = action.payload;
        },
        closeTopAlert(state,action){
            delete state.topAlerts[action.payload.id];
        },
        switchView(state,action){
            state.tableView = !state.tableView;
        },
        rejectUser(state, action){
            state.error= true;
            state.loggedIn = false;
            state.name = "";
            state.avatar = "";
            state.id = null;
            state.setupRequired = true;
        },
        setUser(state, action){
            state.name = action.payload.githubName;       
            state.avatar = action.payload.img;     
            state.id = action.payload.id;    
            state.error = false;
            state.loggedIn = true;
            state.setupRequired = action.payload.setupRequired;
        }
    }
});

export const {
    passInitialSetup,
    switchView,
    setUser,
    rejectUser,
    addTopAlert,
    closeTopAlert,
    setModal,
    closeModal,
    triggerMultiple
 } = userSlice.actions

export default userSlice.reducer