import { CircularSpinnerSecondary } from "./Spinners"


export const InTextLink = ({ url, title }) => {
    return (
        <a className="text-green-600 hover:text-green-500" href={url} target="_blank">{title}</a>
    )
}

export const InTextClickButton = ({ title, onClick, busy, extra }) => {
    return (
        <button
            type="button"
            onClick={onClick}
            disabled={busy || false}
            className="inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >           
            {busy && <CircularSpinnerSecondary className="mr-1"/>}
            {extra!==undefined && extra}
            {title}
        </button>
    )
}