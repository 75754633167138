import { createSelector } from "@reduxjs/toolkit"
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { settings } from "../../settings";
import { loadRestores } from "../../slices/dataSlice";
import { FullScreenLoader } from "../../kit/Spinners";
import RestoreTable from "./RestoreTable";
import { NoSymbolIcon } from "@heroicons/react/24/outline";
import { useDependentMixedData } from "../../hooks/useMixedData";

const selectRestores = createSelector([
    state => state.data.dashboard.loadedRestores,
    (state, defId) => defId
], (restores, defId) => defId === 0 || restores === undefined || !(defId in restores) ? [] : restores[defId])

export const RepositoryRestores = ({ defId }) => {

    const [restores, isLoading] = useDependentMixedData(() => axios.get(settings.backendUrl + "/v2/restores?definitionId=" + defId),
        (res, dispatch) => dispatch(loadRestores(res.data.result)), (state) => selectRestores(state, defId), defId,
         (data) => data !== undefined && data !== null && data.length > 0);
    return (
        <>
            {isLoading ? <FullScreenLoader className="h-96" fillColor="gray" strokeColor="black" /> :
                <div className="p-2">
                        {restores.length > 0 ? <RestoreTable rows={restores} />
                            :
                            <div className="flex flex-col mt-10">
                                <div className="flex align-middle justify-center items-center pb-2">
                                    <NoSymbolIcon className="h-6 w-6" style={{ opacity: 0.3 }} />
                                </div>
                                <div className="flex align-middle justify-center items-center">
                                    <div className="align-middle text-center">
                                        <span className="text-nowrap text-gray-400">
                                            No restores here yet
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                </div>}
        </>
    )
}