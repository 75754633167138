import { Link } from "react-router-dom"
import { ReactComponent as CloudbackLogo } from './../../assets/logo-black.svg'
import { FormButton } from '../../kit/Buttons'
import { MailIcon, MarkGithubIcon } from "@primer/octicons-react"
import { dynamicSettings, settings } from "../../settings"


export const AccountDeleted = () => {
    return (
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="w-full flex justify-center items-center">
                    <Link to="https://cloudback.it" className='inline-flex items-center'><CloudbackLogo className="mx-auto h-10 w-auto mr-1" /><span className='text-lg font-semibold'>Cloudback</span></Link>
                </div>
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Your account has been deleted
                </h2>
            </div>
            <div className="mt-10 mx-auto sm:w-full sm:max-w-[720px] px-5">
                <p>We are sorry to see you go, but we thank you for giving us a fair try! If you have time, please take a moment to fill out our <a className="underline font-semibold" target="_blank" href="https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__q88DUJUOEpIQjY2TVJOSjc1SFJCWFNETkNBUzNUUS4u">Cancellation Survey</a>. We would love to hear your feedback so we can continue to bring out great products!</p>
                <p className="mt-5">Feel free to remove your GitHub application installation(s):</p>
                <div className="flex flex-col sm:flex-row gap-3 mt-2">
                    <Link className="sm:w-max w-full inline-flex justify-center items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500" to={dynamicSettings.appInstallUrl}>
                        <MarkGithubIcon className="h-5 w-5 mr-1" />
                        Manage GitHub installations
                    </Link>
                    <Link className="sm:w-max w-full inline-flex justify-center items-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500" to="mailto:feedback@cloudback.it?subject=Cloudback feedback" target="_blank">
                        <MailIcon className="h-5 w-5 mr-1" />
                        Send feedback email
                    </Link>
                </div>
            </div>
        </div>
    )
}