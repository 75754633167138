import { Dialog } from "@headlessui/react"
import { CheckIcon } from "@heroicons/react/24/outline"




export const SuccessModalWithSingleCloseButton = ({ onClose, data }) => {

    return (
        <>
            <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-2">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {data.title}
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            {data.text}
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-6">
                <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </>
    )
}