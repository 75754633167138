import { MarketplacePlanMissingDialogAlert } from "./MarketplacePlanMissingDialogAlert"



export const TopAlerts = {
    MarketplacePlanMissing: "MarketplacePlanMissing"
}

export const TopAlertsContent = {
    [TopAlerts.MarketplacePlanMissing]: MarketplacePlanMissingDialogAlert
}