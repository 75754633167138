import { Dialog } from "@headlessui/react"
import { FeedTagIcon } from "@primer/octicons-react"
import { settings } from "../../../settings"
import { classNames } from "../ApplicationHeader"
import CopyToClipboard from "react-copy-to-clipboard"
import { useNotifier } from "../../../hooks/notifications/notificationActions"
import { ClipboardIcon, InformationCircleIcon } from "@heroicons/react/24/outline"
import { Tooltip } from "react-tooltip"
import { ButtonType, FormButton } from "../../../kit/Buttons"


export const BadgeModal = ({ onClose, data }) => {
    const badgeUrl = settings.backendUrl + `/badge/${data.repository.owner}/${data.repository.name}`;
    const markDownUrl = `[![Build Status](${badgeUrl})](https://cloudback.it)`;
    const [notifySuccess] = useNotifier();
    return (
        <>
            <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <FeedTagIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-2">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Status badge URL
                    </Dialog.Title>
                    <div className="mt-3">
                        <p className="text-sm font-medium text-gray-500">
                            This is how the <b>status badge</b> looks like for your repository:
                        </p>
                        <div className="w-full inline-flex justify-center mt-2">
                            <img src={badgeUrl} style={{ height: 20 }} />
                        </div>
                        <div className="sm:col-span-1 flex items-center">
                            <p className="font-medium text-gray-600 mr-2">Image URL:</p>
                        </div>
                        <div className="sm:col-span-5">
                            <div className="flex flex-col">
                                <div className="mt-1 flex flex-grow items-stretch">
                                    <input
                                        readOnly={true}
                                        defaultValue={badgeUrl}
                                        className={classNames("rounded-l-md", "resize-none block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6")}
                                    />
                                    <CopyToClipboard text={badgeUrl} onCopy={() => notifySuccess("Copied")}>
                                        <button
                                            data-tooltip-id="copy-tooltip" data-tooltip-content="Copy"
                                            type="button"
                                            className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        >
                                            <ClipboardIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>

                                    </CopyToClipboard>
                                    <Tooltip id="copy-tooltip" />
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-1 flex items-center mt-3">
                            <p className="font-medium text-gray-600 mr-2">Markdown:</p>
                        </div>
                        <div className="sm:col-span-5">
                            <div className="flex flex-col">
                                <div className="mt-1 relative">
                                    <textarea
                                        rows={4}
                                        readOnly={true}
                                        className={classNames("resize-none block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6")}
                                        defaultValue={markDownUrl}
                                    />
                                    <CopyToClipboard text={markDownUrl} onCopy={() => notifySuccess("Copied")}>
                                        <button
                                            data-tooltip-id="copy-tooltip" data-tooltip-content="Copy"
                                            type="button"
                                            className="absolute top-1 right-5 rounded-full bg-white p-1 text-gray-600 shadow-md hover:text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                        >
                                            <ClipboardIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>

                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>
                        <div className="rounded-md bg-blue-50 p-4 mt-3">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3 flex-1 md:flex md:justify-between">
                                    <p className="text-sm text-blue-700">The badge represents the status of the latest backup created by Cloudback application</p>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-6">
            <FormButton onClick={onClose} btnText="Close" buttonType={ButtonType.Cancel} className="!w-full !ml-0" />
            </div>
        </>
    )
}