import { Fragment, useEffect, useState } from "react"
import { FullScreenLoader } from "../../kit/Spinners";
import { TableCell, TableHeadCell } from "../../kit/Table";
import axios from "axios";
import { settings } from "../../settings";
import { classNames } from "../application/ApplicationHeader";
import { CreditCardIcon, DocumentCheckIcon, InformationCircleIcon, LightBulbIcon, ShoppingCartIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import { processAxiosError } from "../util/ErrorBoundary";
import { ErrorPage } from "../../kit/ErrorPage";
import { EmptyInstallationPage } from "../application/EmptyInstallationPage";
import { LinkExternalIcon, MailIcon } from "@primer/octicons-react";

const SpanTableContent = ({ title, Icon }) => {
    return <span className="inline-flex items-center align-middle">{Icon !== undefined && <Icon className="min-h-5 min-w-5 h-5 w-5 mr-1" />}{title}</span>
}

const NeedMoreLinkButton = ({ needMoreLink }) => {

    if (needMoreLink === null) {
        return <></>;
    }

    return (
        <Link to={needMoreLink.url} target="_blank">
            <button
                type="button"
                className="whitespace-nowrap rounded-md bg-gray-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            >
                {needMoreLink.title}{needMoreLink.url.includes("mailto")?<MailIcon className="h-4 w-4 ml-1" verticalAlign="middle"/>:<LinkExternalIcon className="h-4 w-4 ml-1" verticalAlign="middle"/>}
            </button>
        </Link>
    )
}

export const BillingPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [billing, setBilling] = useState(null);
    const [pageError, setPageError] = useState(null);
    const loadBilling = () => {
        setIsLoading(true);
        axios.get(settings.backendUrl + "/v2/billing")
            .then(res => {
                if (res.data.isSuccess) {
                    setBilling(res.data.result);
                }
                else {
                    setPageError(res.data.errorDescription);
                }
            })
            .catch((e) => {
                processAxiosError(e);
                setPageError("The billing couldn't be loaded. Please try again later.");
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        loadBilling();
    }, [])

    return pageError !== null ? <ErrorPage /> : (
        <div className="inline-block min-w-full align-middle px-4 xl:px-16 mt-5">
            {isLoading ? <FullScreenLoader className="h-96" fillColor="gray" strokeColor="black" />
                :
                <table className="border-none min-w-full divide-y divide-gray-300 overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-t-lg">
                    <thead className="bg-gray-50">
                        <tr>
                            <TableHeadCell sticky={true} className="font-bold py-3 pl-4 pr-3 rounded-tl-lg"><SpanTableContent title="Account" Icon={UserCircleIcon} /></TableHeadCell>
                            <TableHeadCell sticky={true} className="font-bold px-3 py-3.5"><SpanTableContent title="Plan" Icon={ShoppingCartIcon} /></TableHeadCell>
                            <TableHeadCell sticky={true} className="font-bold px-3 py-3.5"><SpanTableContent title="Pricing" Icon={CreditCardIcon} /></TableHeadCell>
                            <TableHeadCell sticky={true} className="font-bold px-3 py-3.5"><SpanTableContent title="Repositories" Icon={InformationCircleIcon} />
                                <span className="mt-1"><br /><span className="text-xs font-light text-gray-500">Purchased / In use / Remaining</span></span>
                            </TableHeadCell>
                            <TableHeadCell sticky={true} className="px-3 py-3.5 rounded-tr-lg"><SpanTableContent title="Need More" Icon={LightBulbIcon} /></TableHeadCell>
                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        {Object.values(billing.accountBillings).map((billingItem, id) =>
                            <Fragment key={id}>
                                <tr key={id}>
                                    <TableCell className={classNames("font-normal py-4 pl-4 pr-3")} >
                                        <SpanTableContent title={billingItem.accountName} Icon={() => <div
                                            className="relative inline-block pr-2"
                                        >
                                            <img
                                                className="min-h-8 min-w-8 h-8 w-8 mr-1 rounded-md"
                                                src={`https://avatars.githubusercontent.com/${billingItem.accountName}`}
                                                alt=""
                                            /><span>
                                                {billingItem.isInvoiced &&
                                                    <DocumentCheckIcon className="absolute right-0 bottom-0 block h-3 w-3 rounded-sm bg-white -translate-x-2/4" data-tooltip-id="invoiced-tooltip" data-tooltip-content="Invoiced customer" />
                                                }

                                            </span></div>} /> <Tooltip id="invoiced-tooltip" className="z-[55]" /></TableCell>
                                    <TableCell className={classNames("font-normal px-3 py-4")} >
                                        {billingItem.freeTrial !== null ? "Free trial" : billingItem.planName}
                                    </TableCell>
                                    <TableCell className="px-3 py-4">{billingItem.freeTrial !== null ? billingItem.freeTrial.endsAtString : billingItem.pricingString}</TableCell>
                                    <TableCell className="px-3 py-4">{billingItem.usageString}</TableCell>
                                    <TableCell className="px-3 py-4">{billingItem.freeTrial !== null ? <></> :
                                        <NeedMoreLinkButton needMoreLink={billingItem.needMoreLink} />
                                    }</TableCell>
                                </tr>
                                {billingItem.freeTrial !== null &&
                                    <tr key={`extra-${id}`}>
                                        <TableCell className={classNames("font-normal py-4 pl-4 pr-3")} >
                                            {billingItem.freeTrial.fromString}
                                        </TableCell>
                                        <TableCell className={classNames("font-normal px-3 py-4")} >{billingItem.planName}</TableCell>
                                        <TableCell className="px-3 py-4">{billingItem.pricingString}</TableCell>
                                        <TableCell className="px-3 py-4">{billingItem.freeTrial.usageString}</TableCell>
                                        <TableCell className="px-3 py-4"><NeedMoreLinkButton needMoreLink={billingItem.needMoreLink} /></TableCell>
                                    </tr>
                                }
                            </Fragment>
                        )}
                        {!isLoading && billing.accountBillings.length === 0 &&
                            <tr>
                                <td colSpan={5} className="py-5"><EmptyInstallationPage /></td>
                            </tr>
                        }
                    </tbody>
                </table>
            }
        </div>
    )

}