import ReactApexChart from "react-apexcharts";
import {apexColors} from "./../../util/Colors";
import { NoChartDataFound } from "./NoChartDataFound";


export const DeduplicationDonut = ({stats}) => {
  const statsAreEmpty = stats.totalCount === 0 && stats.deduplicatedCount === 0;
    const series = [ stats.totalCount-stats.deduplicatedCount, stats.deduplicatedCount];
    const options = {
        chart: {
            type: 'donut',
        },
        colors:apexColors,
        dataLabels: {
            enabled: false,
        },
        labels: [ 'Non-deduplicated backups','Deduplicated backups'],
        legend: {
            show: true,
            position: 'left',
            horizontalAlign: 'left',
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: function(val) {
                    return val;
                }
            }
        } 
    };

    return (      
      <div className="min-h-[120px] min-w-[300] w-full flex justify-center relative border rounded-lg">
        <div className="w-full m-3">
          { statsAreEmpty && <NoChartDataFound /> }
          <ReactApexChart options={options} series={series} type="donut" height="100%" width="100%"/>
        </div>
      </div>
    )
}