import { getBackupStatus, StorageImg } from "../../helpers/domainHelpers";
import { TableCell, TableHeadCell } from "../../kit/Table";
import { BackupStatus } from "../../model/domain";
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import { CheckIcon, DesktopDownloadIcon, IssueOpenedIcon, MilestoneIcon, PivotColumnIcon, RepoPushIcon, TableIcon, TagIcon } from "@primer/octicons-react";
import { CheckCircleIcon, DocumentDuplicateIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { BackupInfoBlock } from "./RepositoryDetails";
import { useDispatch } from "react-redux";
import { setModal } from "../../slices/userSlice";
import { Modals } from "../application/modals/ModalsCommon";
import { StorageProviderName } from "../../helpers/domainHelpers";

function BackupRow(props) {
    const backup = props.backup;
    const repoId = props.repoId;
    const owner = props.owner;
    const repo = props.repo;
    const backupStatus = getBackupStatus(backup, false);
    const id = props.id;
    const dispatch = useDispatch();
    return (
        <tr key={id}>
            <TableCell className="font-normal py-2 pl-4 pr-3">
                <div className="flex align-middle items-center">
                    <backupStatus.IconSrc className="mr-2 h-4 w-4" />
                    <div className={`inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-normal ring-1 ring-inset ${backupStatus.Style}`}>
                        {backupStatus.Text}
                    </div>
                </div>
            </TableCell>
            <TableCell className="font-normal px-2 py-2">
                {backup.startTimeText}
            </TableCell>
            <TableCell className="font-normal px-2 py-2">
                {backup.compressedSizeText}
            </TableCell>
            <TableCell className="font-normal px-2 py-2">
                {backup.meta.isDeduplicated ? <CheckIcon className="h-5 w-5 !block" verticalAlign="middle" size="medium" alt="deduplicated-icon" /> : <></>}            </TableCell>
            <TableCell className="font-normal px-2 py-2">
                <span className="items-center flex font-normal">
                    <StorageImg className="mr-2 h-6 w-6 min-h-6 min-w-6" alt="storage-img" isCustomStorage={backup.isCustomStorage} storageName={backup.storageName} provider={backup.provider} />
                    {backup.storageName ?? "nothing selected"}
                </span>
            </TableCell>
            <TableCell className="font-normal px-2 py-2">
                <div className="flex">
                    <div className="flex align-middle items-center text-center mr-3">
                        <button className="rounded-md bg-white p-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            data-tooltip-id="info-tooltip" data-tooltip-content="Information"
                            onClick={() => dispatch(setModal({ id: Modals.BackupInfoModal, owner, repo, repoId, backup }))}>
                            <InformationCircleIcon className="h-5 w-5 !block" verticalAlign="middle" size="medium" alt="download-icon" />
                        </button>
                    </div>
                    {backup.status === BackupStatus.succeeded &&
                        <>
                            <div className="flex align-middle items-center text-center mr-3">
                                <button className="rounded-md bg-white p-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    data-tooltip-id="download-tooltip" data-tooltip-content="Download this backup"
                                    onClick={() => dispatch(setModal({
                                        id: Modals.DownloadBackupModal,
                                        backupId: backup.backupId,
                                        storageProviderName: StorageProviderName(backup.provider)
                                      }))}>
                                    <DesktopDownloadIcon className="h-5 w-5 !block" verticalAlign="middle" size="medium" alt="download-icon" />
                                </button>
                            </div>
                            <div className="flex align-middle items-center text-center">
                                <Link className="rounded-md bg-white p-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    to={`/restore/${owner}/${repoId}/${backup.backupId}`}
                                    data-tooltip-id="restore-tooltip" data-tooltip-content="Restore this backup">
                                    <RepoPushIcon className="h-5 w-5 !block" verticalAlign="middle" size="medium" alt="restore-icon" />
                                </Link>
                            </div>
                        </>
                    }
                </div>
            </TableCell>
        </tr>
    );
}


function BackupTable(props) {
    const tableRows = props.rows;
    const repoId = props.repoId;
    const owner = props.owner;
    const repo = props.repo;
    return (
        <div className="w-full py-2">
            <table className="border-none min-w-full divide-y divide-gray-300 shadow ring-1 ring-black ring-opacity-5 rounded-t-lg">
                <thead className="bg-gray-50">
                    <tr>
                        <TableHeadCell stickyPage={true} className="!top-[113px] font-bold py-3 pl-4 pr-3 rounded-tl-lg">
                            Status
                        </TableHeadCell >
                        <TableHeadCell stickyPage={true} className="!top-[113px] font-bold px-3 py-3.5">
                            Start time
                        </TableHeadCell>
                        <TableHeadCell stickyPage={true} className="!top-[113px] font-bold px-3 py-3.5">
                            Backup size
                        </TableHeadCell>
                        <TableHeadCell stickyPage={true} className="!top-[113px] font-bold px-3 py-3.5">
                            Deduplicated
                        </TableHeadCell>
                        <TableHeadCell stickyPage={true} className="!top-[113px] font-bold px-3 py-3.5">
                            Storage
                        </TableHeadCell>
                        <TableHeadCell stickyPage={true} className="!top-[113px] font-bold px-3 py-3.5">
                        </TableHeadCell>
                    </tr>
                </thead>
                <tbody className="bg-white">
                    {tableRows
                        .map((row, id) => <BackupRow key={id} backup={row} repo={repo} repoId={repoId} owner={owner} />)
                    }
                </tbody>
            </table>
            <Tooltip className="z-[55]" id="restore-tooltip" />
            <Tooltip className="z-[55]" id="download-tooltip" />
            <Tooltip className="z-[55]" id="info-tooltip" />
        </div>
    );
}

export default BackupTable;