import { Dialog } from "@headlessui/react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"
import axios from "axios"
import { settings } from "../../../settings"
import { useState } from "react"
import { useSelector } from "react-redux"
import { ButtonType, FormButton } from "../../../kit/Buttons"



export const ConfirmDeleteAccountModal = ({ onClose, data }) => {
    const [accName, setAccName] = useState("");
    const currentUser = useSelector(state=>state.user.name);
    const [isDeleting, setIsDeleting] = useState(false);
    const onDeleting = ()=>{
        if (!currentUser === accName){
            return;
        }
        setIsDeleting(true);
        axios.post(settings.backendUrl + "/Auth/DeleteCurrentAccount")
        .then(res => {
            if (res.status === 200) {
                window.location.href = settings.backendUrl+"/Auth/SignOutAfterDelete";
            }
        })
    }   
    return(
        <>
            <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                    <ExclamationTriangleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-2">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    Are you absolutely sure?
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                        This action cannot be undone!
                        This will permanently delete your account and all your backups. This will not change your billing plan. If you want to change it, you can do so in <a href={settings.marketplaceUrl} target="_blank" className="underline">GitHub website</a>.
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <label htmlFor="repository-pattern" className="block text-sm font-semibold leading-6 text-gray-600">
                Please type your account name to confirm the action.
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        value={accName}
                        placeholder="Type account name here..."
                        onChange={e => setAccName(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                    />
                </div>
            </div>
            <div className="mt-6 sm:mt-4 sm:flex sm:flex-row-reverse">
            <FormButton onClick={onClose} btnText="Cancel" buttonType={ButtonType.Cancel}/>
            <FormButton buttonType={ButtonType.Secondary} className={currentUser!==accName?"":"!bg-red-500 !text-white hover:!bg-red-700"} onClick={onDeleting} busy={isDeleting} disabled={currentUser!==accName} btnText={isDeleting ? "Deleting account" : "I understand the consequences, delete my account"}/>  
        </div>
        </>
    )
}