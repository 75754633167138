export function wildcardMatch(text, pattern) {
    const regexPattern =
        new RegExp('^' + pattern.replace(/\?/g, '.').replace(/\*/g, '.*') + '$');
    return regexPattern.test(text);
}


﻿export const toParams = query => {
    const q = query.replace(/^\??\//, "");
    return q.split("&").reduce((values, param) => {
            const [key, value] = param.split("=");
            values[key] = value;
            return values;
    }, {});
};


export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}