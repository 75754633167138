import { classNames } from "../components/application/ApplicationHeader"

/*export const FullScreenLoader = ({ className, spinnerClassName, fillColor = "currentColor", strokeColor = "currentColor", title = "Loading..." }) => {
    return (
        <div className={classNames("w-full flex justify-center items-center align-middle", className)}>
            <div className="flex flex-col">
                <div className="flex justify-center">
                    <CircularSpinnerSecondary className={spinnerClassName} fillColor={fillColor} strokeColor={strokeColor} />
                </div>
                <p className="text-sm">{title}</p>
            </div>
        </div>
    )
}*/

export const NewLoader = ({ className, size, title, light = false }) => {
    const sizeClass = size==="xs" ? "xsmall-loader": size === "sm" ? "small-loader" : size === "md" ? "medium-loader" : "big-loader";
    return (
        <div className={classNames("flex justify-center items-center align-middle", className)}>
            <div className="flex flex-col">
                <div className="flex justify-center">
                    <div className={classNames(sizeClass, "loader1", light?"loader-light":"")} />
                </div>
                {title && <p className="text-md mt-3">{title}</p>}
            </div>
        </div>
    )
}

export const FullScreenLoader = ({ className, size, title }) => {
    const titleToPass = title===undefined?"Loading...":title===null?undefined:title;
    return (
        <NewLoader className={classNames(className,"flex-1")} size={size} title={titleToPass}/>
    )
}

export const CircularSpinnerSecondary = ({ className, light=false }) => {
    return <NewLoader size="sm" className={className} light={light}/>
}

/*export const CircularSpinnerSecondary = ({ className, fillColor = "currentColor", strokeColor = "currentColor" }) => {
    return (
        <svg className={classNames("animate-spin mr-2 h-5 w-5 text-white", className)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke={strokeColor} strokeWidth="4"></circle>
            <path className="opacity-75" fill={fillColor} d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
    )
}*/