import { Tooltip } from "react-tooltip";
import { TableCell, TableHeadCell } from "../../kit/Table";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Delete, Get } from "../../helpers/requests";
import { deleteSchedule, loadSchedules } from "../../slices/dataSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { settings } from "../../settings";
import { TrashIcon, PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import { WarningWindow } from "../../kit/WarningWindow";
import { CircularSpinnerSecondary, FullScreenLoader } from "../../kit/Spinners";
import { Paths } from "../../helpers/navigationHelper";
import { classNames } from "../application/ApplicationHeader";
import { Modals } from "../application/modals/ModalsCommon";
import { setModal } from "../../slices/userSlice";
import { processAxiosError } from "../util/ErrorBoundary";
import { useMixedData } from "../../hooks/useMixedData";
import axios from "axios";
import { ErrorPage } from "../../kit/ErrorPage";
import { DummyRow } from "../repositories/VirtualizedRepoTable";
import { LongTableWrapper, LongTextTag } from "../../kit/LongInvisibleInput";
import { BaseTransition } from "../../kit/Transitions";

const ScheduleRows = ({ ikey, groupName, schedules, onDelete, isCustomSchedules, deleteBusy, isLoading }) => {

    return (
        <Fragment key={ikey}>
            <BaseTransition>
                <tr className="border-t border-gray-200 w-full">
                    <td
                        colSpan={isCustomSchedules && !isLoading ? 1 : 3}
                        scope="colgroup"
                        className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-xs text-gray-900 sm:pl-3"
                    >
                        {groupName}
                    </td>
                    {!isLoading && isCustomSchedules &&
                        <td
                            colSpan={2}
                            scope="colgroup"
                            className="w-max bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-xs text-gray-900 sm:pl-3"
                        >
                            <div className="flex justify-end w-full">
                                <Link to={Paths.schedules.newSchedule.path}
                                    className="whitespace-nowrap inline-flex align-middle items-center rounded text-white px-2 py-1 text-xs font-semibold bg-green-600 shadow-sm hover:bg-green-500"
                                >
                                    <PlusIcon className="w-4 h-4 mr-1" />
                                    Add a new schedule
                                </Link>
                            </div>
                        </td>
                    }
                </tr>
            </BaseTransition>
            {!isLoading && isCustomSchedules && schedules.length === 0 &&
                <BaseTransition>
                    <tr>
                        <td className="text-gray-700" scope="colgroup" colSpan={3}>
                            <div className="flex justify-center py-3">
                                <h3 className="font-normal text-sm">You haven't configured custom schedules yet</h3>
                            </div>
                        </td>
                    </tr>
                </BaseTransition>
            }
            {isLoading && [1, 2].map(x => <tr key={x}><DummyRow colSpan={3} /></tr>)}
            {!isLoading && schedules.map((schedule, id) =>
                <BaseTransition key={id} >
                    <tr key={id} className="text-gray-700">
                        <TableCell className={classNames("py-4 pl-4 pr-3 text-gray-700 !font-medium")} >
                            <LongTableWrapper>
                                <LongTextTag value={schedule.name} />
                            </LongTableWrapper>
                        </TableCell>
                        <TableCell className={classNames("w-max whitespace-nowrap px-3 py-4 text-gray-600 !font-medium")} >{schedule.cron}</TableCell>
                        <TableCell className="px-3 py-4">
                            {schedule.scheduleId >= 100 &&
                                <div className="flex float-right">
                                    <Link className="mr-2" to={Paths.schedules.editSchedule.linkPath(schedule.scheduleId)} data-tooltip-id="edit-schedule-tooltip" data-tooltip-content="Edit Schedule">
                                        <button className="rounded-md bg-gray-600 p-1 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                            data-tooltip-id="edit-schedule-tooltip" data-tooltip-content="Edit Schedule">
                                            <PencilIcon className="h-5 w-5" />
                                        </button>

                                    </Link>
                                    <Tooltip id="edit-schedule-tooltip" className="z-[6]" />
                                    <button className="rounded-md bg-red-400 p-1 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                        data-tooltip-id="delete-schedule-tooltip" data-tooltip-content="Delete Schedule"
                                        onClick={() => onDelete(schedule.scheduleId)} disabled={deleteBusy}>
                                        {deleteBusy ? <CircularSpinnerSecondary light={true} /> : <TrashIcon className="h-5 w-5" />}
                                    </button>
                                    <Tooltip id="delete-schedule-tooltip" className="z-[6]" />
                                </div>}
                        </TableCell>
                    </tr>
                </BaseTransition>
            )}
        </Fragment>
    )
}

export const SchedulesList = () => {

    const dispatch = useDispatch();

    const [schedules, isLoading, pageError, tryAgain] = useMixedData(() => axios.get(settings.backendUrl + "/v2/schedules"), (res, dispatch) => {
        dispatch(loadSchedules(res.data.result));
    }, state => state.data.schedules, undefined, (data) => data !== undefined && data !== null && Object.keys(data).length > 0);

    const [deleteBusy, setDeleteBusy] = useState(false);
    const onDelete = (id) => {
        const schedule = schedules[id];
        if (schedule !== undefined && schedule !== null) {
            setDeleteBusy(true);
            axios.get(settings.backendUrl + "/v2/schedules/delete?scheduleId=" + schedule.scheduleId)
                .then(res => {
                    if (res.data.isSuccess) {
                        dispatch(deleteSchedule(schedule));
                    }
                    else {
                        dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to delete", text: res.data.errorDescription }));
                    }
                })
                .catch(e => {
                    processAxiosError(e);
                    dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unable to delete", text: "The schedule couldn't be deleted. Please try again later." }));

                })
                .finally(() => setDeleteBusy(false));
        }
    };

    const sortedSchedules = useMemo(() => Object.values(schedules).sort((x, y) => y.scheduleId - x.scheduleId), [schedules]);
    return pageError !== null ? <ErrorPage canTryAgain={true} tryAgainAction={tryAgain} /> : (
        <div className="w-full bg-gray-100">
            <div className="w-full px-3 mx-auto max-w-xl md:max-w-4xl mt-2 pb-6">
                <table className="min-w-full divide-y divide-gray-300 shadow ring-1 ring-black ring-opacity-5 rounded-t-lg">
                    <thead className="bg-gray-50">
                        <tr>
                            <TableHeadCell stickyPage={true} className="whitespace-nowrap font-bold py-3 pl-4 pr-3 rounded-tl-lg" >Schedule name</TableHeadCell>
                            <TableHeadCell stickyPage={true} className="whitespace-nowrap font-bold px-3 py-3.5">Cron value</TableHeadCell>
                            <TableHeadCell stickyPage={true} className="px-3 py-3.5 rounded-tr-lg"></TableHeadCell>
                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        <ScheduleRows ikey="custom"
                            isLoading={isLoading}
                            isCustomSchedules={true}
                            onDelete={onDelete} deleteBusy={deleteBusy} groupName="Customer managed schedules"
                            schedules={sortedSchedules.filter(x => x.scheduleId > 100)}
                        />
                        <ScheduleRows ikey="system" isLoading={isLoading} onDelete={onDelete} groupName="System schedules"
                            schedules={sortedSchedules.filter(x => x.scheduleId <= 100)}
                        />
                    </tbody>
                </table>
            </div>
        </div>
    )
}