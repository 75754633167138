import { ClipboardIcon } from "@heroicons/react/24/outline"
import { classNames } from "../../application/ApplicationHeader"
import { useEffect, useRef, useState } from "react"
import axios from "axios";
import { isDevelopment, settings } from "../../../settings";
import { Tooltip } from "react-tooltip";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNotifier } from "../../../hooks/notifications/notificationActions";
import { SelectBox } from "../../../kit/SelectBox";
import { CircularSpinnerSecondary, FullScreenLoader } from "../../../kit/Spinners";
import { ErrorAlert, GreenSimpleAlert, WarningAlert } from "../../../kit/Alerts";
import { useDispatch } from "react-redux";
import { setModal } from "../../../slices/userSlice";
import { Modals } from "../../application/modals/ModalsCommon";
import { processAxiosError } from "../../util/ErrorBoundary";
import { ButtonType, FormButton } from "../../../kit/Buttons";

export const StorageWrapperComponent = ({ providers, className, children }) => {
    return (
        <div id="onedrive-picker">
            {
                providers.isLoading ? <FullScreenLoader className="mt-10" title={null} /> :
                    <ProviderSettingsWrapper className={className}>
                        {children}
                    </ProviderSettingsWrapper>
            }
        </div>
    )
}

export const useStorageSection = (onCancel, onTest, onComplete, connStringFactory, editMode, setPageError) => {

    const [testBusy, setTestBusy] = useState(false);
    const [saveBusy, setSaveBusy] = useState(false);
    const providers = useProviders(setPageError);
    const [notifySuccess] = useNotifier();
    const dispatch = useDispatch();
    const onTestResult = (result) => {
        setTestBusy(false);
        if (result.error !== undefined) {
            dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unexpected error occured", text: "Please try again or contact support" }));
            return;
        }
        if (!result.isValid) {
            dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Storage test failed", text: result.messages.join(";") }));
            return;
        }
        dispatch(setModal({ id: Modals.SuccessModalWithSingleCloseButton, title: "Storage test succeeded", 
            text: result.archiveCustomName!==null && result.archiveCustomName!==undefined? 
            <p>
                Archive Name Pattern test resulted to
                <br/>
                <b>{result.archiveCustomName}</b>
            </p>:"" }));
    }

    const onCompleteResult = (result) => {
        //if success - exit, if not - show what's happened (ex - storage name is empty)
        setSaveBusy(false);
        if (result.error !== undefined) {
            dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Unexpected error occured", text: "Please try again or contact support" }));
            return;
        }
        if (!result.isSuccess) {
            dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Storage not " + (editMode ? "updated" : "created"), text: result.errorDescription }));
            return;
        }
        notifySuccess("Storage " + (editMode ? "updated" : "created"));
        onCancel();
    }

    const onTestInner = () => {
        setTestBusy(true);
        onTest(connStringFactory(), onTestResult);
    }

    const onCompleteInner = () => {
        setSaveBusy(true);
        onComplete(connStringFactory(), onCompleteResult);
    }

    return { testBusy, saveBusy, providers, alert, onTest: onTestInner, onComplete: onCompleteInner, onCancel }
}

export const useBroadcastChannel = () => {
    const [busy, setBusy] = useState(false);
    const [extParams, setExtParams] = useState(null);
    const popupInterval = useRef();
    const broadcast = new BroadcastChannel("CloudbackAuth");
    useEffect(() => {
        const messageHandler = event => {           
            if (event.origin.startsWith(settings.currentUrl)) {
                if (event.data.oauth !== undefined) return;
                setExtParams(event.data);
                if (popupInterval.current !== null) clearInterval(popupInterval.current);
                broadcast.postMessage({ close: true });
                setBusy(false);
            }
        };
        broadcast.addEventListener("message", messageHandler);
        return () => {
            broadcast.removeEventListener("message", messageHandler);
        }
    }, [])
    const dispatch = useDispatch();
    const checkExit = (popup, exitTitle) => {
        popupInterval.current = setInterval(() => {
            if (popup.closed) {
                clearInterval(popupInterval.current);
                dispatch(setModal({ id: Modals.WarningModalWithSingleCloseButton, title: exitTitle, text: "Popup was closed. Please try again" }));
                setBusy(false);
            }
        }, 500);
    }

    return { busy, setBusy, extParams, checkExit }
}

export const urlToParams = query => {
    const q = query.replace(/^\??\//, "");
    return q.split("&").reduce((values, param) => {
        const [key, value] = param.split("=");
        values[key] = value;
        return values;
    }, {});
};

export const useProviders = (setPageError) => {
    const [isLoading, setIsLoading] = useState(true);
    const [providers, setProviders] = useState({});

    const loadProviders = () => {
        setIsLoading(true);
        axios.get(settings.backendUrl + "/api/v2/providers")
            .then(res => {
                if (res.data.isSuccess) {
                    setProviders(res.data.result);
                }
                else {
                    setPageError(res.data.errorDescription);
                }
            })
            .catch(e => {
                processAxiosError(e);
                setPageError("Error occured while loading providers");
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        loadProviders();
    }, [])

    return { list: providers, isLoading }
}


export const ProviderSettingsWrapper = (props) => {
    return (
        <div className={classNames("w-full h-full divide-y divide-gray-200 rounded-lg bg-white shadow-sm shadow-gray-400 px-4", props.className)}>
            <div className="pt-6">
                <div className="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export const ProviderStepWrapper = (props) => {
    return (
        <>
            <div className="sm:col-span-1 flex items-center">
                <p className="font-bold mr-2">{props.stepTitle}:</p>
            </div>
            <div className="sm:col-span-5 flex flex-row items-center">
                {props.children}
            </div>
            <div className="col-span-full border-b border-gray-900/10" />
        </>
    )
}

export const ProviderStepWithSelectAndTextInput = (props) => {
    return (
        <>
            <div className="sm:col-span-1 flex items-center">
                <p className="font-bold mr-2">{props.stepTitle}:</p>
            </div>
            <div className="sm:col-span-5">
                <div className="flex flex-col">
                    {props.children}
                    <div className="mt-2 grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-12">
                        <div className="sm:col-span-3 text-sm self-center">
                            {props.selectTitle}
                        </div>
                        <div className="sm:col-span-9 self-center">
                            <SelectBox
                                options={props.options}
                                selected={props.selected}
                                setSelected={props.setSelected}
                                keySelector={props.keySelector}
                                labelSelector={props.labelSelector}
                            />
                        </div>
                        <div className="sm:col-span-3 text-sm self-center">
                            {props.inputTitle}
                        </div>
                        <div className="sm:col-span-9 self-center">
                            <input
                                placeholder={props.inputPlaceholder || ''}
                                className={classNames("resize-none block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6", props.secondTextClassName)}
                                value={props.inputValue}
                                onChange={(e) => props.setInputValue === undefined ? e => { } : props.setInputValue(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-full border-b border-gray-900/10" />
        </>
    )
}

export const ProviderStepWithDoubleTextInput = (props) => {
    return (
        <>
            <div className="sm:col-span-1 flex items-center">
                <p className="font-bold mr-2">{props.stepTitle}:</p>
            </div>
            <div className="sm:col-span-5">
                <div className="flex flex-col">
                    {props.children}
                    <div className="mt-2 grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-12">
                        <div className="sm:col-span-3 text-sm self-center">
                            {props.firstTitle}
                        </div>
                        <div className="sm:col-span-9">
                            <input
                                placeholder={props.firstPlaceholder || ''}
                                className={classNames("resize-none block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6", props.firstTextClassName)}
                                value={props.firstValue}
                                onChange={(e) => props.setFirstValue === undefined ? e => { } : props.setFirstValue(e.target.value)}
                            />
                        </div>
                        <div className="sm:col-span-3 text-sm self-center">
                            {props.secondTitle}
                        </div>
                        <div className="sm:col-span-9">
                            <input
                                placeholder={props.secondPlaceholder || ''}
                                className={classNames("resize-none block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6", props.secondTextClassName)}
                                value={props.secondValue}
                                onChange={(e) => props.setSecondValue === undefined ? e => { } : props.setSecondValue(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-full border-b border-gray-900/10" />
        </>
    )
}

export const ProviderStepWithSelectBox = (props) => {
    return (
        <>
            <div className="sm:col-span-1 flex items-center">
                <p className="font-bold mr-2">{props.stepTitle}:</p>
            </div>
            <div className="sm:col-span-5">
                <div className="flex flex-col">
                    {props.children}
                    <div className="mt-2 grid grid-cols-1 gap-x-2 gap-y-2 sm:grid-cols-12">
                        {props.selectTitle !== undefined && <div className="sm:col-span-3 text-sm self-center">
                            {props.selectTitle}
                        </div>}
                        <div className={props.selectTitle === undefined ? "col-span-full self-center" : "sm:col-span-9 self-center"}>
                            {props.optionsLoading ?
                                <CircularSpinnerSecondary /> :
                                <SelectBox
                                    options={props.options}
                                    selected={props.selected}
                                    setSelected={props.setSelected}
                                    keySelector={props.keySelector}
                                    labelSelector={props.labelSelector}
                                    leftContentSelector={props.leftContentSelector}
                                />
                            }

                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-full border-b border-gray-900/10" />
        </>
    )
}

export const ProviderStepWithTextInput = (props) => {
    const [notifySuccess, notifyInfo] = useNotifier();
    return (
        <>
            <div className="sm:col-span-1 flex items-center">
                <p className="font-bold mr-2">{props.stepTitle}:</p>
            </div>
            <div className="sm:col-span-5">
                <div className="flex flex-col">
                    {props.children}
                    <div className="mt-2 flex flex-grow items-stretch">
                        <input
                            placeholder={props.placeholder || ''}
                            className={classNames(props.copy ? "rounded-l-md" : "rounded-md", "resize-none block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6", props.textClassName)}
                            value={props.value}
                            onChange={(e) => props.setValue === undefined ? e => { } : props.setValue(e.target.value)}
                        />
                        {props.copy &&
                            <>
                                <CopyToClipboard text={props.value} onCopy={() => notifySuccess("Copied")}>
                                    <button
                                        data-tooltip-id="copy-tooltip" data-tooltip-content="Copy"
                                        type="button"
                                        className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    >
                                        <ClipboardIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>

                                </CopyToClipboard>
                                <Tooltip id="copy-tooltip" />
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="col-span-full border-b border-gray-900/10" />
        </>
    )
}

export const ProviderStepWithTextArea = (props) => {
    const [notifySuccess, notifyInfo] = useNotifier();
    return (
        <>
            <div className="sm:col-span-1 flex items-center">
                <p className="font-bold mr-2">{props.stepTitle}:</p>
            </div>
            <div className="sm:col-span-5">
                <div className="flex flex-col">
                    {props.children}
                    <div className="mt-2 relative">
                        <textarea
                            rows={4}
                            readOnly={props.readOnly || false}
                            className={classNames("resize-none block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6", props.textClassName)}
                            value={props.value}
                            onChange={props.setValue === undefined ? e => { } : (e) => props.setValue(e.target.value)}
                        />
                        {props.copy &&
                            <>
                                <CopyToClipboard text={props.value} onCopy={() => notifySuccess("Copied")}>
                                    <button
                                        data-tooltip-id="copy-tooltip" data-tooltip-content="Copy"
                                        type="button"
                                        className="absolute top-1 right-5 rounded-full bg-white p-1 text-gray-600 shadow-md hover:text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                    >
                                        <ClipboardIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>

                                </CopyToClipboard>
                                <Tooltip id="copy-tooltip" />
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="col-span-full border-b border-gray-900/10" />
        </>
    )
}

export const ProviderTestAndSave = ({ canContinue, nameIncluded, storageEditor }) => {
    const canContinueSave = canContinue && nameIncluded;
    return storageEditor.providers.isLoading ? <></> : (
        <div className="sm:col-span-full">
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <FormButton onClick={storageEditor.onCancel} btnText="Cancel" buttonType={ButtonType.Cancel} />
                <FormButton busy={storageEditor.testBusy || storageEditor.saveBusy}
                    disabled={!canContinueSave || storageEditor.testBusy || storageEditor.saveBusy}
                    onClick={storageEditor.onComplete}
                    btnText={storageEditor.saveBusy ? "Saving" : "Save"}
                    showTooltip={!canContinueSave}
                    tooltipId="disabled-tooltip"
                    tooltipText="Not all steps in the form completed or storage name is blank" />
                <FormButton busy={storageEditor.testBusy || storageEditor.saveBusy}
                    disabled={!canContinue || storageEditor.testBusy || storageEditor.saveBusy}
                    onClick={storageEditor.onTest}
                    btnText={storageEditor.testBusy ? "Testing" : "Test"}
                    showTooltip={!canContinue}
                    tooltipId="disabled-tooltip-test"
                    tooltipText="Not all steps in the form completed"
                    buttonType={ButtonType.Secondary}
                />
            </div>
        </div>
    )
}