import { Fragment, memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deselectCard, selectCard } from "../../slices/dataSlice";
import { selectCard as selectStoreCard } from "../../helpers/domainHelpers"
import { cardCanBeSelectedOrBackedUp, getBackupStatus, getCard, getScheduleStatus, StorageImg } from "../../helpers/domainHelpers";
import { TableCell } from "../../kit/Table";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { Paths } from "../../helpers/navigationHelper";
import { createSelector } from "@reduxjs/toolkit";
import { ClockIcon, LockIcon, RepoIcon } from "@primer/octicons-react";
import { getGitHubAvatarUrl } from "../../settings";
import { LongInvisibleInput, LongTableWrapper, LongTextLink, LongTextTag } from "../../kit/LongInvisibleInput";
import { Transition } from "@headlessui/react";
import { classNames } from "../application/ApplicationHeader";

export const SelectBoxTableCell = memo((props) => {
  const isSelected = useSelector((state) => props.repoId in state.data.selectors.selectedCards);
  const card = useSelector((state) => selectStoreCard(state, props.repoId));
  const disabled= useMemo(()=>!cardCanBeSelectedOrBackedUp(card),[card]);
  const dispatch = useDispatch();
  const handleSelect = () => {
    if (isSelected) {
      dispatch(deselectCard(props.repoId));
    }
    else {
      dispatch(selectCard(props.repoId));
    }
  }

  return (
    <TableCell padding="checkbox" className="border-b border-gray-200">
      <input
        type="checkbox"
        readOnly
        disabled={disabled}
        className="h-6 w-6 rounded border-gray-300 hover:bg-gray-300 hover:text-gray-300 text-gray-600"
        checked={isSelected}
        onClick={handleSelect}
      />
    </TableCell>
  );
})

export const RepoNameCell = memo((props) => {
  const card = useSelector((state) => selectStoreCard(state, props.repoId));
  const disabled= useMemo(()=>!cardCanBeSelectedOrBackedUp(card),[card]);
  return (
    <TableCell scope="row" className="border-b border-gray-200">
      <div className="flex items-center">
        <div className="relative inline-block pr-2">
          <img
            className="min-h-8 min-w-8 h-8 w-8 rounded-md"
            src={getGitHubAvatarUrl(card.repository.owner, 40)}
            alt=""
          />
          <span>
            {card.repository.isPrivate ?
              <LockIcon className="absolute right-0 bottom-0 block h-3 w-3 rounded-sm bg-white -translate-x-2/4" />
              :
              <RepoIcon className="absolute right-0 bottom-0 block h-3 w-3 rounded-sm bg-white -translate-x-2/4" />
            }
          </span>
        </div>
        <LongTableWrapper>
          <LongTextLink href={Paths.dashboard.repositoryDetails.linkPath(props.repoId)} className={classNames("hover:underline pr-2 text-sm font-medium text-gray-700",disabled?"line-through":"")}
          >
            {`${card.repository.owner} / ${card.repository.name}`}
          </LongTextLink>
        </LongTableWrapper>
      </div>
    </TableCell>
  );
})
//<LongInvisibleInput value={`${card.repository.owner} / ${card.repository.name}`} className="pr-2 text-sm font-medium text-gray-700 w-full"/>
export const selectScheduleStatus = createSelector(
  [state => state.data.dashboard.cards, (state, repoId) => repoId],
  (cards, repoId) => getScheduleStatus(getCard(cards, repoId)));

export const ScheduleCell = memo((props) => {

  const scheduleStatus = useSelector(s => selectScheduleStatus(s, props.repoId));
  return (
    <TableCell className="border-b border-gray-200">
      {scheduleStatus.Text !== "Not scheduled" && <div className={`w-max inline-flex items-center font-normal rounded-md !bg-gray-50 px-2 py-1 text-xs ${scheduleStatus.Style} items-center flex`}>
        <ClockIcon className="mr-2 h-4 w-4" style={scheduleStatus.ImgStyle} alt="clock" />
        <p className="font-medium">{scheduleStatus.Text}</p>
      </div>}
    </TableCell>
  );
})

export const StorageCell = memo((props) => {
  const card = useSelector((state) => selectStoreCard(state, props.repoId));
  return (
    <TableCell className="border-b border-gray-200">
      <div className="w-max items-center flex text-sm font-normal">
        <StorageImg className="mr-1 w-5 h-5 min-h-5 min-w-5" alt="storage-img" isCustomStorage={card.storage.isCustomStorage} storageName={card.storage.storageName} provider={card.storage.provider} />
        <LongTableWrapper className="max-w-[200px] !min-w-[160px]">
          <LongTextTag className="font-medium text-gray-600" value={card.storage.storageName ?? "nothing selected"} />
        </LongTableWrapper>
      </div>
    </TableCell>
  );
})

export const selectBackupStatus = createSelector([state => state.data.dashboard.cards, (state, repoId) => repoId],
  (cards, repoId) => {
    let card = getCard(cards, repoId);
    if (card === null || card === undefined || card.definitionId === null) return getBackupStatus(null);

    return getBackupStatus(card.lastBackup);
  }
);


export const StatusCell = memo((props) => {
  const backupStatus = useSelector((state) => selectBackupStatus(state, props.repoId));
  return (
    <TableCell className="border-b border-gray-200">
      <div className={`w-max inline-flex items-center font-normal rounded-md !bg-gray-50 px-2 py-1 text-xs ${backupStatus.Style}`}>
        <p className="font-medium">{backupStatus.Text}</p>
      </div>
    </TableCell>
  );
})

export const RowInner = memo((props) => {
  const repoId = props.repoId;
  return (
    <>
      <SelectBoxTableCell repoId={repoId}  />
      <RepoNameCell repoId={repoId}/>
      <ScheduleCell repoId={repoId} />
      <StorageCell repoId={repoId} />
      <StatusCell repoId={repoId} />
      <TableCell className="border-b border-gray-200">
        <Link to={Paths.dashboard.repositoryDetails.linkPath(repoId)}>
          <button
            type="button"
            data-tooltip-id="details-tooltip" data-tooltip-content="Details"
            className="rounded-full bg-white p-1 text-gray-600 hover:bg-gray-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
          >
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </Link>
      </TableCell>
    </>
  );
}, (prevProps, newProps) => prevProps.repoId === newProps.repoId);
