export const LoadedStatus = {
    loading: "loading",
    loaded: "loaded"
}

export const DataRowType = {
    basic: "basic",
    subtables: "subtables",
    settings: "settings"
}

export const BackupStatus = {
    undefined: "undefined",
    inProgress : "inProgress",
    succeeded : "succeeded",
    failed : "failed",
    deleted : "deleted",
    queued : "queued",
    downloadedRepo : "downloadedRepo",
    downloadedData : "downloadedData",
    archived : "archived",
    uploaded : "uploaded"
  }
  
  export const RestoreStatus = {
    undefined : "undefined",
    queued : "queued",
    inProgress : "inProgress",
    succeeded : "succeeded",
    failed : "failed",
    downloaded : "downloaded",
    restoredRepo : "restoredRepo",
  }
  
  export const TriggerType = {
    undefined: "undefined",
    manual:"manual",
    scheduled:"scheduled"
  }
  
  export const ErrorCode = {
    success : "success",
    internal : "internal",
    unauthorized : "unauthorized",
    duplicateEntity : "duplicateEntity",
    entityNotFound : "entityNotFound",
    validationError : "validationError",
    malformedInput : "malformedInput",
    notSupported : "notSupported",
    storageProviderError : "storageProviderError",
    backupNotFound : "backupNotFound",
    installationNotFound : "installationNotFound",
    multipleInstallations : "multipleInstallations",
    tooManyRequests : "tooManyRequests",
    brokenArchive : "brokenArchive",
    restoreNotFound : "restoreNotFound",
    repositoryCreationError : "repositoryCreationError",
    alreadyExists : "alreadyExists",
    unknownMarketplacePlan : "unknownMarketplacePlan",
    commitNoFound : "commitNoFound",
    timeout : "timeout",
    repositoryNotFound : "repositoryNotFound",
    external : "external",
    rateLimitExceeded : "rateLimitExceeded",
    sizeLimitExceeded : "sizeLimitExceeded"
  }
  
  export const ArchivedBehaviour = {
    doNotShowDoNotBackup:"doNotShowDoNotBackup",
    showAndBackup:"showAndBackup",
    undefined: "undefined"
  }

  export const ScheduleStatus = {
    notScheduled:"notScheduled",
    scheduled:"scheduled",
    restoreOnly:"restoreOnly",
    repositoryNotAccessible:"repositoryNotAccessible"
  }
  
  export const BackupDefinitionState ={
    undefined : "undefined",
    canBackupAndRestore : "canBackupAndRestore",
    canRestoreOnly : "canRestoreOnly",
    deleted : "deleted"
  }
  
  export const AccountTypeEnum= {
    user:"user",
    organization:"organization",
    bot:"bot"
  }
  
  export const BackupStorageProvider ={
    undefined : "undefined",
    azureBlobContainer : "azureBlobContainer",
    amazonS3AccessPoint : "amazonS3AccessPoint",
    amazonS3Glacier : "amazonS3Glacier",
    googleCloudStorageBucket : "googleCloudStorageBucket",
    alibabaObjectStorageService : "alibabaObjectStorageService",
    amazonS3AccessKey : "amazonS3AccessKey",
    openStackSwiftS3 : "openStackSwiftS3",
    microsoftOneDrivePersonal : "microsoftOneDrivePersonal",
    microsoftOneDriveBusiness : "microsoftOneDriveBusiness",
    wasabiS3AccessKey : "wasabiS3AccessKey",
    compositeStorage:"compositeStorage"
  }

  export const InstantNotificationType={
    disabled:"disabled",
    onCompletion:"onCompletion",
    onFailureOnly:"onFailureOnly"
  }

  export const InstantNotificationMessengerType={
    none:"none",
    discord:"discord",
    microsoftTeams:"microsoftTeams",
    slack:"slack"
  }