import { useEffect, useState } from "react";
import Turnstile from "react-turnstile";
import { dynamicSettings, settings } from "../../settings";

export const useCaptcha = ({onSuccess,onError,onLoad=()=>{}})=>{
    const [bound, setBound] = useState(null);

    useEffect(()=>{
        if (bound!==null)onLoad();
    },[bound]);

    const [captchaErrorMessage, setCaptchaErrorMessage] = useState(null);
    const success = (token) => {
        onSuccess(token);
        bound.reset();
    }

    const error = (e)=>{
        setCaptchaErrorMessage("Unable to verify you are human.");
        onError();
        bound.reset();
    }

    const unsupported = ()=>{
        setCaptchaErrorMessage("This browser is unsupported.");
        onError();
        bound.reset();
    }
   
    return {
        bound,
        setBound,
        captchaErrorMessage,
        clear: ()=>setCaptchaErrorMessage(null),
        onSuccess: success,
        onError: error,
        onUnsupported: unsupported
    };
}
export const CaptchaWidget = ({captcha})=>{
    const onLoaded = (bound)=>{
        captcha.setBound(bound);
    }
    return(
        <Turnstile
        id="bla"
        onVerify={captcha.onSuccess}
        onError={captcha.onError}
        onUnsupported={captcha.onUnsupported}
        onLoad={(widgetId, bound)=>onLoaded(bound)}
        execution="execute"
        theme="light"
        retry="never"
        appearance="execute"
        sitekey={dynamicSettings.turnstile} />
    )
}