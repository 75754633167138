import { Link } from "react-router-dom"
import { classNames } from "../components/application/ApplicationHeader"

export const LongTableWrapper = ({ children, className }) => {
    return (
        <table className={classNames("table-fixed w-full min-w-[200px]", className)}>
            <tbody>
                <tr>
                    <td>
                        {children}
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export const LongTextTag = ({ value, className }) => {
    return (
        <p className={classNames("align-middle overflow-hidden w-fit max-w-full whitespace-nowrap truncate", className)}>{value}</p>
    )
}

export const LongTextLink = ({ children, className, href, ...props }) => {
    return (
        <Link to={href} {...props} className={classNames("align-middle inline-block overflow-hidden w-fit max-w-full whitespace-nowrap truncate", className)}>{children}</Link>
    )
}

export const LongInvisibleInput = ({ value, className, isLink }) => {
    return (
        <input className={classNames("align-middle w-full outline-none border-none p-0 truncate focus:outline-none focus:ring-0 focus:ring-offset-0 focus:border-none", isLink ? "hover:underline hover:cursor-pointer " : "", className)} readOnly defaultValue={value} />
    )
}