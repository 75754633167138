import { useDispatch, useSelector } from "react-redux"
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { getGitHubAvatarUrl, settings } from "../../settings";
import { loadBackups, loadDashboard } from "../../slices/dataSlice";
import { createSelector } from "@reduxjs/toolkit";
import { LoadingAutocomplete, useAutocomplete, useDependentAutocomplete } from "../../kit/Autocomplete";
import { Tooltip } from "react-tooltip";
import { ButtonType, FormButton } from "../../kit/Buttons";
import { processAxiosError } from "../util/ErrorBoundary";
import { BackupStatus } from "../../model/domain";

const filterOut = (backups, defId) => {
    return backups[defId] === undefined ? {} : backups[defId]
        .reduce((acc, val) => ({ ...acc, [val.backupId]: { id: val.backupId, label: val.startTimeText, status: val.status, startTimeUtc: val.startTimeUtc } }), {});
}

const repoSelector = createSelector([state => state.data.dashboard.cards, (state, userValue) => userValue],
    (cards, userValue) => userValue === null ? {} : cards.filter(x => x.repository.owner === userValue)
        .reduce((acc, val) => ({ ...acc, [val.repositoryId]: { id: val.repositoryId, label: val.repository.name, defId: val.definitionId, isPrivate: val.repository.isPrivate } }), {}))

const backupSelector = createSelector([
    state => state.data.dashboard.loadedBackups,
    (state, repoValue) => repoValue,
    (state, repoValue, isBackupLoading) => isBackupLoading
], (loadedBackups, repoValue, isBackupLoading) => repoValue === null || isBackupLoading ? {} : filterOut(loadedBackups, repoValue.defId))


const RestoreUserSelector = ({ initialUserName, user, setUser, isDashboardLoading }) => {

    const accountNames = useSelector((state) => state.data.dashboard.accountNames);
    const accountSettings = useMemo(() => accountNames.reduce((acc, v) => { acc[v] = v; return acc; }, {}), [accountNames]);
    const autocomplete = useAutocomplete(accountSettings, isDashboardLoading, user !== null ? user : initialUserName, user, setUser);

    return (
        <div className="sm:col-span-full">
            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                Select source account
            </label>
            <div className="mt-2">
                <LoadingAutocomplete isLoading={autocomplete.isLoading}
                    autocomplete={{
                        ...autocomplete,
                        labelSelector: (option) => option,
                        keySelector: (option) => option,
                        optionLeadingContentSelector: (option) => <img
                            className="h-8 w-8 rounded-md"
                            src={getGitHubAvatarUrl(option)}
                            alt=""
                        />,
                        size: "lg"
                    }}
                />
            </div>
        </div>
    )
}

const RestoreRepoSelector = ({ initialRepoId, user, isDashboardLoading, repo, setRepo }) => {
    const repos = useSelector(state => repoSelector(state, user));
    const autocomplete = useDependentAutocomplete(repos, isDashboardLoading, repo !== null ? repo.id : initialRepoId, repo, setRepo, user,undefined, "repo");

    return (
        <div className="sm:col-span-full">
            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                Select repository to restore
            </label>
            <div className="mt-2">
                <LoadingAutocomplete isLoading={autocomplete.isLoading}
                    autocomplete={{
                        ...autocomplete,
                        labelSelector: (option) => option?.label,
                        keySelector: (option) => option?.id,
                    }}
                />
            </div>
        </div>
    )
}

const RestoreBackupSelector = ({ initialBackupId, backup, setBackup, isBackupLoading, repo }) => {
    const backups = useSelector(state => backupSelector(state, repo, isBackupLoading));
    const filteredBackups = useMemo(()=>
    {        
        var succeededBackups = Object.values(backups).filter(x=>x.status === BackupStatus.succeeded);
        return succeededBackups.length === 0 ? {}:
        succeededBackups.reduce((acc, val) => ({ ...acc, [val.id]: val }),{});
    }
    , [backups]);
    
    const autocomplete = useDependentAutocomplete(filteredBackups, isBackupLoading, backup !== null ? backup.id : initialBackupId, backup, setBackup, repo,
        (a, b) => new Date(b.startTimeUtc) - new Date(a.startTimeUtc),"backup");

    return (
        <div className="sm:col-span-full">
            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                Select backup to restore
            </label>
            <div className="mt-2">
                <LoadingAutocomplete isLoading={autocomplete.isLoading}
                    autocomplete={{
                        ...autocomplete,
                        labelSelector: (option) => option?.label,
                        keySelector: (option) => option?.id,
                    }}
                />
            </div>
        </div>
    )
}

export const RestoreStepOne = ({ initialUserName, initialRepoId, initialBackupId, onNext, onCancel, user, repo, backup, setUser, setRepo, setBackup, setPageError }) => {

    const [isDashboardLoading, setIsDashboardLoading] = useState(true);
    const [isBackupLoading, setIsBackupLoading] = useState(true);
    const dashboardLoaded = useSelector(state => state.data.dashboard.loaded);

    const onRepoChange = (r) => {
        const oldRepoId = repo?.id;
        setRepo(r);
        if (r !== null && oldRepoId !== r.id) {
            getBackups(r.defId);
        }
        else { setIsBackupLoading(false); }
    }

    const dispatch = useDispatch();
    const getDashboard = () => {
        setIsDashboardLoading(true);
        axios.get(settings.backendUrl + "/v2/dashboard")
            .then((res) => {
                if (res.data.isSuccess) {
                    dispatch(loadDashboard(res.data.result));
                }
                else {
                    setPageError(res.data.errorDescription);
                }
            })
            .catch(e => {
                processAxiosError(e);
                setPageError(e);
            })
            .finally(() => {
                setIsDashboardLoading(false);
            })
    }

    const getBackups = (defId) => {
        setIsBackupLoading(true);
        axios.get(settings.backendUrl + "/v2/backups?definitionId=" + defId)
            .then((res) => {
                if (res.data.isSuccess) {
                    dispatch(loadBackups(res.data.result));
                }
                else {
                    setPageError(res.data.errorDescription)
                }
            })
            .catch((e) => {
                processAxiosError(e);
                setPageError(e);
            })
            .finally(() => {
                setIsBackupLoading(false);
            });
    }

    useEffect(() => {
        if (!dashboardLoaded) {
            getDashboard();
        }
        else {
            setIsDashboardLoading(false);
        }
    }, [])

    const canContinue = user !== null && repo !== null && backup !== null;
    const formLoading = isDashboardLoading || isBackupLoading;

    return (
        <div className="space-y-3">
            <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Source</h2>
                <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <RestoreUserSelector initialUserName={initialUserName} user={user} setUser={setUser} isDashboardLoading={isDashboardLoading} />
                    <RestoreRepoSelector initialRepoId={initialRepoId} isDashboardLoading={isDashboardLoading} repo={repo} setRepo={onRepoChange} user={user} />
                    <RestoreBackupSelector initialBackupId={initialBackupId} isBackupLoading={isBackupLoading} repo={repo} backup={backup} setBackup={setBackup} />
                </div>
            </div>
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-full">
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <FormButton onClick={onNext} disabled={!canContinue} loading={formLoading} btnText="Next"
                            tooltipId="disabled-tooltip" tooltipText="Not all fields selected" showTooltip={!canContinue} />
                        <FormButton onClick={onCancel} btnText="Cancel" buttonType={ButtonType.Cancel} />
                    </div>
                </div>
            </div>
        </div>
    )
}