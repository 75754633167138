import { Dialog } from "@headlessui/react"
import { CloudArrowDownIcon, InformationCircleIcon, EnvelopeIcon } from "@heroicons/react/24/outline"
import { Link } from "react-router-dom"
import { settings } from "../../../settings"
import { ButtonType, FormButton } from "../../../kit/Buttons"
import { useEffect, useState } from "react"
import axios from "axios"
import { useDispatch } from "react-redux"
import { setModal } from "../../../slices/userSlice"
import { Modals } from "./ModalsCommon"
import { processAxiosError } from "../../util/ErrorBoundary"
import { FullScreenLoader } from "../../../kit/Spinners"

function onSendEmail(backendUrl, backupId, dispatch) {
    axios.post(backendUrl + `/backup/${backupId}/email`)
      .then((res) => {
        if (res.data.isSuccess) {
          dispatch(setModal({ id: Modals.SuccessModalWithSingleCloseButton, title: "Email is sent", text: null }));
        }
        else {
          dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Error sending email", text: res.data.errorDescription }));
        }
      })
      .catch((error) => {
        dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Error contacting server", text: null }));
      });
}

export const DownloadBackupModal = ({ onClose, data }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [isPasswordProtected, setIsPasswordProtected] = useState(false);
    const [isInstantDownloadSupported, setIsInstantDownloadSupported] = useState(false);
    const dispatch = useDispatch();
    const verifyBackup = () => {
        setIsLoading(true);
        axios.post(settings.backendUrl + "/backup/validateArchive?id=" + data.backupId)
            .then((res) => {

                if (!res.data.isSuccess || res.data.result === null) {
                    dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Cannot download backup archive", text: res.data.errorDescription }))
                }
                else if (res.data.result !== null && !res.data.result.isValid) {
                    dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Cannot download backup archive", text: "Backup archive not found. Possible reasons: file moved/deleted or storage inaccessible." }))
                }

                if (res.data.result?.isPasswordProtected) {
                    setIsPasswordProtected(true);
                }

                if (res.data.result?.isInstantDownloadSupported) {
                    setIsInstantDownloadSupported(true);
                }
            })
            .catch((e) => {
                processAxiosError(e);
                dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Cannot download backup archive", text: "Something went wrong" }))
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        verifyBackup();
    }, [])

    return isLoading ? <div>
        <FullScreenLoader />
    </div>
        :
        (
            <>
                <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                        <InformationCircleIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                    </div>
                    <div className="text-center sm:mt-1">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                            Download this backup
                        </Dialog.Title>
                        <div className="mt-3">
                            {isInstantDownloadSupported && <>
                              <p className="text-sm font-medium text-gray-500">
                                Please use the following link to download your backup:
                              </p>
                              <Link to={settings.backendUrl + `/backup/${data.backupId}/file`}
                                target="_blank"
                                onClick={onClose}
                                className="mt-2 inline-flex justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
                                <CloudArrowDownIcon className="h-5 w-5 mr-1" aria-hidden="true" />
                                Download now
                              </Link>
                              {isPasswordProtected && <p className="text-sm text-gray-500 mt-2">
                                For security reason this backup is password protected. 
                                <br/>
                                You will receive <b>an email</b> with the password after clicking <b>Download now</b> button.
                              </p>}
                            </>}

                            {!isInstantDownloadSupported && isPasswordProtected && <>
                              <p className="text-sm font-medium text-gray-500">
                              <b>{data.storageProviderName}</b> does not support instant downloads. You will need to download the archive from your own storage solution.
                              </p>
                              <button
                                onClick={() => onSendEmail(settings.backendUrl, data.backupId, dispatch)}
                                className="mt-2 inline-flex justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
                                <EnvelopeIcon className="h-5 w-5 mr-1" aria-hidden="true" />
                                Send Password
                              </button>
                              <p className="text-sm text-gray-500 mt-2">
                              For security reasons this backup is password protected. You will receive <b>an email</b> with the password after clicking <b>Send Password</b> button.
                              </p>
                            </>}
                        </div>
                    </div>
                </div>
                <div className="mt-5 sm:mt-6 flex justify-center sm:justify-end">
                    <FormButton onClick={onClose} btnText="Close" buttonType={ButtonType.Cancel} />
                </div>
            </>
        )
}