import { useEffect, useMemo, useState } from "react"
import { ProviderStepWithSelectBox, ProviderTestAndSave, StorageWrapperComponent, useStorageSection } from "./ProvidersCommon"
import { NoSymbolIcon } from "@heroicons/react/24/outline"
import { settings } from "../../../settings"
import { Get } from "../../../helpers/requests"
import { StorageImg } from "../../../helpers/domainHelpers"
import { processAxiosError } from "../../util/ErrorBoundary"
import { useDispatch } from "react-redux"
import { setModal } from "../../../slices/userSlice"
import { Modals } from "../../application/modals/ModalsCommon"


const initialStoragesDict = {
    0: { id: 0, name: "Not selected", icon: (className) => <NoSymbolIcon className={className} /> }
}

const parseConnectionString = cs => {
    if (!cs) return {
        s1: 0,
        s2: 0
    }
    let parts = cs.split(';');
    let partsDict = parts.map(x => { let keyValue = x.split('='); return { key: keyValue[0], value: keyValue[1] } }).reduce((acc, val) => ({ ...acc, [val.key]: val.value }), {});
    return (
        {
            s1: Number(partsDict["S1"]),
            s2: Number(partsDict["S2"]),
        }
    )
}

const mapStorageToOption = (storage) => {
    return {
        id: storage.storageId,
        name: storage.storageName,
        icon: (className) => StorageImg({ isCustomStorage: storage.isCustomStorage, storageName: storage.storageName, provider: storage.provider, className })
    }
}

const reduceOptions = (opt, customStorageOnly) => {
    return opt
        .filter(x => !customStorageOnly || (customStorageOnly && x.isCustomStorage))
        .reduce((acc, val) => ({ ...acc, [val.storageId]: mapStorageToOption(val) }), {})
}

export const CompositeStorageInfo = ({ onComplete, onCancel, onTest, nameProvided, connectionString, editMode, setPageError }) => {

    const parsedConnectionString = useMemo(() => parseConnectionString(connectionString), [connectionString])
    const [s1, setS1] = useState(parsedConnectionString.s1);
    const [s2, setS2] = useState(parsedConnectionString.s2);
    const buildConnectionString = () => `S1=${s1};S2=${s2};`;
    const storageEditor = useStorageSection(onCancel, onTest, onComplete, buildConnectionString, editMode, setPageError);
    const canContinue = s1 !== 0 && s2 !== 0 && s1 !== s2;
   
    const [optionsLoading, setOptionsLoading] = useState(true);
    const [s1Options, setS1Options] = useState(initialStoragesDict);
    const [s2Options, setS2Options] = useState(initialStoragesDict);
    const dispatch = useDispatch();
    const loadOptions = () => {
        Get(settings.backendUrl + "/v2/storages", (res) => {
            if (res.data.isSuccess) {
                setS1Options(opt => ({ ...opt, ...reduceOptions(res.data.result, false) }));
                setS2Options(opt => ({ ...opt, ...reduceOptions(res.data.result, true) }));
            }
            else {
                setPageError(res.data.errorDescription);
            }
        }, (e) => {
            processAxiosError(e);
            setPageError("Something went wrong");
        }, setOptionsLoading);
    }

    useEffect(() => {
        if (s1 === s2 && s1 !== 0) {
            //TODO:
            setS2(0);
            dispatch(setModal({ id: Modals.ErrorModalWithSingleCloseButton, title: "Incorrect storages selected", text: "Composite storage should include two different storages" }));
        }
    }, [s1, s2, storageEditor])

    useEffect(() => {
        loadOptions();
    }, [])

    const currentS1Options = useMemo(() => Object.values(s1Options), [s1Options]);
    const currentS2Options = useMemo(() => Object.values(s2Options), [s2Options]);
    return (
        <>
            <StorageWrapperComponent className="bg-red-50/20" providers={storageEditor.providers}>
                <ProviderStepWithSelectBox stepTitle="Step 1"
                    options={currentS1Options}
                    optionsLoading={optionsLoading}
                    selected={s1Options[s1]}
                    setSelected={(v) => setS1(v.id)}
                    keySelector={v => v.id}
                    labelSelector={v => v.name}
                    leftContentSelector={option => (option.icon("h-5 w-5"))}
                >
                    <p>
                        Select primary storage
                    </p>
                </ProviderStepWithSelectBox>
                <ProviderStepWithSelectBox stepTitle="Step 2"
                    options={currentS2Options}
                    optionsLoading={optionsLoading}
                    selected={s2Options[s2]}
                    setSelected={(v) => setS2(v.id)}
                    keySelector={v => v.id}
                    labelSelector={v => v.name}
                    leftContentSelector={option => (option.icon("h-5 w-5"))}
                >
                    <p>
                        Select secondary storage
                    </p>
                </ProviderStepWithSelectBox>
            </StorageWrapperComponent>
            <ProviderTestAndSave storageEditor={storageEditor} nameIncluded={nameProvided}  canContinue={canContinue} />
        </>
    )
}