import { BackupStorageProvider, TriggerType, BackupStatus, RestoreStatus, ScheduleStatus, Order, OrderBy, ErrorCode, BackupDefinitionState, ArchivedBehaviour, InstantNotificationType } from "../model/domain";
import { ReactComponent as LogoBlack } from './../assets/logo-black.svg'
import { ReactComponent as LogoAzure } from './../assets/logo_azure.svg'
import { ReactComponent as LogoAwsS3 } from './../assets/aws-s3.svg'
import { ReactComponent as LogoGlacier } from './../assets/amazon-glacier.svg'
import { ReactComponent as LogoAlibaba } from './../assets/alibaba.svg'
import { ReactComponent as LogoGoogleCloud } from './../assets/google_cloud.svg'
import LogoSwift from './../assets/swift.png'
import LogoWasabi from './../assets/wasabi.png'
import { ReactComponent as LogoOneDrive } from './../assets/onedrive.svg'
import { ServerStackIcon } from "@heroicons/react/24/outline";
import { ClockIcon, LockIcon, PersonIcon, QuestionIcon, RepoIcon, SkipIcon } from "@primer/octicons-react";
import { createSelector } from "@reduxjs/toolkit";

const dangerColor = "text-red-700 bg-red-100 ring-red-600/10 ring-1 ring-inset";
const infoColor = "text-blue-700 bg-blue-100  ring-blue-700/10 ring-1 ring-inset";
const successColor = "text-green-700 bg-green-100  ring-green-600/20 ring-1 ring-inset";
const disabledColor = "text-gray-600 bg-gray-100  ring-gray-500/10 ring-1 ring-inset";
const warningColor = "text-yellow-800 bg-yellow-100  ring-yellow-600/20 ring-1 ring-inset";

export const cardCanBeSelectedOrBackedUp = (card) => {
  if (card === undefined || card === null) return false;
  const canBeSelected = card.state === BackupDefinitionState.canBackupAndRestore;
  return canBeSelected;
}

export const selectCard = createSelector([state => state.data.dashboard.cards, (state, repoId) => repoId],
  (cards, repoId) => getCard(cards, repoId));

export const getCard = (cards, repoId) => {
  let index = cards.findIndex(x => x.repositoryId === repoId);
  return cards[index];
}

export const StorageProviderName = (provider) => {
  switch (provider) {
    case BackupStorageProvider.undefined: return "Cloudback";
    case BackupStorageProvider.azureBlobContainer: return "Microsoft Azure Blob Container";
    case BackupStorageProvider.amazonS3AccessPoint: return "Amazon S3 AccessPoint"
    case BackupStorageProvider.amazonS3AccessKey: return "Amazon S3 AccessKey"
    case BackupStorageProvider.amazonS3Glacier: return "Amazon S3 Glacier"
    case BackupStorageProvider.alibabaObjectStorageService: return "Alibaba"
    case BackupStorageProvider.googleCloudStorageBucket: return "Google Cloud"
    case BackupStorageProvider.openStackSwiftS3: return "Swift S3"
    case BackupStorageProvider.wasabiS3AccessKey: return "Wasabi S3"
    case BackupStorageProvider.microsoftOneDrivePersonal: return "OneDrive Personal"
    case BackupStorageProvider.microsoftOneDriveBusiness: return "OneDrive Business"
    case BackupStorageProvider.compositeStorage: return "Composite"
    default: return "undefined";
  }
}

export const StorageImg = ({ isCustomStorage, storageName, provider, className }) => {
  
  return (
    <>
      {
        !isCustomStorage && storageName !== null ?
          <LogoBlack className={className} /> :
          !Object.keys(BackupStorageProvider).includes(provider)? <SkipIcon className={className} />
          :
          {
            [BackupStorageProvider.undefined]: <SkipIcon className={className} />,
            [BackupStorageProvider.microsoftOneDrivePersonal]: <LogoOneDrive className={className} />,
            [BackupStorageProvider.microsoftOneDriveBusiness]: <LogoOneDrive className={className} />,
            [BackupStorageProvider.azureBlobContainer]: <LogoAzure className={className} />,
            [BackupStorageProvider.amazonS3AccessPoint]: <LogoAwsS3 className={className} />,
            [BackupStorageProvider.amazonS3AccessKey]: <LogoAwsS3 className={className} />,
            [BackupStorageProvider.amazonS3Glacier]: <LogoGlacier className={className} />,
            [BackupStorageProvider.alibabaObjectStorageService]: <LogoAlibaba className={className} />,
            [BackupStorageProvider.googleCloudStorageBucket]: <LogoGoogleCloud className={className} />,
            [BackupStorageProvider.openStackSwiftS3]: <img src={LogoSwift} className={className} />,
            [BackupStorageProvider.wasabiS3AccessKey]: <img src={LogoWasabi} className={className} />,
            [BackupStorageProvider.compositeStorage]: <ServerStackIcon className={className} />
          }[provider]
      }
    </>
  )
}

export function getBackupStatus(backup, treatAsDate = true) {
  if (backup === null || backup===undefined) {
    return { Text: "Not run", Style: disabledColor, IconSrc: undefined };
  }

  let img = "";
  switch (backup.triggerType) {
    case TriggerType.manual: img = ({ className }) => <PersonIcon className={className} />; break;
    case TriggerType.scheduled: img = ({ className }) => <ClockIcon className={className} />; break;
    case TriggerType.undefined: img = ({ className }) => <QuestionIcon className={className} />; break;
  }

  switch (backup.status) {
    case BackupStatus.succeeded: return { Text: treatAsDate ? backup.startTimeText : "Succeeded", Style: successColor, IconSrc: img }
    case BackupStatus.archived: return { Text: "Uploading", Style: infoColor, IconSrc: img }
    case BackupStatus.deleted: return { Text: "Deleted", Style: warningColor, IconSrc: img }
    case BackupStatus.downloadedData: return { Text: "Archiving", Style: infoColor, IconSrc: img }
    case BackupStatus.downloadedRepo: return { Text: "Downloading", Style: infoColor, IconSrc: img }
    case BackupStatus.failed: {
      if ((backup.errorCode !== null) && (backup.errorCode === ErrorCode.sizeLimitExceeded)) {
        return { Text: "Limit Exceeded", Style: dangerColor, IconSrc: img };
      }
      return { Text: "Failed", Style: dangerColor, IconSrc: img }
    }
    case BackupStatus.inProgress: return { Text: "In progress", Style: infoColor, IconSrc: img }
    case BackupStatus.queued: return { Text: "Queued", Style: infoColor, IconSrc: img }
    case BackupStatus.uploaded: return { Text: "Almost Ready", Style: infoColor, IconSrc: img }
    case BackupStatus.undefined: return { Text: "Undefined", Style: infoColor, IconSrc: img }
    default: return { Text: "Unknown", Style: infoColor, IconSrc: img }
  }
}

export function getRestoreStatus(restore) {

  if (restore === null || restore===undefined) {
    return { Text: "Not run", Style: disabledColor, IconSrc: undefined };
  }

  const img = restore.isPrivate === null || !restore.isPrivate
    ? ({ className }) => <RepoIcon className={className} />
    : ({ className }) => <LockIcon className={className} />
  switch (restore.status) {
    case RestoreStatus.succeeded: return { Text: "Succeeded", Style: successColor, IconSrc: img }
    case RestoreStatus.failed: return { Text: "Failed", Style: dangerColor, IconSrc: img }
    case RestoreStatus.inProgress: return { Text: "In progress", Style: infoColor, IconSrc: img }
    case RestoreStatus.queued: return { Text: "Queued", Style: infoColor, IconSrc: img }
    case RestoreStatus.undefined: return { Text: "Undefined", Style: infoColor, IconSrc: img }
    case RestoreStatus.restoredRepo: return { Text: "In progress", Style: infoColor, IconSrc: img }
    case RestoreStatus.downloaded: return { Text: "In progress", Style: infoColor, IconSrc: img }
    default: return { Text: "Unknown", Style: warningColor, IconSrc: img }
  }
}

export function getScheduleStatusSecondary(card) {
  switch (card.scheduleStatus) {
    case ScheduleStatus.scheduled: return { Text: "Scheduled", Color: "text-green-700 bg-green-100/20" };
    case ScheduleStatus.notScheduled: return { Text: "Not scheduled", Color: "text-gray-700 bg-gray-100/50" };
    case ScheduleStatus.restoreOnly: return { Text: "Restore only", Color: "text-red-700 bg-red-100/20" };
    case ScheduleStatus.repositoryNotAccessible: return { Text: "Not accessible", Color: "text-red-700 bg-red-100/20" };
    default: return { Text: "Unknown", Color: "text-yellow-700 bg-yellow-100" }
  }
}

export function getScheduleStatus(card) {
  switch (card.scheduleStatus) {
    case ScheduleStatus.scheduled: return { Text: card.schedule.name, Style: successColor, ImgStyle: { filter: "invert(50%) sepia(97%) saturate(352%) hue-rotate(82deg) brightness(86%) contrast(95%)" } }
    case ScheduleStatus.notScheduled: return { Text: "Not scheduled", Style: disabledColor, ImgStyle: { filter: "invert(31%) sepia(0%) saturate(2946%) hue-rotate(135deg) brightness(108%) contrast(97%)" } }
    case ScheduleStatus.restoreOnly: return { Text: "Restore only", Style: dangerColor, ImgStyle: { filter: "invert(22%) sepia(91%) saturate(7084%) hue-rotate(357deg) brightness(95%) contrast(118%)" } }
    case ScheduleStatus.repositoryNotAccessible: return { Text: "Not accessible", Style: dangerColor, ImgStyle: { filter: "invert(22%) sepia(91%) saturate(7084%) hue-rotate(357deg) brightness(95%) contrast(118%)" } }
    default: return { Text: "Unknown", Style: disabledColor, ImgStyle: { filter: "invert(31%) sepia(0%) saturate(2946%) hue-rotate(135deg) brightness(108%) contrast(97%)" } }
  }
}

export function orderDesc(a, b) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

export function getOrderRes(a, b, order, orderBy) {
  let orderRes = orderDesc(a.repository?.owner, b.repository?.owner);
  switch (orderBy) {
    case "owner-repo":
      orderRes = a.repository?.owner === b.repository?.owner
        ? orderDesc(a.repository.name.toLowerCase(), b.repository.name.toLowerCase())
        : orderDesc(a.repository?.owner.toLowerCase(), b.repository?.owner.toLowerCase());
      break;
    case "schedule":
      let aStat = getScheduleStatus(a);
      let bStat = getScheduleStatus(b);
      orderRes = orderDesc(aStat.Text, bStat.Text);
      break;
    case "storage":
      orderRes = orderDesc(a.storage.storageName, b.storage.storageName);
      break;
    case "lastBackupStatus":
      orderRes = a.lastBackup === null && b.lastBackup === null
        ? 0
        : a.lastBackup === null
          ? 1
          : b.lastBackup === null
            ? -1
            : orderDesc(new Date(a.lastBackup.startTimeUtc), new Date(b.lastBackup.startTimeUtc));
      break;
  }
  return order === "desc" ? orderRes : -orderRes;
}

function getRepoDataDate(repoData) {
  return repoData.isBackup && repoData.backup !== null
    ? repoData.backup.startTimeUtc
    : repoData.isRestore && repoData.restore !== null
      ? repoData.restore.startTimeUtc
      : new Date(0);
}

export function getFlatBackupsRestores(data) {
  let res = [];
  for (let i = 0; i < data.backups.length; i++) {
    res.push({ isBackup: true, isRestore: false, backup: data.backups[i], restore: null });
  }
  for (let i = 0; i < data.restores.length; i++) {
    res.push({ isBackup: false, isRestore: true, backup: null, restore: data.restores[i] });
  }
  return res.sort((a, b) => orderDesc(getRepoDataDate(a), getRepoDataDate(b)));
}