import { classNames } from "../components/application/ApplicationHeader"


export const TableHeadCell = (props)=>{
    const {sticky,stickyPage,children,className, ...other} = props;
    return(
        <th
        colSpan={props.colSpan || 1}
        className={classNames(sticky?"sticky top-0 z-[5]":stickyPage?"sticky top-0 z-[5]":"", "table-cell block bg-gray-50 px-2 py-2.5 border-b border-gray-300 text-left text-base font-bold text-gray-900", className)}
        {...other}  >
            {children}
        </th>
    )
}
export const TableCell = (props)=>{
    const {children, ...other} = props;
    return(
        <td colSpan={props.colSpan || 1} {...other} className={classNames("table-cell px-2 py-1 border-b border-gray-200 relative text-left text-sm font-normal", props.className)} >
            {children}
        </td>
    )
}