import axios from 'axios';

export const Get=(url, callback, error, setLoading)=>{
    setLoading(true);
    axios.get(url)
    .then(res => {        
        callback(res);
    })
    .catch(e => {
        error(e);
    })
    .finally(() => {
        setLoading(false);
        
    });
}

export const Delete=(url, callback, error, setLoading)=>{
    setLoading(true);
    axios.delete(url)
    .then(res => {        
        callback(res);
    })
    .catch(e => {
        error(e);
    })
    .finally(() => {
        setLoading(false);
        
    });
}

export const Post=(url,  body, callback, error,setLoading)=>{
    setLoading(true);
    axios.post(url, body)
    .then(res => {        
        callback(res);
    })
    .catch(e => {
        error(e);
    })
    .finally(() => {
        setLoading(false);
    });
}

