import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { SnackbarKey, useSnackbar } from "notistack";
import { removeNotification, removeNotifications } from "../../slices/notificationSlice";

let displayed = [];

export const useNotifications = () => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const dispatch = useDispatch();
    const notifications = useSelector((state) => state.notifications);

    const addDisplayed = (myKey) => {
      displayed = [...displayed, myKey];
    };
  
    const removeDisplayed = (myKey) => {
      displayed = [...displayed.filter(key => myKey !== key)];
    };
  
    useEffect(() => {   
      let toRemove = [];

      notifications.forEach(notification => {
       
        if (displayed.includes(notification.options.key)) {
          toRemove.push(notification.options.key);
        } else {             
          enqueueSnackbar(notification.message,
            {
              key: notification.options.key,
              variant: notification.options.variant,
              onExited: (event, myKey) => {
                dispatch(removeNotification(myKey));                
                removeDisplayed(myKey);
              },
            });
          addDisplayed(notification.options.key);
        }     
      });
      dispatch(removeNotifications(toRemove));
  
    }, [notifications]);

    return;
  };