import { LinkExternalIcon } from '@primer/octicons-react'

const InlineCard = ({ title, text="Some text explaining something", buttonTitle, href }) => {
    return (
        <div className="border-b border-gray-900/10 pb-6">
            <div className="flex flex-col sm:flex-row gap-2 justify-end items-center">
                <div className="flex-1 w-full">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">{title}</h2>
                    {text && <p className="mt-1 text-sm leading-6 text-gray-600">{text}</p>}
                </div>
                <div className="flex items-center w-full sm:w-auto">
                    <a
                        href={href}
                        type="button"
                        className="w-full sm:w-max justify-center sm:justify-start rounded-md bg-gray-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500"
                        target="_blank"
                    >
                        {buttonTitle}
                        &nbsp;
                        <LinkExternalIcon verticalAlign='middle'></LinkExternalIcon>
                    </a>
                </div>
            </div>            
        </div>
    )
}


export const ContactUsPage = () => {
    return (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-5 bg-gray-100">
            <div className="mx-auto max-w-3xl">
                <div className="space-y-6 pt-5">
                    <InlineCard title="Encountered a problem?" text="Click here to let us know! Help us improve your experience by reporting any bugs, errors, or issues you come across." buttonTitle={"Report an issue"} href="https://github.com/cloudback/issue-tracker/issues/new?template=bug_report.md" />
                    <InlineCard title="Need a feature?" text="Looking for something specific? Let us know if there's a feature you need that could enhance your experience with our services." buttonTitle={"Request a feature"} href="https://github.com/cloudback/issue-tracker/issues/new?template=feature_request.md" />
                    <InlineCard title="Write an Email" text="Reach out to our support team for assistance with your account, feedback on our services, or any other inquiries. We're here to help and will respond promptly." buttonTitle={"Write an email"} href="mailto:support@cloudback.it" />
                    <InlineCard title="Book a meeting" text="Schedule a one-on-one session with our experts to discuss your needs, get a product demo, or plan your next steps." buttonTitle={"Book a meeting"} href="https://calendly.com/cloudback/30min" />
                </div>
            </div>
        </div>
    )
}