import { memo, useEffect, useMemo, useState } from "react";
import { VirtualizedRepoTable } from "./VirtualizedRepoTable"
import { getOrderRes } from "../../helpers/domainHelpers";
import { useDispatch, useSelector } from "react-redux";
import { DataRowType } from "../../model/domain";
import { loadDashboard, setFiltered } from "../../slices/dataSlice";
import axios from "axios";
import { settings } from "../../settings";
import { GridRepositories } from "./GridRepositories";
import { FullScreenLoader } from "../../kit/Spinners";
import { addTopAlert } from "../../slices/userSlice";
import { TopAlerts } from "../application/topAlerts/TopAlertsCommon";
import { useMixedData } from "../../hooks/useMixedData";
import { ErrorPage } from "../../kit/ErrorPage";


export const TabledRepositories = () => {

    const [dashboardLoaded, isLoading, pageError, tryAgain] = useMixedData(()=>axios.get(settings.backendUrl + "/v2/dashboard"), 
    (res,dispatch)=>{
        dispatch(loadDashboard(res.data.result));
        /*
            // TODO: move it to a separate API call
            if (res.data.result.accountsWithoutPlan.length !== 0) {
                dispatch(addTopAlert({
                    id: TopAlerts.MarketplacePlanMissing,
                    accounts: res.data.result.accountsWithoutPlan
                }))
            }
        */
    }, state => state.data.dashboard?.accountNames?.length > 0, undefined, (data) => data);
    
    const dispatch = useDispatch();
    const searchTerm = useSelector((state) => state.data.selectors.searchTerm);

    const cards = useSelector((state) => state.data.dashboard.cards);
    const orderSet = useSelector((state) => state.data.selectors.orderSet);

    useEffect(() => {
        if (cards.length === 0)
            return;
        let filtered = cards.slice()
            .filter(x => searchTerm === "" || x.repository.name.includes(searchTerm) || x.repository.owner.includes(searchTerm))
            .sort((a, b) =>
                getOrderRes(a, b, orderSet.order, orderSet.orderBy))
            .map((x) => {
                return { repoId: x.repositoryId, rowType: DataRowType.basic }
            });
        dispatch(setFiltered(filtered));
    }, [orderSet.order, orderSet.orderBy, searchTerm, cards]);

    useEffect(() => {
        document.title = "Cloudback: Dashboard";
    }, []);
    const tableView = useSelector(state => state.user.tableView);
    return pageError !== null? <ErrorPage canTryAgain={true} tryAgainAction={tryAgain}/> :
    tableView ? <VirtualizedRepoTable isLoading={!dashboardLoaded && isLoading}/> : <GridRepositories isLoading={!dashboardLoaded && isLoading}/>;
}

//