export const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const baseSettings = {
    marketplaceUrl: "https://github.com/marketplace/cloudback"
}


/* global cloudbackSettings*/
export const dynamicSettings = cloudbackSettings;

export const settings = isDevelopment ? {
    ...baseSettings,
    currentUrl: "http://127.0.0.1:3000",
    backendUrl: "http://127.0.0.1:1035",
    identityUrl: "https://app.staging.cloudback.it/identity",
    otpEnabled: true,
} :{
    ...baseSettings,
    currentUrl: window.location.origin,
    backendUrl: window.location.origin,
    identityUrl: window.location.origin+"/identity",
    ...dynamicSettings
}


export const getGitHubAvatarUrl = (owner, size) => `https://avatars.githubusercontent.com/${owner}?s=${size}&v=4`;
