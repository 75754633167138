import { createSlice, current } from '@reduxjs/toolkit'
import { cardCanBeSelectedOrBackedUp, orderDesc } from '../helpers/domainHelpers';

const initialState = {
  dashboard: {
    cards: [],
    loadedBackups: {},
    loadedRestores: {},
    accountsWithoutPlan: [],
    isInstallationRequired: false,
    userTimezone: "",
    accountNames: [],
    loaded:false
  },
  selectors: {
    filtered: [],
    searchTerm: "",
    selectedCards: {},
    opened: {},
    orderSet: { order: "asc", orderBy: "owner-repo" },
    settingsOpened: {},
  },
  schedules: {},
  storages: {},
  retentions: {},
  archivedBehaviours:{
    showAndBackup:{label:"Display on dashboard and backup",value:"showAndBackup"},
    doNotShowDoNotBackup:{label:"Hide from dashboard and skip backup",value:"doNotShowDoNotBackup"}
  },
}

const updateBackupCount = (state,cards)=>{
  try{
    if (state.dashboard === null) {
      return;
    }
    cards.forEach(c => {
      let index = state.dashboard.cards.findIndex(x => x.repositoryId === c.repositoryId);
      if (index === -1 || index === undefined) {      
      }
      else {
        state.dashboard.cards[index].backupsCount =state.dashboard.cards[index].backupsCount+1;
      }
    });
  }
  catch(e){
    console.log(e);
  }
}

const mergeCards = (state, list) => {
  if (state.dashboard === null) {
    return;
  }
  list.forEach(c => {
    let index = state.dashboard.cards.findIndex(x => x.repositoryId === c.repositoryId);
    if (index === -1 || index === undefined) {
      state.dashboard.cards.push(c);
    }
    else {
      state.dashboard.cards[index] = c;
    }
  });
}

const mergeBackups = (state, key, list) => {

  if (state.dashboard === null) {
    return;
  }

  let visibleBackups = state.dashboard?.loadedBackups;
  if (visibleBackups === undefined) {
    state.dashboard.loadedBackups = {};
    state.dashboard.loadedBackups[key] = [];
  }
  else {
    if (!(key in state.dashboard.loadedBackups)) {
      state.dashboard.loadedBackups[key] = [];
    }
  }

  let changed = false;
  if (list.length > 0) {
    list.forEach(b => {
      var index = state.dashboard?.loadedBackups[b.definitionId].findIndex(x => x.backupId === b.backupId);
      if (index === -1 || index === undefined) {
        state.dashboard.loadedBackups[b.definitionId].push(b);
      }
      else {
        state.dashboard.loadedBackups[b.definitionId][index] = b;
      }
    });
    changed = true;
  }
  let index = state.dashboard.cards.findIndex(x => x.definitionId === key);
  if (changed) {
    state.dashboard.loadedBackups[key].sort((a, b) => orderDesc(a.startTimeUtc, b.startTimeUtc));    
    if (index !== -1 && index !== undefined) {
      state.dashboard.cards[index].lastBackup = state.dashboard.loadedBackups[key][0] || null;
    }
  } 
  else{
    if (index !== -1 && index !== undefined) {
      state.dashboard.cards[index].lastBackup = null;
    }
  }
}

const mergeRestores = (state, key, list) => {

  if (state.dashboard === null) {
    return;
  }

  let visibleRestores = state.dashboard?.loadedRestores;
  if (visibleRestores === undefined) {
    state.dashboard.loadedRestores = {};
    state.dashboard.loadedRestores[key] = [];
  }
  else {
    if (!(key in state.dashboard.loadedRestores)) {
      state.dashboard.loadedRestores[key] = [];
    }
  }
  let changed = false;
  if (list.length > 0) {
    list.forEach(b => {
      var index = state.dashboard?.loadedRestores[b.definitionId].findIndex(x => x.restoreId === b.restoreId);
      if (index === -1 || index === undefined) {
        state.dashboard.loadedRestores[b.definitionId].push(b);
      }
      else {
        state.dashboard.loadedRestores[b.definitionId][index] = b;
      }
    });
    changed = true;
  }
  let index = state.dashboard.cards.findIndex(x => x.definitionId === key);
  if (changed){
    state.dashboard.loadedRestores[key].sort((a, b) => orderDesc(a.startTimeUtc, b.startTimeUtc));    
    if (index !== -1 && index !== undefined) {
      state.dashboard.cards[index].lastRestore = state.dashboard.loadedRestores[key][0] || null;
    }
  }
  else{
    if (index !== -1 && index !== undefined) {
      state.dashboard.cards[index].lastRestore = null;
    }
  }
}

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    loadDashboard: (state, action) => {
      state.dashboard = action.payload;
      action.payload.cards.forEach(c=>{
        mergeBackups(state, c.definitionId, c.latestBackups);
        mergeRestores(state, c.definitionId, c.latestRestores);
      })      
      state.dashboard.loaded = true;
    },    
    updateCards:(state,action)=>{
      mergeCards(state, action.payload);
    },
    updateCard: (state, action) => {
      let cardIndex = state.dashboard.cards.findIndex(x => x.repositoryId === action.payload.repositoryId);
      if (cardIndex === -1) {
        state.dashboard.cards.push(action.payload);
      }
      else {
        state.dashboard.cards[cardIndex] = action.payload;
      }
      mergeBackups(state, action.payload.definitionId, action.payload.latestBackups);
      mergeRestores(state, action.payload.definitionId, action.payload.latestRestores);
    },
    loadSchedules: (state, action) => {
      state.schedules = action.payload.reduce((acc, val) => ({ ...acc, [val.scheduleId]: val }), {});
    },
    loadStorages: (state, action) => {
      state.storages = action.payload.reduce((acc, val) => ({ ...acc, [val.storageId]: val }), {});
    },
    addOrUpdateSchedule: (state, action) => {
      state.schedules[action.payload.scheduleId] = action.payload;
    },
    addOrUpdateStorage: (state, action) => {
      state.storages[action.payload.storageId] = action.payload;
    },
    deleteStorage: (state, action) => {
      delete state.storages[action.payload.storageId];
    },
    deleteSchedule: (state, action) => {
      delete state.schedules[action.payload.scheduleId];
    },
    loadBackups: (state, action) => {
      if (action.payload.length ===0)return;
      mergeBackups(state, action.payload[0].definitionId, action.payload);
    },
    loadRestores: (state, action) => {
      if (action.payload.length ===0)return;
      mergeRestores(state, action.payload[0].definitionId, action.payload);
    },
    updateBackup: (state, action) => {
      mergeBackups(state, action.payload.definitionId, [action.payload]);
    },
    updateRestore: (state, action) => {
      mergeRestores(state, action.payload.definitionId, [action.payload]);
    },
    selectCard: (state, action) => {
      state.selectors.selectedCards[action.payload] = true;
    },
    deselectCard: (state, action) => {
      delete state.selectors.selectedCards[action.payload];
    },
    selectAll: (state, action) => {
      state.selectors.selectedCards = state.dashboard.cards.filter(x => cardCanBeSelectedOrBackedUp(x))
        .reduce((acc, v) => ({ ...acc, [v.repositoryId]: true }), {});
    },
    deselectAll: (state, action) => {
      state.selectors.selectedCards = {};
    },    
    setOrder: (state, action) => {
      state.selectors.orderSet = action.payload;
    },
    setSearch: (state, action) => {
      state.selectors.searchTerm = action.payload;
    },
    setFiltered: (state, action) => {
      state.selectors.filtered = action.payload;
    },
    preload: (state, action) => {
      state.schedules = action.payload.schedules.reduce((acc, val) => ({ ...acc, [val.scheduleId]: val }), {});
      state.retentions = action.payload.retentions.reduce((acc, val) => ({ ...acc, [val.retentionPolicyId]: val }), {});
      state.storages = action.payload.storages.reduce((acc, val) => ({ ...acc, [val.storageId]: val }), {});
    },
    updateBackupsForCards: (state, action)=>{
      console.log("Update backups", action.payload);
      updateBackupCount(state, action.payload);
    }
  }
});

// Action creators are generated for each case reducer function
export const { loadDashboard, loadSchedules, addOrUpdateSchedule, deleteSchedule, loadBackups,loadRestores, updateBackup, updateRestore,
  selectAll, deselectAll, selectCard, deselectCard, setOrder, setSearch, setFiltered,
  preload, updateCard,updateCards, loadStorages, addOrUpdateStorage, deleteStorage,updateBackupsForCards } = dataSlice.actions;

export default dataSlice.reducer