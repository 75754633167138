import { useParams, useSearchParams } from 'react-router-dom'
import { ReactComponent as CloudbackLogo } from './../../assets/logo-black.svg'
import { useEffect, useRef, useState } from 'react';
import { CaptchaWidget, useCaptcha } from "./securedRequest";
import { settings } from '../../settings';
import axios from 'axios';
import ReactCodeInput from 'react-code-input';
import { classNames } from '../application/ApplicationHeader';
import { CircularSpinnerSecondary } from '../../kit/Spinners';

const OtpTimeoutMinutes = 2;

const useCodeValidation = (email, returnUrl) => {
    const [code, setCode] = useState("");
    const [invalidCode, setInvalidCode] = useState(false);
    const [error, setError] = useState(null);
    const [sendBusy, setSentBusy] = useState(false);
    const [confirmBusy, setConfirmBusy] = useState(false);
    const [cooldown, setCooldown] = useState({
        cooldownTill: 0,
        isOnCooldown: false
    });

    useEffect(() => {
        startCooldown();
    }, [])

    useEffect(() => {
        if (code.length === 6) {
            onVerify(returnUrl);
        }
    }, [code])

    const startCooldown = () => {
        setCooldown({
            cooldownTill: new Date().getTime() + (OtpTimeoutMinutes * 60) * 1000,
            isOnCooldown: true
        });
    }
    const [counter, setCounter] = useState(0);
    const cooldownInterval = useRef();
    useEffect(() => {
        if (cooldown.isOnCooldown) {
            cooldownInterval.current = setInterval(
                () => {
                    let now = new Date().getTime();
                    if (cooldown.cooldownTill - now < 0) {
                        setCooldown({
                            cooldownTill: 0,
                            isOnCooldown: false
                        })
                    }
                    else {
                        setCounter(cooldown.cooldownTill - new Date().getTime());
                    }
                },
                1000
            );
        }
        else {
            clearInterval(cooldownInterval.current);
            cooldownInterval.current = null;
        }
    }, [cooldown.isOnCooldown]);

    const captcha = useCaptcha({ onSuccess: (token) => onSendCode(token), onError: () => setSentBusy(false) });

    const onStartSendingCode = () => {
        setSentBusy(true);
        captcha.clear();
        captcha.bound.execute();
    }

    const onSendCode = (token) => {
        setSentBusy(true);
        setError(null);
        var bodyFormData = new FormData();
        bodyFormData.append('action', "send");
        bodyFormData.append('email', email);
        bodyFormData.append('captcha', token); // <- token == undefined

        axios.post(
            settings.identityUrl + "/signin-cloudback",
            bodyFormData,
            {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            })
            .then(res => {
                if (res.status === 200) {
                    startCooldown();
                } else {
                    // TODO: handle error
                    console.log("Error sending code", res);
                }
            })
            .catch(e => {
                console.log("Error sending code", e);
            })
            .finally(() => {
                setSentBusy(false);
            })
    }
    const onVerify = (returnUrl) => {
        setConfirmBusy(true);
        setError(null);
        var bodyFormData = new FormData();
        bodyFormData.append('action', "verify");
        bodyFormData.append('email', email);
        bodyFormData.append('otp', code);
        bodyFormData.append('returnUrl', returnUrl);

        axios.post(
            settings.identityUrl + "/signin-cloudback",
            bodyFormData,
            {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            })
            .then(res => {
                if (res.status === 200) {
                    console.log("Success, redirect required", res.headers.location);
                    window.location = `${settings.identityUrl}/signin-cloudback?email=${encodeURIComponent(email)}&returnUrl=${encodeURIComponent(returnUrl)}&otp=${encodeURIComponent(code)}`;
                } else if (res.status === 500) {
                    setInvalidCode(true);
                }
            })
            .catch(e => {
                console.log("Error validating code", e);
                if (e?.response?.status === 401 || false) {
                    setInvalidCode(true);
                }
                setError("Error validating code");
            })
            .finally(() => {
                setConfirmBusy(false);
            })
    }

    return { error, cooldown, counter, captcha, confirmBusy, code, setCode, invalidCode, onStartSendingCode, sendBusy }
}

export const OtpWindow = ({ params }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const email = searchParams.get("email");
    const returnUrl = searchParams.get("returnUrl");

    // TODO: handle parameters
    const timestamp = searchParams.get("timestamp"); // unix epoch timestamp
    const invalid = searchParams.get("invalid"); // <1|undefined>, 1 if the code is invalid

    const { cooldown, counter, captcha, confirmBusy, code, setCode, invalidCode, onStartSendingCode, sendBusy, error } = useCodeValidation(email, returnUrl);

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">

            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <CloudbackLogo className="mx-auto h-10 w-auto" />
                <p className='text-md text-center w-full mt-4 font-medium'>One-Time Password has been sent to your email.</p>
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                    <div className='space-y-6'>
                        <div className='flex flex-col'>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email address
                            </label>
                            <div className="mt-2 flex flex-col sm:flex-row gap-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    readOnly
                                    defaultValue={email}
                                    autoComplete="email"
                                    required
                                    className="flex-1 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                                />
                                <button
                                    disabled={sendBusy || cooldown.isOnCooldown}
                                    onClick={onStartSendingCode}
                                    className={classNames("flex w-full sm:w-max justify-center items-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600",
                                        sendBusy || cooldown.isOnCooldown ? "!bg-gray-300" : "")}
                                >
                                    {sendBusy && <CircularSpinnerSecondary light={true} className="mr-1" />}
                                    Re-send code{cooldown.isOnCooldown && ` (${Math.floor((counter % (1000 * (OtpTimeoutMinutes * 60))) / 1000)}s)`}
                                </button>
                            </div>
                            {captcha.captchaErrorMessage !== null &&
                                <div className="flex flex-row w-full">
                                    <label className="label">
                                        <span className="text-red-300 text-xs">{captcha.captchaErrorMessage}</span>
                                    </label>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-center mt-2">
                        <CaptchaWidget captcha={captcha} />
                    </div>
                    <div className="flex flex-col w-full prose justify-center mt-4">
                        <ReactCodeInput
                            className="code-input text-center !flex flex-row justify-center"
                            type='number'
                            disabled={confirmBusy}
                            fields={6}
                            inputStyle={{
                                MozAppearance: 'textfield',
                                fontFamily: 'monospace',
                                borderRadius: '6px',
                                borderStyle: "solid",
                                borderWidth: "1px",
                                margin: "4px",
                                paddingLeft: "8px",
                                paddingRight: "0px",
                                width: "36px",
                                height: "42px",
                                fontSize: "32px",
                                borderColor: "#374151",
                                outline: "none",
                                "--tw-ring-color": "#374151"
                            }}
                            value={code}
                            onChange={(code) => setCode(code)} />
                        <p className={invalidCode ? "text-error text-center mt-0" : "text-center mt-0"}>{invalidCode ? "Invalid code" : "Enter 6-digit code from email"}</p>
                    </div>
                    {error !== null &&
                        <div className="flex flex-row w-full justify-center">
                            <label className="label">
                                <span className="text-red-300 text-xs">{error}</span>
                            </label>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}