import { useEffect, useState } from 'react'
import { ReactComponent as CloudbackLogo } from './../../assets/logo-black.svg'
import { Link } from 'react-router-dom';
import { classNames } from '../application/ApplicationHeader';
import { Tooltip } from 'react-tooltip';
import { CaptchaWidget, useCaptcha } from "./securedRequest";
import { CircularSpinnerSecondary } from '../../kit/Spinners';
import { settings } from '../../settings';

export const SignInForm = () => {

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const onEmailChange = (email) => {
    setEmail(email);
    if (email === '') return;
    let emailValid = /\S+@\S+\.\S+/.test(email);
    setIsEmailValid(emailValid);
  }

  const [sendBusy, setSentBusy] = useState(false);
  const captcha = useCaptcha({ 
    onSuccess: (token) => window.location.replace(`${settings.backendUrl}/Auth/SignIn?provider=Cloudback&email=${email}&captcha=${token}`),
    onError: () => setSentBusy(false) });
  const onStartSendingCode = () => {
    setSentBusy(true);
    captcha.clear();
    captcha.bound.execute();
  }

  const canContinue = isEmailValid && email!=='';

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="w-full flex justify-center items-center">
        <Link to="https://cloudback.it" className='inline-flex items-center'><CloudbackLogo className="mx-auto h-10 w-auto mr-1" /><span className='text-lg font-semibold'>Cloudback</span></Link>
        </div>
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          {settings.otpEnabled &&
            <>
              <div className='space-y-3'>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={e => onEmailChange(e.target.value)}
                      autoComplete="email"
                      required
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {captcha.captchaErrorMessage !== null &&
                    <div className="flex flex-row w-full">
                      <label className="label">
                        <span className="text-red-300 text-xs">{captcha.captchaErrorMessage}</span>
                      </label>
                    </div>
                  }
                  <div className="flex flex-row items-center justify-center mt-2">
                    <CaptchaWidget captcha={captcha} />
                  </div>
                </div>
                <div>
                  <button
                    onClick={onStartSendingCode}
                    disabled={!canContinue}
                    data-tooltip-id="email-tooltip" data-tooltip-content="Incorrect email format"
                    className={classNames("flex w-full justify-center rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600",
                    )}
                  >
                    {sendBusy ? <CircularSpinnerSecondary light={true} /> : "Sign in"}
                  </button>
                  {!isEmailValid && <Tooltip className="z-[55]" id="email-tooltip" />}
                </div>
              </div>
              <div className="mt-10">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-white px-6 text-gray-900">Or continue with</span>
                  </div>
                </div>
              </div>
            </>
          }
          <div className='mt-6'>
            <a
              href={settings.backendUrl + "/Auth/SignIn?provider=GitHubBackup"}
              className="flex w-full items-center justify-center gap-3 rounded-md bg-[#24292F] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
            >
              <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-sm font-semibold leading-6">Sign In with GitHub</span>
            </a>
          </div>

          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{' '}
            <a href={settings.marketplaceUrl} className="font-semibold leading-6 text-gray-600 hover:text-gray-500">
              Start a 14 day free trial or setup a free plan
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
