import { Transition } from "@headlessui/react"
import { Fragment } from "react"


export const BaseTransition = ({children}) => {
  
    return (
        <Transition
            show={true}
            appear={true}
            as={Fragment}
            enter="transition-opacity duration-200"
            enterFrom="opacity-50"
            enterTo="opacity-100"
        >
            {children}
        </Transition>
    )
}