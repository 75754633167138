import { LinkExternalIcon } from "@primer/octicons-react"
import { classNames } from "../components/application/ApplicationHeader"
import { FullScreenLoader } from "./Spinners"
import { LongInvisibleInput, LongTextLink, LongTextTag } from "./LongInvisibleInput"
import { Link } from "react-router-dom"

export const StatLabel = ({ title, className }) => {
    return <p className={classNames("text-sm font-medium leading-6 text-gray-500",className)}>{title}: </p>
}

const StatTextValue = ({ value, valueLink }) => {
    return valueLink !== undefined ?
        <LongTextLink href={valueLink} className="underline ml-1 text-right" target="_blank">
            {value}
            <LinkExternalIcon className="h-4 w-4 ml-1" verticalAlign='middle'/>
        </LongTextLink >
        : 
        <LongTextTag value={value === 0 ? "-" : value} className="ml-1 items-center text-md font-medium text-right"/>
        
}

export const Statement = ({ title, value, Icon, valueLink, isLast }) => {
    
    return (
        <div className={classNames("flex flex-row items-center align-middle py-1 w-full overflow-hidden", isLast?"":"border-b border-gray-100/2 ")}>
            {Icon !== undefined && <Icon className="h-5 w-5 min-h-5 min-w-5 mr-1" size="medium" />}
            <div className="flex flex-row justify-between w-full items-center overflow-hidden">
                {title !== undefined && <StatLabel title={title} />}
                <StatTextValue value={value} valueLink={valueLink} />
            </div>
        </div>
    )
}

export const DashboardCard = (props) => {
    return (
        <div className={classNames("br-2 p-5 w-full rounded-lg bg-white shadow flex flex-col flex-1", props.className)}>
            <div className="flex flex-col sm:flex-row gap-2 justify-between mb-4">
                <h3 className="text-base font-semibold leading-7 text-gray-900">{props.title}</h3>
                {props.rightContent && props.rightContent}
            </div>
            {props.isLoading ?
                <FullScreenLoader title={null} className="" /> :
                props.children
            }
        </div>
    )
}