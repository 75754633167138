import { NoSymbolIcon } from "@heroicons/react/24/outline"

export const EmptyState = ({ text, title, content, Icon }) => {
    return (
        <div className="w-full flex justify-center">
            <div className="flex flex-col max-w-xl px-3">
                <div className="flex align-middle justify-center items-center pb-2">
                    {Icon !== undefined ? <Icon className="h-10 w-10" style={{ opacity: 0.3 }} /> : <NoSymbolIcon className="h-10 w-10" style={{ opacity: 0.3 }} />}
                </div>
                <div className="flex align-middle justify-center items-center">
                    <p className="text-xl font-semibold text-gray-500">{title}</p>
                </div>
                <div className="mt-2">
                    {text !== undefined && <div className="align-middle text-center">
                        <span className="text-nowrap text-gray-500 text-sm">
                            {text}
                        </span>
                    </div>}
                </div>
                {content !== undefined && content}
            </div>
        </div>
    )
}
