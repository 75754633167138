import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/24/outline"
import { Tooltip } from "react-tooltip";
import { ButtonType, FormButton } from "../../kit/Buttons";

export const RestoreStepTwo = ({ targetOwner, setTargetOwner, targetRepo, setTargetRepo, isPrivate, setIsPrivate, onNext, onBack }) => {

    const canContinue = targetOwner !== '' && targetRepo !== '';
    const formLoading = false;
    return (
        <div className="space-y-3">
            <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Target</h2>
                <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="col-span-full">
                        <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
                            Type a target account name
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                value={targetOwner}
                                onChange={e => setTargetOwner(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="col-span-full">
                        <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
                            Type a new repository name
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                value={targetRepo}
                                onChange={e => setTargetRepo(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="col-span-full">
                        <legend className="text-sm font-semibold leading-6 text-gray-900">Choose repository privacy</legend>
                        <div className="mt-6 space-y-6">
                            <div className="flex items-center gap-x-3">
                                <input
                                    value={!isPrivate}
                                    checked={!isPrivate}
                                    onChange={() => setIsPrivate(!isPrivate)}
                                    type="radio"
                                    className="h-4 w-4 border-gray-300 text-gray-600"
                                />
                                <div className="inline-flex items-center align-middle">
                                    <LockOpenIcon className="min-w-8 min-h-8 w-8 h-8 mr-1" />
                                    <div className="flex flex-col">
                                        <h3 className="font-bold">Public</h3>
                                        <p className="text-sm">Anyone on the internet can see this repository. You choose who can commit.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center gap-x-3">
                                <input
                                    value={isPrivate}
                                    checked={isPrivate}
                                    onChange={() => setIsPrivate(!isPrivate)}
                                    type="radio"
                                    className="h-4 w-4 border-gray-300 text-gray-600"
                                />
                                <div className="inline-flex items-center align-middle">
                                    <LockClosedIcon className="min-w-8 min-h-8 w-8 h-8 mr-1" />
                                    <div className="flex flex-col">
                                        <h3 className="font-bold">Private</h3>
                                        <p className="text-sm">You choose who can see and commit to this repository.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-full">
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <FormButton onClick={onNext} disabled={!canContinue} loading={formLoading} btnText="Next"
                            tooltipId="disabled-tooltip" tooltipText="Not all fields set" showTooltip={!canContinue} />
                        <FormButton onClick={onBack} btnText="Back" buttonType={ButtonType.Cancel} />
                    </div>
                </div>
            </div>
        </div>
    )
}