import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { log, processAxiosError } from "../components/util/ErrorBoundary";


export const useDependentMixedData = (request, callback, selector, dependency,loadedConditionFunc) => {
   
    const [pageError, setPageError] = useState(null);
    const dispatch = useDispatch();
    const data = useSelector(selector);
    const [isLoading, setIsLoading] = useState(loadedConditionFunc === undefined?true:!loadedConditionFunc(data));
    const requestData = () => {
        request().then(res => {
            if (res.data.isSuccess) {
                callback(res, dispatch);
            }
            else{
                setPageError(res.data.errorDescription)
            }
        }
        )
        .catch(e => {
            processAxiosError(e);
            setPageError(e);
        })
        .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (dependency !== null && dependency !== undefined) {
            requestData();
        }
    }, [dependency])

    const tryAgain = () => {
        setPageError(null);
        if (dependency !== null && dependency !== undefined) {
            requestData();
        }
    }
   
    return [data, isLoading, pageError, tryAgain]
}

export const useMixedData = (request, callback, selector, customSetPageError, loadedConditionFunc) => {
   
    const dispatch = useDispatch();
    const data = useSelector(selector);
    const [pageError, setPageError] = useState(null);
    const [isLoading, setIsLoading] = useState(loadedConditionFunc === undefined?true:!loadedConditionFunc(data));
    const requestData = () => {
        request().then(res => {
            if (res.data.isSuccess) {
                callback(res, dispatch);
            }
            else{
                setPageError(res.data.errorDescription)
            }
        }
        )
        .catch(e => {
            processAxiosError(e);
            setPageError(e);
        })
        .finally(() => setIsLoading(false));
    }


    useEffect(() => {
        requestData();
    }, [])

    const tryAgain = () => {
        setPageError(null);
        requestData();
    }
    const setError = customSetPageError !== undefined?customSetPageError:setPageError;
    return [data, isLoading, pageError, tryAgain, setError]
}